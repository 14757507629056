/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Link, useLocation } from "react-router-dom";

/* PACKAGES */
import { Helmet } from "react-helmet";

/* MATERIAL TAILWIND COMPONENTS */
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  Button,
} from "@material-tailwind/react";

/* USER DEFINED COMPONENTS */

/* ASSETS */
import Alpaca from "assets/images/logos/alpaca.png";

/* ICONS */
import { GoArrowLeft } from "react-icons/go";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

/* SAMPLE DATA */
import accountsConnected from "data/accounts";

/* ASSETS */
import CharlesSchwab from "assets/images/logos/charles-schwab.jpeg";
import {
  getAccountListApi,
  getActiveSingleApi,
  getAccountAllDetailsApi,
  getAccountDetailsApi,
} from "service/api";
import ChangeOrderComponent from "components/forms/changeOrder";
import { useDispatch, useSelector } from "react-redux";

import userAction from "redux/actions/user.action";
import TradeHistory from "components/others/tradeHistory";

/* ACCORDIONN ANIMATION */

/* ORDER TYPE */

/* TIMING OPTIONS */

const ChangeTradePage = () => {
  /* GLOBAL STATE */

  const dispatch = useDispatch();
  const open = useSelector((state) => state?.user?.openOrder);

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const orderId = query.get("orderId");

  /* STATE VARIABLES */
  const [selectedSymbol, setSelectedSymbol] = useState(null);

  const [symbolDetails, setSymbolDetails] = useState(null);
  const [orderDetails, setOrderDetails] = useState(null);

  /* VIEW HANDLER STATES */
  const [view, setView] = useState("change");

  useEffect(() => {
    dispatch(userAction.setApiStatus(true));
  }, []);

  /* ORDER PLACED COMPONENT */
  const OrderPlacedComponent = () => {
    return (
      <div className="w-full xl:w-3/5 lg:w-3/5 bg-greyBg border border-greyBorder rounded-md p-5">
        <h5 className="text-2xl font-redhat-extrabold text-black text-center">
          Your order was placed!
        </h5>
        <div className="mt-3">
          <img
            src={require("../../../../assets/images/icons/check.png")}
            className="w-16 h-16 mx-auto"
            alt="Order placed"
          />
        </div>
        <div className="mt-3">
          <p className="text-lg font-redhat-semibold text-black text-center">
            Check your order status below
          </p>
          <div className="w-11/12 xl:w-1/4 lg:w-1/4 mx-auto">
            <div className="flex items-center gap-3 mt-5">
              <Button
                className="w-full shadow-none hover:shadow-none bg-black hover:bg-opacity-80 rounded-md text-white font-redhat-bold text-sm px-5 py-3 normal-case transition-all duration-300 ease-in-out"
                onClick={() => setView("change")}
              >
                Go back
              </Button>
              <Button
                className="w-full shadow-none hover:shadow-none bg-logoGreen hover:bg-opacity-80 rounded-md text-white font-redhat-bold text-sm px-5 py-3 normal-case transition-all duration-300 ease-in-out whitespace-nowrap"
                onClick={() => setView("change")}
              >
                New order
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  /* CANCEL ORDER COMPONENT */
  const OrderCancelledComponent = () => {
    return (
      <div className="w-full xl:w-3/5 lg:w-3/5 bg-greyBg border border-greyBorder rounded-md p-5">
        <h5 className="text-2xl font-redhat-extrabold text-black text-center">
          Your order was cancelled!
        </h5>
        <div className="mt-3">
          <img
            src={require("../../../../assets/images/icons/check.png")}
            className="w-16 h-16 mx-auto"
            alt="Order cancelled"
          />
        </div>
        <div className="mt-3">
          <p className="text-lg font-redhat-semibold text-black text-center">
            Check your order status below
          </p>
          <div className="w-11/12 xl:w-1/4 lg:w-1/4 mx-auto">
            <div className="flex items-center gap-3 mt-5">
              <Button
                className="w-full shadow-none hover:shadow-none bg-black hover:bg-opacity-80 rounded-md text-white font-redhat-bold text-sm px-5 py-3 normal-case transition-all duration-300 ease-in-out"
                onClick={() => setView("change")}
              >
                Go back
              </Button>
              <Button
                className="w-full shadow-none hover:shadow-none bg-logoGreen hover:bg-opacity-80 rounded-md text-white font-redhat-bold text-sm px-5 py-3 normal-case transition-all duration-300 ease-in-out whitespace-nowrap"
                onClick={() => setView("change")}
              >
                New order
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const getSymbolDetailsFunction = () => {
    getActiveSingleApi(selectedSymbol).then((res) => {
      setSymbolDetails(res?.data?.security);
    });
  };

  useEffect(() => {
    // getAccountListFunction();
    if (selectedSymbol) {
      getSymbolDetailsFunction();
    }
  }, [selectedSymbol]);

  useEffect(() => {
    if (orderId && orderId !== orderDetails?.id) {
      setOrderDetails(open?.find((el) => el?.details?.id === orderId));
      setSelectedSymbol(open?.find((el) => el?.details?.id === orderId)?.name)
    }
  }, [orderId]);

  return (
    <section className="px-3 xl:px-10 lg:px-10 py-3 xl:py-7 lg:py-7">
      {/* PAGE TITLE */}
      <Helmet>
        <title>Change your trade | ScaleTrade</title>
      </Helmet>

      <div className="container-full-width">
        {/* CONTENT */}
        <div className="w-full xl:w-11/12 lg:w-11/12 overflow-auto overflow-x-hidden overflow-y-auto hide-scrollbar">
          {view === "change" && (
            <ChangeOrderComponent
              symbolDetails={symbolDetails}
              orderDetails={orderDetails}
              setView={setView}
            />
          )}

          {view === "placed" && <OrderPlacedComponent />}

          {view === "cancel" && <OrderCancelledComponent />}

          {/* ACCOUNT ORDERS */}
          <TradeHistory />
        </div>
      </div>
    </section>
  );
};

export default ChangeTradePage;
