/* eslint-disable no-unused-vars */
import { useState, useEffect } from "react";

/* PACKAGES */
import { Helmet } from "react-helmet";
import Select from "react-select";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade, Navigation } from "swiper/modules";
import ReactDatePicker from "react-datepicker";
import moment from "moment";
/* IMPORT FORMIK FOR FORM-SUBMIT */
import { useFormik } from "formik";
import * as yup from "yup";
/* MATERIAL TAILWIND COMPONENTS */
import {
  Tabs,
  TabsHeader,
  Tab,
  TabsBody,
  TabPanel,
  Card,
  CardBody,
  Button,
  Dialog,
  DialogBody,
} from "@material-tailwind/react";

/* DATA */
import { brokers, stocksList, investorLabels } from "data/investors";

/* CHARTS */
import DashboardChart from "components/calculator/chart";

/* UTILS */
import { calculateCompoundInterest } from "utils";

/* ASSETS */
import WarrenBuffetFull from "assets/images/investors/warren-buffet-full.png";
import RayDalioFull from "assets/images/investors/ray-dalio-full.png";
import PeterLynchFull from "assets/images/investors/peter-lynch-full.png";
import JackBogleFull from "assets/images/investors/jack-bogle-full.png";

/* ASSETS */
import WarrenBuffet from "assets/images/investors/warren-buffet.png";
import TDAmeritrade from "assets/images/logos/td-ameritrade.png";

/* ASSETS: ETFs */
import ETF01Detail from "assets/images/etf-icons/etf-01-detail.png";
import ETF02Detail from "assets/images/etf-icons/etf-02-detail.png";
import ETF03Detail from "assets/images/etf-icons/etf-03-detail.png";
import ETF04Detail from "assets/images/etf-icons/etf-04-detail.png";
import ETF05Detail from "assets/images/etf-icons/etf-05-detail.png";
import ETF06Detail from "assets/images/etf-icons/etf-06-detail.png";
import ETF07Detail from "assets/images/etf-icons/etf-07-detail.png";
import ETF08Detail from "assets/images/etf-icons/etf-08-detail.png";

/* ICONS */
import {
  FaChevronLeft,
  FaChevronRight,
  FaChevronUp,
  FaChevronDown,
} from "react-icons/fa6";
import { IoClose } from "react-icons/io5";

/* INPUT BOX */
const InputBox = (props) => {
  return (
    <input
      type={props.type}
      name={props.name}
      id={props.id}
      value={props.value}
      onChange={props.onChange}
      placeholder={props.placeholder}
      className="w-full block bg-[#F6F6F6] rounded-md px-5 py-2.5 outline-none focus:outline-none text-base placeholder:font-redhat-regular text-black"
    />
  );
};

/* CONTRIBUTING OPTIONS */
const contributingOptions = [
  /* { value: 52, label: "Per week" }, */
  { value: 1, label: "Monthly" },
  /* { value: 1, label: "Per year" }, */
];

/* VALIDATION SCHEMA FOR LOGIN FORM */
const formValidationSchema = yup.object().shape({
  principalAmount: yup.number().required("Principal Amount is required."),
  monthlyContribution: yup
    .number()
    .required("Monthly Contribution is required."),
  interestRate: yup.number().required("Interest Rate is required."),
  timePeriodRange: yup.object().required("Time Period Range is required."),
  timePeriod: yup.number().required("Time Period is required."),
});

const Selection = () => {
  /* INITIALIZE STATE VARIABLES */
  const [submitLoading, setSubmitLoading] = useState(false);

  /* GLOBAL STATES */
  const [estimatedInvestment, setEstimatedInvestment] = useState(0);

  const [startDate, setStartDate] = useState(new Date());
  const [activeIndex, setActiveIndex] = useState(1);
  const [drawbackIndex, setDrawbackIndex] = useState(1);
  const [activeTab, setActiveTab] = useState("Legendary Investors");
  const [strategySetup, setStrategySetup] = useState(false);

  /* LEGENDARY INVESTORS */
  const [selectedInvestor, setSelectedInvestor] = useState("Warren Buffet");

  /* POWERFUL ETFs */
  const [selectedETF, setSelectedETF] = useState({
    name: "Ticker: VOO",
    strategyName: "500 Largest Companies",
  });

  /* CHART DATA */
  const [graphData, setGraphData] = useState([]);
  const [labels, setLabels] = useState([]);

  /* DIALOG BOX STATES */
  const [stepOne, setStepOne] = useState(true);
  const [stepTwo, setStepTwo] = useState(false);
  const [dropdownClicked, setDropdownClicked] = useState(false);
  const [brokerDropdown, setBrokerDropdown] = useState(false);
  const [selectedOption, setSelectedOption] = useState({
    name: "Warren Buffet",
    image: WarrenBuffet,
    strategyName: "90/10 Strategy",
  });
  const [selectedBroker, setSelectedBroker] = useState({
    name: "TD Ameritrade",
    image: TDAmeritrade,
    balance: "24,159.88",
    text: "You have $5,157.92 in cash to invest in this account.",
  });

  const [automationStarted, setAutomationStarted] = useState(false);
  const [pauseAutomation, setPauseAutomation] = useState(false);

  /* INVESTOR BOX */
  const InvestorBox = (props) => {
    return (
      <div
        className={`w-full ${
          selectedInvestor === props.name
            ? "bg-logoGreen"
            : "bg-white hover:bg-black hover:bg-opacity-5"
        } border border-greyBorder border-opacity-50 shadow-md rounded-md p-2 cursor-pointer transition-all duration-300 ease-in-out`}
        onClick={props.onClick}
      >
        <div className="flex items-center gap-3">
          <img
            src={props.image}
            className="w-14 h-14 flex-shrink-0"
            alt={props.name}
          />
          <div className="p-0">
            <h5
              className={`text-md font-redhat-bold ${
                selectedInvestor === props.name ? "text-white" : "text-black"
              }`}
            >
              {props.name}
            </h5>
            <p
              className={`text-sm font-redhat-semibold ${
                selectedInvestor === props.name
                  ? "text-white text-opacity-80"
                  : "text-greyText"
              }`}
            >
              {props.strategyName}
            </p>
          </div>
        </div>
      </div>
    );
  };

  /* BROKER SELECTION BOX */
  const ETFSelectionBox = (props) => {
    return (
      <div
        className={`${
          selectedETF.strategyName === props.strategyName
            ? "bg-logoGreen"
            : "bg-white hover:bg-[#F2F2F2]"
        } border border-greyBorder border-opacity-50 shadow-md rounded-md p-2 cursor-pointer transition-all duration-300 ease-in-out`}
        onClick={props.onClick}
      >
        <div className="flex items-center gap-3">
          <img
            src={props.image}
            className="w-14 h-14 flex-shrink-0"
            alt={props.strategyName}
          />
          <div className="p-0">
            <h5
              className={`text-md font-redhat-bold ${
                selectedETF.strategyName === props.strategyName
                  ? "text-white"
                  : "text-black"
              }`}
            >
              {props.name}
            </h5>
            <p
              className={`text-sm font-redhat-semibold ${
                selectedETF.strategyName === props.strategyName
                  ? "text-white text-opacity-80"
                  : "text-greyText"
              }`}
            >
              {props.strategyName}
            </p>
          </div>
        </div>
      </div>
    );
  };

  /* INVESTMENT VALUES */
  const InvestmentValue = (props) => {
    return (
      <div className="p-0">
        <h5 className="text-xl font-redhat-bold text-black">{props.value}</h5>
        <div className="flex items-center gap-2">
          {props.color !== "#FFF" && (
            <div
              className="w-2 h-2 rounded-full flex-shrink-0"
              style={{ backgroundColor: props.color }}
            />
          )}

          <p className="text-md font-redhat-regular text-black">
            {props.label}
          </p>
        </div>
      </div>
    );
  };

  /* JACK BOGLE */
  const AboutJack = () => {
    return (
      <Card className="h-full shadow-none bg-transparent">
        <CardBody className="p-0">
          <img
            src={JackBogleFull}
            className="w-full h-[30vh] object-cover rounded-md mx-auto"
            alt="Jack Bogle"
          />

          <div className="mt-3">
            <h5 className="text-lg font-redhat-bold text-black">
              Who is Jack Bogle
            </h5>
          </div>
          <div className="mt-2">
            <ul className="ml-5 list-disc">
              <li className="text-base font-redhat-semibold text-black">
                Averaged 20% per year for over 50 years, making him one of the
                best investors in history
              </li>
              <li className="text-base font-redhat-semibold text-black mt-2">
                Says the average person should just follow the 90/10 portfolio
                to reach wealth
              </li>
              <li className="text-base font-redhat-semibold text-black mt-2">
                Pledged to donate 99% of his wealth
              </li>
            </ul>
          </div>
        </CardBody>
      </Card>
    );
  };

  /* RAY DALIO */
  const AboutRay = () => {
    return (
      <Card className="h-full shadow-none bg-transparent">
        <CardBody className="p-0">
          <img
            src={RayDalioFull}
            className="w-full h-[30vh] object-cover rounded-md mx-auto"
            alt="Ray Dalio"
          />

          <div className="mt-3">
            <h5 className="text-lg font-redhat-bold text-black">
              Who is Ray Dalio
            </h5>
          </div>
          <div className="mt-2">
            <ul className="ml-5 list-disc">
              <li className="text-base font-redhat-semibold text-black">
                Averaged 20% per year for over 50 years, making him one of the
                best investors in history
              </li>
              <li className="text-base font-redhat-semibold text-black mt-2">
                Says the average person should just follow the 90/10 portfolio
                to reach wealth
              </li>
              <li className="text-base font-redhat-semibold text-black mt-2">
                Pledged to donate 99% of his wealth
              </li>
            </ul>
          </div>
        </CardBody>
      </Card>
    );
  };

  /* WARREN BUFFET */
  const AboutWarren = () => {
    return (
      <Card className="h-full shadow-none bg-transparent">
        <CardBody className="p-0">
          <img
            src={WarrenBuffetFull}
            className="w-full h-[30vh] object-cover rounded-md mx-auto"
            alt="Warren Buffet"
          />

          <div className="mt-3">
            <h5 className="text-lg font-redhat-bold text-black">
              Who is Warren Buffet
            </h5>
          </div>
          <div className="mt-2">
            <ul className="ml-5 list-disc">
              <li className="text-base font-redhat-semibold text-black">
                Averaged 20% per year for over 50 years, making him one of the
                best investors in history
              </li>
              <li className="text-base font-redhat-semibold text-black mt-2">
                Says the average person should just follow the 90/10 portfolio
                to reach wealth
              </li>
              <li className="text-base font-redhat-semibold text-black mt-2">
                Pledged to donate 99% of his wealth
              </li>
            </ul>
          </div>
        </CardBody>
      </Card>
    );
  };

  /* PETER LYNCH */
  const AboutPeter = () => {
    return (
      <Card className="h-full shadow-none bg-transparent">
        <CardBody className="p-0">
          <img
            src={PeterLynchFull}
            className="w-full h-[30vh] object-cover rounded-md mx-auto"
            alt="Peter Lynch"
          />

          <div className="mt-3">
            <h5 className="text-lg font-redhat-bold text-black">
              Who is Peter Lynch
            </h5>
          </div>
          <div className="mt-2">
            <ul className="ml-5 list-disc">
              <li className="text-base font-redhat-semibold text-black">
                Averaged 20% per year for over 50 years, making him one of the
                best investors in history
              </li>
              <li className="text-base font-redhat-semibold text-black mt-2">
                Says the average person should just follow the 90/10 portfolio
                to reach wealth
              </li>
              <li className="text-base font-redhat-semibold text-black mt-2">
                Pledged to donate 99% of his wealth
              </li>
            </ul>
          </div>
        </CardBody>
      </Card>
    );
  };

  /* PROGRESSBAR */
  const Progressbar = (props) => {
    return (
      <div className="w-full h-3 rounded-full bg-gradient-to-r from-black to-[#219653] relative flex items-center">
        <div
          style={{ width: `${props.percentage}%` }}
          className="flex items-end"
        >
          <div className="w-2 h-2 bg-white rounded-full ml-auto" />
        </div>
      </div>
    );
  };

  /* COMPOSITION */
  const CompositionWarren = (props) => {
    return (
      <div className="w-full flex items-center gap-1">
        <div
          className="h-14 bg-[#008037] bg-opacity-20 hover:bg-opacity-30 transition-all duration-300 ease-in-out rounded-l-md border-3 border-white border-r-0 flex items-center justify-center cursor-pointer"
          style={{ width: `${props.initialPercentage}%` }}
        >
          <p className="text-md font-redhat-semibold text-black">
            {props.initialPercentage}%
          </p>
        </div>
        <div
          className="h-16 bg-[#008037] hover:bg-opacity-80 transition-all duration-300 ease-in-out rounded-r-md flex items-center justify-center cursor-pointer"
          style={{ width: `${props.secondaryPercentage}%` }}
        >
          <p className="text-md font-redhat-semibold text-white">
            {props.secondaryPercentage}%
          </p>
        </div>
      </div>
    );
  };

  /* JACK BOGLE COMPOSITION */
  const CompositionJack = (props) => {
    return (
      <div className="w-full flex items-center gap-1">
        <div
          className="h-14 bg-[#008037] bg-opacity-20 rounded-l-md flex items-center justify-center"
          style={{ width: `${props.initialPercentage}%` }}
        >
          <p className="text-md font-redhat-semibold text-black">
            {props.initialPercentage}%
          </p>
        </div>
        <div
          className="h-14 bg-[#008037] bg-opacity-40 flex items-center justify-center"
          style={{ width: `${props.secondaryPercentage}%` }}
        >
          <p className="text-md font-redhat-semibold text-white">
            {props.secondaryPercentage}%
          </p>
        </div>
        <div
          className="h-16 bg-[#008037] rounded-r-md flex items-center justify-center"
          style={{ width: `${props.tertiaryPercentage}%` }}
        >
          <p className="text-md font-redhat-semibold text-white">
            {props.tertiaryPercentage}%
          </p>
        </div>
      </div>
    );
  };

  /* DROPDOWN SELECTED */
  const DropdownOption = (props) => {
    return (
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-3">
          <img
            src={props.image}
            className="w-12 h-12 rounded-md object-cover"
            alt={props.name}
          />
          <div className="p-0">
            <h5 className="text-base font-redhat-semibold text-white">
              {props.name}
            </h5>
            <p className="text-sm font-redhat-regular text-white text-opacity-80">
              {props.strategyName}
            </p>
          </div>
        </div>
        {dropdownClicked ? (
          <FaChevronUp className="w-3 h-3 text-white" />
        ) : (
          <FaChevronDown className="w-3 h-3 text-white" />
        )}
      </div>
    );
  };

  /* OPTION BOX */
  const OptionBox = (props) => {
    return (
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-3">
          <img
            src={props.image}
            className="w-12 h-12 rounded-md object-cover"
            alt={props.name}
          />
          <div className="p-0">
            <h5 className="text-base font-redhat-semibold text-black">
              {props.name}
            </h5>
            <p className="text-sm font-redhat-regular text-greyText">
              {props.strategyName}
            </p>
          </div>
        </div>
      </div>
    );
  };

  /* BROKER BOX */
  const BrokerBox = (props) => {
    return (
      <div className="flex items-center justify-between gap-5">
        <div className="flex items-center gap-3">
          <img
            src={props.image}
            className="w-12 h-12 rounded-md object-cover"
            alt={props.name}
          />
          <div className="p-0">
            <div className="flex items-center justify-between">
              <h5 className="text-base font-redhat-semibold text-black">
                {props.name}
              </h5>
              <h5 className="text-base font-redhat-semibold text-black">
                ${props.balance}
              </h5>
            </div>
            <p className="text-sm font-redhat-regular text-greyText leading-snug">
              {props.text}
            </p>
          </div>
        </div>

        {brokerDropdown ? (
          <FaChevronUp className="w-3 h-3 text-black" />
        ) : (
          <FaChevronDown className="w-3 h-3 text-black" />
        )}
      </div>
    );
  };

  /* DATE EXCLUSION */
  const isDateExcluded = (date) => {
    const day = date.getDate();
    return day === 29 || day === 30 || day === 31;
  };

  /* ABOUT VOO */
  const AboutVOO = () => {
    return (
      <Card className="h-full shadow-none bg-transparent">
        <CardBody className="p-0">
          <img
            src={ETF01Detail}
            className="w-full h-[30vh] object-cover rounded-md mx-auto"
            alt="What is VOO"
          />

          <div className="mt-3">
            <h5 className="text-lg font-redhat-bold text-black">
              What is VOO?
            </h5>
          </div>
          <div className="mt-2">
            <ul className="ml-5 list-disc">
              <li className="text-base font-redhat-semibold text-black">
                This gold ETF is one of the best ways to invest in gold, as it
                tracks the price of the gold bullion
              </li>
              <li className="text-base font-redhat-semibold text-black mt-2">
                It was started in 2004 and is run by an extremely credible asset
                manager, SPDR
              </li>
              <li className="text-base font-redhat-semibold text-black mt-2">
                It has a 0.40% expense ratio, which is average
              </li>
            </ul>
          </div>
        </CardBody>
      </Card>
    );
  };

  /* ABOUT QQQ */
  const AboutQQQ = () => {
    return (
      <Card className="h-full shadow-none bg-transparent">
        <CardBody className="p-0">
          <img
            src={ETF02Detail}
            className="w-full h-[30vh] object-cover rounded-md mx-auto"
            alt="What is QQQ"
          />

          <div className="mt-3">
            <h5 className="text-lg font-redhat-bold text-black">
              What is QQQ?
            </h5>
          </div>
          <div className="mt-2">
            <ul className="ml-5 list-disc">
              <li className="text-base font-redhat-semibold text-black">
                The Nasdaq ETF (QQQ) is one of the three major ways people track
                the stock market
              </li>
              <li className="text-base font-redhat-semibold text-black mt-2">
                It’s a way to invest in the largest tech companies in the United
                States
              </li>
              <li className="text-base font-redhat-semibold text-black mt-2">
                It’s often a bit more volatile than other options like the S&P
                500 and Dow Jones
              </li>
            </ul>
          </div>
        </CardBody>
      </Card>
    );
  };

  /* ABOUT SCHD */
  const AboutSCHD = () => {
    return (
      <Card className="h-full shadow-none bg-transparent">
        <CardBody className="p-0">
          <img
            src={ETF03Detail}
            className="w-full h-[30vh] object-cover rounded-md mx-auto"
            alt="What is SCHD"
          />

          <div className="mt-3">
            <h5 className="text-lg font-redhat-bold text-black">
              What is SCHD?
            </h5>
          </div>
          <div className="mt-2">
            <ul className="ml-5 list-disc">
              <li className="text-base font-redhat-semibold text-black">
                This gold ETF is one of the best ways to invest in gold, as it
                tracks the price of the gold bullion
              </li>
              <li className="text-base font-redhat-semibold text-black mt-2">
                It was started in 2004 and is run by an extremely credible asset
                manager, SPDR
              </li>
              <li className="text-base font-redhat-semibold text-black mt-2">
                It has a 0.40% expense ratio, which is average
              </li>
            </ul>
          </div>
        </CardBody>
      </Card>
    );
  };

  /* ABOUT GLD */
  const AboutGLD = () => {
    return (
      <Card className="h-full shadow-none bg-transparent">
        <CardBody className="p-0">
          <img
            src={ETF04Detail}
            className="w-full h-[30vh] object-cover rounded-md mx-auto"
            alt="What is GLD"
          />

          <div className="mt-3">
            <h5 className="text-lg font-redhat-bold text-black">
              What is GLD?
            </h5>
          </div>
          <div className="mt-2">
            <ul className="ml-5 list-disc">
              <li className="text-base font-redhat-semibold text-black">
                This gold ETF is one of the best ways to invest in gold, as it
                tracks the price of the gold bullion
              </li>
              <li className="text-base font-redhat-semibold text-black mt-2">
                It was started in 2004 and is run by an extremely credible asset
                manager, SPDR
              </li>
              <li className="text-base font-redhat-semibold text-black mt-2">
                It has a 0.40% expense ratio, which is average
              </li>
            </ul>
          </div>
        </CardBody>
      </Card>
    );
  };

  /* ABOUT VUG */
  const AboutVUG = () => {
    return (
      <Card className="h-full shadow-none bg-transparent">
        <CardBody className="p-0">
          <img
            src={ETF05Detail}
            className="w-full h-[30vh] object-cover rounded-md mx-auto"
            alt="What is VUG"
          />

          <div className="mt-3">
            <h5 className="text-lg font-redhat-bold text-black">
              What is VUG?
            </h5>
          </div>
          <div className="mt-2">
            <ul className="ml-5 list-disc">
              <li className="text-base font-redhat-semibold text-black">
                Provides a convenient way to match the performance of many of
                the nation’s largest growth stocks.
              </li>
              <li className="text-base font-redhat-semibold text-black mt-2">
                Follows a passively managed, full-replication approach with a
                0.04% expense ratio
              </li>
            </ul>
          </div>
        </CardBody>
      </Card>
    );
  };

  /* ABOUT IWM */
  const AboutIWM = () => {
    return (
      <Card className="h-full shadow-none bg-transparent">
        <CardBody className="p-0">
          <img
            src={ETF06Detail}
            className="w-full h-[30vh] object-cover rounded-md mx-auto"
            alt="What is IWM"
          />

          <div className="mt-3">
            <h5 className="text-lg font-redhat-bold text-black">
              What is IWM?
            </h5>
          </div>
          <div className="mt-2">
            <ul className="ml-5 list-disc">
              <li className="text-base font-redhat-semibold text-black">
                This gold ETF is one of the best ways to invest in gold, as it
                tracks the price of the gold bullion
              </li>
              <li className="text-base font-redhat-semibold text-black mt-2">
                It was started in 2004 and is run by an extremely credible asset
                manager, SPDR
              </li>
              <li className="text-base font-redhat-semibold text-black mt-2">
                It has a 0.40% expense ratio, which is average
              </li>
            </ul>
          </div>
        </CardBody>
      </Card>
    );
  };

  /* ABOUT SWSX */
  const AboutSWSX = () => {
    return (
      <Card className="h-full shadow-none bg-transparent">
        <CardBody className="p-0">
          <img
            src={ETF07Detail}
            className="w-full h-[30vh] object-cover rounded-md mx-auto"
            alt="What is SWSX"
          />

          <div className="mt-3">
            <h5 className="text-lg font-redhat-bold text-black">
              What is SWSX?
            </h5>
          </div>
          <div className="mt-2">
            <ul className="ml-5 list-disc">
              <li className="text-base font-redhat-semibold text-black">
                This gold ETF is one of the best ways to invest in gold, as it
                tracks the price of the gold bullion
              </li>
              <li className="text-base font-redhat-semibold text-black mt-2">
                It was started in 2004 and is run by an extremely credible asset
                manager, SPDR
              </li>
              <li className="text-base font-redhat-semibold text-black mt-2">
                It has a 0.40% expense ratio, which is average
              </li>
            </ul>
          </div>
        </CardBody>
      </Card>
    );
  };

  /* ABOUT DIA */
  const AboutDIA = () => {
    return (
      <Card className="h-full shadow-none bg-transparent">
        <CardBody className="p-0">
          <img
            src={ETF08Detail}
            className="w-full h-[30vh] object-cover rounded-md mx-auto"
            alt="What is DIA"
          />

          <div className="mt-3">
            <h5 className="text-lg font-redhat-bold text-black">
              What is DIA?
            </h5>
          </div>
          <div className="mt-2">
            <ul className="ml-5 list-disc">
              <li className="text-base font-redhat-semibold text-black">
                This gold ETF is one of the best ways to invest in gold, as it
                tracks the price of the gold bullion
              </li>
              <li className="text-base font-redhat-semibold text-black mt-2">
                It was started in 2004 and is run by an extremely credible asset
                manager, SPDR
              </li>
              <li className="text-base font-redhat-semibold text-black mt-2">
                It has a 0.40% expense ratio, which is average
              </li>
            </ul>
          </div>
        </CardBody>
      </Card>
    );
  };

  /* HANDLE FORM SUBMIT USING FORMIK */
  const formFormikFunction = useFormik({
    /* SET INITIAL VALUES FOR LOGIN FORM */
    initialValues: {
      principalAmount: 10000,
      monthlyContribution: 1500,
      interestRate: 10.26,
      timePeriodRange: { value: 1, label: "Monthly" },
      timePeriod: 40,
    },
    /* VALIDATION SCHEMA  */
    validationSchema: formValidationSchema,
    /* HANDLE FORM SUBMIT */
    onSubmit: (values) => {
      /* SET LOADING AS TRUE */
      setSubmitLoading(true);
      const result = calculateCompoundInterest(values);
      setLabels(result?.labels);
      setGraphData(result?.graphData);
      setEstimatedInvestment(result);
      setSubmitLoading(false);
    },
  });

  /* USE EFFECT */
  useEffect(() => {
    const result = calculateCompoundInterest({
      principalAmount: 10000,
      monthlyContribution: 1500,
      interestRate: 10.26,
      timePeriodRange: {
        value: 1,
        label: "Monthly",
      },
      timePeriod: 40,
    });
    setLabels(result?.labels);
    setGraphData(result?.graphData);
    setEstimatedInvestment(result);
  }, []);

  return (
    <section className="px-3 xl:px-10 lg:px-10 py-3 xl:py-7 lg:py-7">
      {/* PAGE TITLE */}
      <Helmet>
        <title>Calculator | ScaleTrade</title>
      </Helmet>

      <div className="container">
        {/* HEADING */}
        <div className="p-0">
          <h1 className="text-2xl xl:text-3xl lg:text-3xl font-redhat-extrabold text-black leading-tight">
            Select your investment
          </h1>
          <p className="mt-1 text-lg font-redhat-regular text-black">
            Automate Legendary Investors and Powerful ETFs from inside your own
            account
          </p>
        </div>

        {/* TABS */}
        <div className="mt-5">
          <Tabs value={activeTab}>
            <TabsHeader
              className="bg-transparent p-0 shadow-none rounded-none gap-2"
              indicatorProps={{
                className:
                  "bg-transparent shadow-none bg-[#F6F6F6] rounded-t-md rounded-b-none",
              }}
            >
              <Tab
                key="Legendary Investors"
                value="Legendary Investors"
                className="text-base font-redhat-semibold text-black bg-white px-8 py-4 whitespace-nowrap shadow-none rounded-t-md rounded-b-none"
                onClick={() => setActiveTab("Legendary Investors")}
              >
                Legendary Investors
              </Tab>
              <Tab
                key="Powerful ETFs"
                value="Powerful ETFs"
                className="text-base font-redhat-semibold text-black bg-white px-8 py-4 whitespace-nowrap shadow-none rounded-t-md rounded-b-none"
                onClick={() => setActiveTab("Powerful ETFs")}
              >
                Powerful ETFs
              </Tab>
            </TabsHeader>
            <TabsBody className="p-0">
              {/* LEGENDARY INVESTORS */}
              <TabPanel value="Legendary Investors" className="p-0">
                <div className="bg-[#F6F6F6] p-5 rounded-b-md">
                  {/* INVESTORS LIST */}
                  <div className="grid grid-cols-1 xl:grid-cols-4 lg:grid-cols-4 gap-3">
                    {stocksList.map((data, index) => (
                      <div className="col-span-1" key={index}>
                        <InvestorBox
                          name={data.name}
                          image={data.image}
                          strategyName={data.strategyName}
                          onClick={() => setSelectedInvestor(data.name)}
                        />
                      </div>
                    ))}
                  </div>

                  {/* INPUT DATA AND CHART */}
                  <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 gap-0 xl:gap-5 lg:gap-5 mt-5">
                    {/* CALCULATOR DATA */}
                    <div className="col-span-1">
                      <Card className="bg-white shadow-none rounded-md border border-borderColor border-opacity-20">
                        <CardBody className="p-5">
                          <h5 className="text-lg font-redhat-bold text-black">
                            Investment Calculator
                          </h5>
                          <form className="mt-3">
                            <div className="form-group">
                              <label className="text-base font-redhat-semibold text-black">
                                How much do you have invested now?
                              </label>
                              <div className="mt-2 relative">
                                <InputBox
                                  type="number"
                                  name="investmentValue"
                                  id="investmentValue"
                                  placeholder="10000"
                                />

                                <div className="absolute top-3 right-5">
                                  <p className="text-base font-redhat-semibold text-greyText">
                                    USD
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="form-group mt-4">
                              <label className="text-base font-redhat-semibold text-black">
                                How many years will you invest for?
                              </label>
                              <div className="mt-2 relative">
                                <InputBox
                                  type="number"
                                  name="investmentTime"
                                  id="investmentTime"
                                  placeholder="10"
                                />

                                <div className="absolute top-3 right-5">
                                  <p className="text-base font-redhat-semibold text-greyText">
                                    Years
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="form-group mt-4">
                              <label className="text-base font-redhat-semibold text-black">
                                How often will you invest more?
                              </label>
                              <div className="mt-2">
                                <Select
                                  options={contributingOptions}
                                  classNamePrefix="invest"
                                  placeholder="Select contribution"
                                  className="react-select"
                                />
                              </div>
                            </div>
                            <div className="form-group mt-4">
                              <label className="text-base font-redhat-semibold text-black">
                                How much will you invest each time?
                              </label>
                              <div className="mt-2 relative">
                                <InputBox
                                  type="number"
                                  name="investValueEachTime"
                                  id="investValueEachTime"
                                  placeholder="1500"
                                />

                                <div className="absolute top-3 right-5">
                                  <p className="text-base font-redhat-semibold text-greyText">
                                    USD
                                  </p>
                                </div>
                              </div>
                            </div>
                            <Button
                              className="mt-5 w-full shadow-none hover:shadow-none bg-logoGreen hover:bg-opacity-80 rounded-md normal-case text-base font-redhat-bold transition-all duration-300 ease-in-out"
                              onClick={() => setStrategySetup(!strategySetup)}
                            >
                              ✨ Automate {selectedInvestor}
                            </Button>
                          </form>
                        </CardBody>
                      </Card>
                    </div>

                    {/* CHART DATA */}
                    <div className="col-span-2">
                      <Card className="bg-white shadow-none rounded-md border border-borderColor border-opacity-20">
                        <CardBody className="p-5">
                          <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3">
                            {investorLabels.map((data, index) => (
                              <div className="col-span-1" key={index}>
                                <InvestmentValue
                                  label={data.label}
                                  value={data.value}
                                  color={data.color}
                                />
                              </div>
                            ))}
                          </div>

                          {/* CHARTS */}
                          <div className="-mt-11">
                            <DashboardChart
                              values={graphData}
                              labels={labels}
                            />
                          </div>
                        </CardBody>
                      </Card>
                    </div>
                  </div>
                </div>

                {/* INVESTOR BASED DATA */}
                <div className="mt-5">
                  <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 gap-5">
                    <div className="col-span-1">
                      <div className="bg-[#F6F6F6] p-5 rounded-md">
                        {selectedInvestor === "Jack Bogle" && <AboutJack />}
                        {selectedInvestor === "Ray Dalio" && <AboutRay />}
                        {selectedInvestor === "Warren Buffet" && (
                          <AboutWarren />
                        )}
                        {selectedInvestor === "Peter Lynch" && <AboutPeter />}
                      </div>
                    </div>
                    <div className="col-span-1">
                      <Card className="h-full bg-[#F6F6F6] shadow-none rounded-md">
                        <CardBody className="p-5">
                          <h5 className="text-lg font-redhat-bold text-black">
                            About this strategy
                          </h5>
                          <div className="mt-1">
                            <p className="text-base font-redhat-regular text-black">
                              This is some text about the strategy overview that
                              would explain what it is, where it was derived
                              from and who it’s for...
                            </p>
                          </div>
                          <div className="mt-4">
                            <div className="flex items-center justify-between">
                              <p className="text-base font-redhat-semibold text-black">
                                Estimated Return
                              </p>
                              {selectedInvestor === "Warren Buffet" && (
                                <p className="text-base font-redhat-semibold text-black">
                                  13.24% per year (High)
                                </p>
                              )}
                              {selectedInvestor === "Jack Bogle" && (
                                <p className="text-base font-redhat-semibold text-black">
                                  8.13% per year (High)
                                </p>
                              )}
                            </div>
                            <div className="mt-2">
                              {selectedInvestor === "Warren Buffet" && (
                                <Progressbar percentage={80} />
                              )}

                              {selectedInvestor === "Jack Bogle" && (
                                <Progressbar percentage={70} />
                              )}
                            </div>
                          </div>
                          <div className="mt-4">
                            <div className="flex items-center justify-between">
                              <p className="text-base font-redhat-semibold text-black">
                                Risk Level
                              </p>
                              {selectedInvestor === "Warren Buffet" && (
                                <p className="text-base font-redhat-semibold text-black">
                                  3.6 / 5.0 (Medium)
                                </p>
                              )}
                              {selectedInvestor === "Jack Bogle" && (
                                <p className="text-base font-redhat-semibold text-black">
                                  3.0 / 5.0 (Medium)
                                </p>
                              )}
                            </div>
                            <div className="mt-2">
                              {selectedInvestor === "Warren Buffet" && (
                                <Progressbar percentage={70} />
                              )}

                              {selectedInvestor === "Jack Bogle" && (
                                <Progressbar percentage={60} />
                              )}
                            </div>
                          </div>
                          <div className="mt-3">
                            <p className="text-base font-redhat-semibold text-black">
                              The composition of the investment portfolio
                            </p>
                            <div className="mt-2">
                              {selectedInvestor === "Warren Buffet" && (
                                <CompositionWarren
                                  initialPercentage={90}
                                  secondaryPercentage={10}
                                />
                              )}

                              {selectedInvestor === "Jack Bogle" && (
                                <CompositionJack
                                  initialPercentage={33.3}
                                  secondaryPercentage={33.3}
                                  tertiaryPercentage={33.3}
                                />
                              )}
                            </div>
                          </div>
                          <div className="mt-3 bg-white p-3">
                            <div className="flex items-center gap-2">
                              <div className="w-4 h-4 bg-[#008037] rounded" />
                              {selectedInvestor === "Warren Buffet" && (
                                <p className="text-md font-redhat-semibold text-black">
                                  10% is invested into BILLS
                                </p>
                              )}
                              {selectedInvestor === "Jack Bogle" && (
                                <p className="text-md font-redhat-semibold text-black">
                                  33.3% is invested into BILLS
                                </p>
                              )}
                            </div>
                            <p className="text-sm font-redhat-semibold text-black mt-2">
                              The Bloomberg 3-12 Month T-Bill ETF (BILLS) helps
                              you invest in short-term bonds backed by the U.S.
                              government.
                            </p>
                          </div>
                        </CardBody>
                      </Card>
                    </div>
                    <div className="col-span-1">
                      <Card className="h-full shadow-none bg-[#F6F6F6] rounded-lg">
                        <CardBody className="p-5">
                          <h5 className="text-lg font-redhat-bold text-black">
                            Benefits
                          </h5>
                          <div className="mt-3">
                            <Card className="bg-white w-full rounded-md shadow-none">
                              <CardBody className="p-5">
                                <Swiper
                                  modules={[Navigation, EffectFade]}
                                  navigation={{
                                    nextEl: ".benefit-next",
                                    prevEl: ".benefit-prev",
                                  }}
                                  slidesPerView={1}
                                  effect="fade"
                                  fadeEffect={{ crossFade: true }}
                                >
                                  <SwiperSlide>
                                    <p className="w-11/12 mx-auto text-sm font-redhat-semibold text-black text-center">
                                      You own 10,000 different securities
                                      through these investments meaning you have
                                      a lot of diversity.
                                    </p>
                                  </SwiperSlide>
                                  <SwiperSlide>
                                    <p className="w-11/12 mx-auto text-sm font-redhat-semibold text-black text-center">
                                      You own 9,000 different securities through
                                      these investments meaning you have a lot
                                      of diversity.
                                    </p>
                                  </SwiperSlide>
                                  <SwiperSlide>
                                    <p className="w-11/12 mx-auto text-sm font-redhat-semibold text-black text-center">
                                      You own 8,000 different securities through
                                      these investments meaning you have a lot
                                      of diversity.
                                    </p>
                                  </SwiperSlide>
                                  <SwiperSlide>
                                    <p className="w-11/12 mx-auto text-sm font-redhat-semibold text-black text-center">
                                      You own 7,000 different securities through
                                      these investments meaning you have a lot
                                      of diversity.
                                    </p>
                                  </SwiperSlide>
                                </Swiper>

                                <div className="mt-7 flex items-center justify-between">
                                  <Button
                                    className="shadow-none hover:shadow-none p-2 rounded-full bg-white hover:bg-black hover:bg-opacity-5 transition-all duration-300 ease-in-out benefit-prev"
                                    onClick={() =>
                                      setActiveIndex(activeIndex - 1)
                                    }
                                  >
                                    <FaChevronLeft className="w-4 h-4 text-black" />
                                  </Button>
                                  <div className="flex items-center gap-2">
                                    <div
                                      className={`w-2.5 h-2.5 rounded-full ${
                                        activeIndex === 1
                                          ? "bg-[#008037]"
                                          : "bg-[#DDD]"
                                      }`}
                                    />
                                    <div
                                      className={`w-2.5 h-2.5 rounded-full ${
                                        activeIndex === 2
                                          ? "bg-[#008037]"
                                          : "bg-[#DDD]"
                                      }`}
                                    />
                                    <div
                                      className={`w-2.5 h-2.5 rounded-full ${
                                        activeIndex === 3
                                          ? "bg-[#008037]"
                                          : "bg-[#DDD]"
                                      }`}
                                    />
                                    <div
                                      className={`w-2.5 h-2.5 rounded-full ${
                                        activeIndex === 4
                                          ? "bg-[#008037]"
                                          : "bg-[#DDD]"
                                      }`}
                                    />
                                  </div>
                                  <Button
                                    className="shadow-none hover:shadow-none p-2 rounded-full bg-white hover:bg-black hover:bg-opacity-5 transition-all duration-300 ease-in-out benefit-next"
                                    onClick={() =>
                                      setActiveIndex(activeIndex + 1)
                                    }
                                  >
                                    <FaChevronRight className="w-4 h-4 text-black" />
                                  </Button>
                                </div>
                              </CardBody>
                            </Card>
                          </div>

                          <div className="mt-5">
                            <h5 className="text-lg font-redhat-bold text-black">
                              Drawbacks
                            </h5>
                            <div className="mt-3">
                              <Card className="bg-white w-full rounded-md shadow-none">
                                <CardBody className="p-5">
                                  <Swiper
                                    modules={[Navigation, EffectFade]}
                                    navigation={{
                                      nextEl: ".benefit-next",
                                      prevEl: ".benefit-prev",
                                    }}
                                    slidesPerView={1}
                                    effect="fade"
                                    fadeEffect={{ crossFade: true }}
                                  >
                                    <SwiperSlide>
                                      <p className="w-11/12 mx-auto text-sm font-redhat-semibold text-black text-center">
                                        Whoa, here are some downsides to this
                                        strategy. This is the first one.
                                      </p>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                      <p className="w-11/12 mx-auto text-sm font-redhat-semibold text-black text-center">
                                        Whoa, here are some downsides to this
                                        strategy. This is the first one.
                                      </p>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                      <p className="w-11/12 mx-auto text-sm font-redhat-semibold text-black text-center">
                                        Whoa, here are some downsides to this
                                        strategy. This is the first one.
                                      </p>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                      <p className="w-11/12 mx-auto text-sm font-redhat-semibold text-black text-center">
                                        Whoa, here are some downsides to this
                                        strategy. This is the first one.
                                      </p>
                                    </SwiperSlide>
                                  </Swiper>

                                  <div className="mt-7 flex items-center justify-between">
                                    <Button
                                      className="shadow-none hover:shadow-none p-2 rounded-full bg-white hover:bg-black hover:bg-opacity-5 transition-all duration-300 ease-in-out benefit-prev"
                                      onClick={() =>
                                        setDrawbackIndex(drawbackIndex - 1)
                                      }
                                    >
                                      <FaChevronLeft className="w-4 h-4 text-black" />
                                    </Button>
                                    <div className="flex items-center gap-2">
                                      <div
                                        className={`w-2.5 h-2.5 rounded-full ${
                                          drawbackIndex === 1
                                            ? "bg-[#008037]"
                                            : "bg-[#DDD]"
                                        }`}
                                      />
                                      <div
                                        className={`w-2.5 h-2.5 rounded-full ${
                                          drawbackIndex === 2
                                            ? "bg-[#008037]"
                                            : "bg-[#DDD]"
                                        }`}
                                      />
                                      <div
                                        className={`w-2.5 h-2.5 rounded-full ${
                                          drawbackIndex === 3
                                            ? "bg-[#008037]"
                                            : "bg-[#DDD]"
                                        }`}
                                      />
                                      <div
                                        className={`w-2.5 h-2.5 rounded-full ${
                                          drawbackIndex === 4
                                            ? "bg-[#008037]"
                                            : "bg-[#DDD]"
                                        }`}
                                      />
                                    </div>
                                    <Button
                                      className="shadow-none hover:shadow-none p-2 rounded-full bg-white hover:bg-black hover:bg-opacity-5 transition-all duration-300 ease-in-out benefit-next"
                                      onClick={() =>
                                        setDrawbackIndex(drawbackIndex + 1)
                                      }
                                    >
                                      <FaChevronRight className="w-4 h-4 text-black" />
                                    </Button>
                                  </div>
                                </CardBody>
                              </Card>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </div>
                  </div>
                </div>
              </TabPanel>

              {/* POWERFUL ETFs */}
              <TabPanel value="Powerful ETFs" className="p-0">
                <div className="bg-[#F6F6F6] p-5 rounded-b-md">
                  {/* ETFs LIST */}
                  <Swiper
                    navigation
                    modules={[Navigation, EffectFade]}
                    effect="fade"
                    fadeEffect={{ crossFade: false }}
                    slidesPerView={4}
                    spaceBetween={15}
                  >
                    <SwiperSlide>
                      <div className="grid grid-cols-1 xl:grid-cols-4 lg:grid-cols-4 gap-5">
                        {stocksList.slice(0, 4).map((data, index) => (
                          <div className="col-span-1" key={index}>
                            <ETFSelectionBox
                              name={data.name}
                              image={data.image}
                              strategyName={data.strategyName}
                              onClick={() =>
                                setSelectedETF({
                                  name: data.name,
                                  strategyName: data.strategyName,
                                })
                              }
                            />
                          </div>
                        ))}
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="grid grid-cols-1 xl:grid-cols-4 lg:grid-cols-4 gap-5">
                        {stocksList.slice(4, 8).map((data, index) => (
                          <div className="col-span-1" key={index}>
                            <ETFSelectionBox
                              name={data.name}
                              image={data.image}
                              strategyName={data.strategyName}
                              onClick={() =>
                                setSelectedETF({
                                  name: data.name,
                                  strategyName: data.strategyName,
                                })
                              }
                            />
                          </div>
                        ))}
                      </div>
                    </SwiperSlide>
                  </Swiper>

                  {/* INPUT DATA AND CHART */}
                  <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 gap-0 xl:gap-5 lg:gap-5 mt-5">
                    {/* CALCULATOR DATA */}
                    <div className="col-span-1">
                      <Card className="bg-white shadow-none rounded-md border border-borderColor border-opacity-20">
                        <CardBody className="p-5">
                          <h5 className="text-lg font-redhat-bold text-black">
                            Investment Calculator
                          </h5>
                          <form className="mt-3">
                            <div className="form-group">
                              <label className="text-base font-redhat-semibold text-black">
                                How much do you have invested now?
                              </label>
                              <div className="mt-2 relative">
                                <InputBox
                                  type="number"
                                  name="investmentValue"
                                  id="investmentValue"
                                  placeholder="10000"
                                />

                                <div className="absolute top-3 right-5">
                                  <p className="text-base font-redhat-semibold text-greyText">
                                    USD
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="form-group mt-4">
                              <label className="text-base font-redhat-semibold text-black">
                                How many years will you invest for?
                              </label>
                              <div className="mt-2 relative">
                                <InputBox
                                  type="number"
                                  name="investmentTime"
                                  id="investmentTime"
                                  placeholder="10"
                                />

                                <div className="absolute top-3 right-5">
                                  <p className="text-base font-redhat-semibold text-greyText">
                                    Years
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="form-group mt-4">
                              <label className="text-base font-redhat-semibold text-black">
                                How often will you invest more?
                              </label>
                              <div className="mt-2">
                                <Select
                                  options={contributingOptions}
                                  classNamePrefix="invest"
                                  placeholder="Select contribution"
                                  className="react-select"
                                />
                              </div>
                            </div>
                            <div className="form-group mt-4">
                              <label className="text-base font-redhat-semibold text-black">
                                How much will you invest each time?
                              </label>
                              <div className="mt-2 relative">
                                <InputBox
                                  type="number"
                                  name="investValueEachTime"
                                  id="investValueEachTime"
                                  placeholder="1500"
                                />

                                <div className="absolute top-3 right-5">
                                  <p className="text-base font-redhat-semibold text-greyText">
                                    USD
                                  </p>
                                </div>
                              </div>
                            </div>
                            <Button
                              className="mt-5 w-full shadow-none hover:shadow-none bg-logoGreen hover:bg-opacity-80 rounded-md normal-case text-base font-redhat-bold transition-all duration-300 ease-in-out"
                              onClick={() => setStrategySetup(!strategySetup)}
                            >
                              ✨ Automate {selectedETF.strategyName}
                            </Button>
                          </form>
                        </CardBody>
                      </Card>
                    </div>

                    {/* CHART DATA */}
                    <div className="col-span-2">
                      <Card className="bg-white shadow-none rounded-md border border-borderColor border-opacity-20">
                        <CardBody className="p-5">
                          <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3">
                            {investorLabels.map((data, index) => (
                              <div className="col-span-1" key={index}>
                                <InvestmentValue
                                  label={data.label}
                                  value={data.value}
                                  color={data.color}
                                />
                              </div>
                            ))}
                          </div>

                          {/* CHARTS */}
                          <div className="-mt-11">
                            <DashboardChart
                              values={graphData}
                              labels={labels}
                            />
                          </div>
                        </CardBody>
                      </Card>
                    </div>
                  </div>
                </div>

                {/* ETF BASED DATA */}
                <div className="mt-5">
                  <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 gap-5">
                    <div className="col-span-1">
                      <div className="bg-[#F6F6F6] p-5 rounded-md">
                        {selectedETF.name === "Ticker: VOO" && <AboutVOO />}
                        {selectedETF.name === "Ticker: QQQ" && <AboutQQQ />}
                        {selectedETF.name === "Ticker: SCHD" && <AboutSCHD />}
                        {selectedETF.name === "Ticker: GLD" && <AboutGLD />}
                        {selectedETF.name === "Ticker: VUG" && <AboutVUG />}
                        {selectedETF.name === "Ticker: IWM" && <AboutIWM />}
                        {selectedETF.name === "Ticker: SWSX" && <AboutSWSX />}
                        {selectedETF.name === "Ticker: DIA" && <AboutDIA />}
                      </div>
                    </div>
                    <div className="col-span-1">
                      <Card className="h-full bg-[#F6F6F6] shadow-none rounded-md">
                        <CardBody className="p-5">
                          <h5 className="text-lg font-redhat-bold text-black">
                            About this ETF
                          </h5>
                          <div className="mt-1">
                            <p className="text-base font-redhat-regular text-black">
                              This is some text about the strategy overview that
                              would explain what it is, where it was derived
                              from and who it’s for...
                            </p>
                          </div>
                          <div className="mt-4">
                            <div className="flex items-center justify-between">
                              <p className="text-base font-redhat-semibold text-black">
                                Estimated Return
                              </p>
                              {selectedInvestor === "Warren Buffet" && (
                                <p className="text-base font-redhat-semibold text-black">
                                  13.24% per year (High)
                                </p>
                              )}
                              {selectedInvestor === "Jack Bogle" && (
                                <p className="text-base font-redhat-semibold text-black">
                                  8.13% per year (High)
                                </p>
                              )}
                            </div>
                            <div className="mt-2">
                              {selectedInvestor === "Warren Buffet" && (
                                <Progressbar percentage={80} />
                              )}

                              {selectedInvestor === "Jack Bogle" && (
                                <Progressbar percentage={70} />
                              )}
                            </div>
                          </div>
                          <div className="mt-4">
                            <div className="flex items-center justify-between">
                              <p className="text-base font-redhat-semibold text-black">
                                Risk Level
                              </p>
                              {selectedInvestor === "Warren Buffet" && (
                                <p className="text-base font-redhat-semibold text-black">
                                  3.6 / 5.0 (Medium)
                                </p>
                              )}
                              {selectedInvestor === "Jack Bogle" && (
                                <p className="text-base font-redhat-semibold text-black">
                                  3.0 / 5.0 (Medium)
                                </p>
                              )}
                            </div>
                            <div className="mt-2">
                              {selectedInvestor === "Warren Buffet" && (
                                <Progressbar percentage={70} />
                              )}

                              {selectedInvestor === "Jack Bogle" && (
                                <Progressbar percentage={60} />
                              )}
                            </div>
                          </div>
                          <div className="mt-5">
                            <p className="text-base font-redhat-semibold text-black">
                              Top holdings
                            </p>
                            <div className="mt-3 bg-white rounded-md p-3">
                              <div className="flex items-center justify-between">
                                <p className="text-base font-redhat-semibold text-black">
                                  Apple Inc. (AAPL)
                                </p>
                                <p className="text-base font-redhat-semibold text-black">
                                  15.9%
                                </p>
                              </div>
                              <div className="mt-2 flex items-center justify-between">
                                <p className="text-base font-redhat-semibold text-black">
                                  Alphabet Inc. (GOOG)
                                </p>
                                <p className="text-base font-redhat-semibold text-black">
                                  13.2%
                                </p>
                              </div>
                              <div className="mt-2 flex items-center justify-between">
                                <p className="text-base font-redhat-semibold text-black">
                                  Amazon Inc. (AMZN)
                                </p>
                                <p className="text-base font-redhat-semibold text-black">
                                  9.1%
                                </p>
                              </div>
                              <div className="mt-2 flex items-center justify-between">
                                <p className="text-base font-redhat-semibold text-black">
                                  Nvidia Inc. (NVDA)
                                </p>
                                <p className="text-base font-redhat-semibold text-black">
                                  7.4%
                                </p>
                              </div>
                              <Button className="mt-3 w-full text-center py-2 shadow-none hover:shadow-none bg-white hover:bg-logoGreen hover:bg-opacity-5 rounded-md transition-all duration-300 ease-in-out text-logoGreen normal-case font-redhat-semibold text-base">
                                See the full list
                              </Button>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </div>
                    <div className="col-span-1">
                      <Card className="h-full shadow-none bg-[#F6F6F6] rounded-lg">
                        <CardBody className="p-5">
                          <h5 className="text-lg font-redhat-bold text-black">
                            Benefits
                          </h5>
                          <div className="mt-3">
                            <Card className="bg-white w-full rounded-md shadow-none">
                              <CardBody className="p-5">
                                <Swiper
                                  modules={[Navigation, EffectFade]}
                                  navigation={{
                                    nextEl: ".benefit-next",
                                    prevEl: ".benefit-prev",
                                  }}
                                  slidesPerView={1}
                                  effect="fade"
                                  fadeEffect={{ crossFade: true }}
                                >
                                  <SwiperSlide>
                                    <p className="w-11/12 mx-auto text-sm font-redhat-semibold text-black text-center">
                                      You own 10,000 different securities
                                      through these investments meaning you have
                                      a lot of diversity.
                                    </p>
                                  </SwiperSlide>
                                  <SwiperSlide>
                                    <p className="w-11/12 mx-auto text-sm font-redhat-semibold text-black text-center">
                                      You own 9,000 different securities through
                                      these investments meaning you have a lot
                                      of diversity.
                                    </p>
                                  </SwiperSlide>
                                  <SwiperSlide>
                                    <p className="w-11/12 mx-auto text-sm font-redhat-semibold text-black text-center">
                                      You own 8,000 different securities through
                                      these investments meaning you have a lot
                                      of diversity.
                                    </p>
                                  </SwiperSlide>
                                  <SwiperSlide>
                                    <p className="w-11/12 mx-auto text-sm font-redhat-semibold text-black text-center">
                                      You own 7,000 different securities through
                                      these investments meaning you have a lot
                                      of diversity.
                                    </p>
                                  </SwiperSlide>
                                </Swiper>

                                <div className="mt-7 flex items-center justify-between">
                                  <Button
                                    className="shadow-none hover:shadow-none p-2 rounded-full bg-white hover:bg-black hover:bg-opacity-5 transition-all duration-300 ease-in-out benefit-prev"
                                    onClick={() =>
                                      setActiveIndex(activeIndex - 1)
                                    }
                                  >
                                    <FaChevronLeft className="w-4 h-4 text-black" />
                                  </Button>
                                  <div className="flex items-center gap-2">
                                    <div
                                      className={`w-2.5 h-2.5 rounded-full ${
                                        activeIndex === 1
                                          ? "bg-[#008037]"
                                          : "bg-[#DDD]"
                                      }`}
                                    />
                                    <div
                                      className={`w-2.5 h-2.5 rounded-full ${
                                        activeIndex === 2
                                          ? "bg-[#008037]"
                                          : "bg-[#DDD]"
                                      }`}
                                    />
                                    <div
                                      className={`w-2.5 h-2.5 rounded-full ${
                                        activeIndex === 3
                                          ? "bg-[#008037]"
                                          : "bg-[#DDD]"
                                      }`}
                                    />
                                    <div
                                      className={`w-2.5 h-2.5 rounded-full ${
                                        activeIndex === 4
                                          ? "bg-[#008037]"
                                          : "bg-[#DDD]"
                                      }`}
                                    />
                                  </div>
                                  <Button
                                    className="shadow-none hover:shadow-none p-2 rounded-full bg-white hover:bg-black hover:bg-opacity-5 transition-all duration-300 ease-in-out benefit-next"
                                    onClick={() =>
                                      setActiveIndex(activeIndex + 1)
                                    }
                                  >
                                    <FaChevronRight className="w-4 h-4 text-black" />
                                  </Button>
                                </div>
                              </CardBody>
                            </Card>
                          </div>

                          <div className="mt-5">
                            <h5 className="text-lg font-redhat-bold text-black">
                              Drawbacks
                            </h5>
                            <div className="mt-3">
                              <Card className="bg-white w-full rounded-md shadow-none">
                                <CardBody className="p-5">
                                  <Swiper
                                    modules={[Navigation, EffectFade]}
                                    navigation={{
                                      nextEl: ".benefit-next",
                                      prevEl: ".benefit-prev",
                                    }}
                                    slidesPerView={1}
                                    effect="fade"
                                    fadeEffect={{ crossFade: true }}
                                  >
                                    <SwiperSlide>
                                      <p className="w-11/12 mx-auto text-sm font-redhat-semibold text-black text-center">
                                        Whoa, here are some downsides to this
                                        strategy. This is the first one.
                                      </p>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                      <p className="w-11/12 mx-auto text-sm font-redhat-semibold text-black text-center">
                                        Whoa, here are some downsides to this
                                        strategy. This is the first one.
                                      </p>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                      <p className="w-11/12 mx-auto text-sm font-redhat-semibold text-black text-center">
                                        Whoa, here are some downsides to this
                                        strategy. This is the first one.
                                      </p>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                      <p className="w-11/12 mx-auto text-sm font-redhat-semibold text-black text-center">
                                        Whoa, here are some downsides to this
                                        strategy. This is the first one.
                                      </p>
                                    </SwiperSlide>
                                  </Swiper>

                                  <div className="mt-7 flex items-center justify-between">
                                    <Button
                                      className="shadow-none hover:shadow-none p-2 rounded-full bg-white hover:bg-black hover:bg-opacity-5 transition-all duration-300 ease-in-out benefit-prev"
                                      onClick={() =>
                                        setDrawbackIndex(drawbackIndex - 1)
                                      }
                                    >
                                      <FaChevronLeft className="w-4 h-4 text-black" />
                                    </Button>
                                    <div className="flex items-center gap-2">
                                      <div
                                        className={`w-2.5 h-2.5 rounded-full ${
                                          drawbackIndex === 1
                                            ? "bg-[#008037]"
                                            : "bg-[#DDD]"
                                        }`}
                                      />
                                      <div
                                        className={`w-2.5 h-2.5 rounded-full ${
                                          drawbackIndex === 2
                                            ? "bg-[#008037]"
                                            : "bg-[#DDD]"
                                        }`}
                                      />
                                      <div
                                        className={`w-2.5 h-2.5 rounded-full ${
                                          drawbackIndex === 3
                                            ? "bg-[#008037]"
                                            : "bg-[#DDD]"
                                        }`}
                                      />
                                      <div
                                        className={`w-2.5 h-2.5 rounded-full ${
                                          drawbackIndex === 4
                                            ? "bg-[#008037]"
                                            : "bg-[#DDD]"
                                        }`}
                                      />
                                    </div>
                                    <Button
                                      className="shadow-none hover:shadow-none p-2 rounded-full bg-white hover:bg-black hover:bg-opacity-5 transition-all duration-300 ease-in-out benefit-next"
                                      onClick={() =>
                                        setDrawbackIndex(drawbackIndex + 1)
                                      }
                                    >
                                      <FaChevronRight className="w-4 h-4 text-black" />
                                    </Button>
                                  </div>
                                </CardBody>
                              </Card>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </div>
                  </div>
                </div>
              </TabPanel>
            </TabsBody>
          </Tabs>
        </div>
      </div>

      {/* STRATEGY SETUP MODAL */}
      <Dialog
        open={strategySetup}
        handler={() => setStrategySetup(!strategySetup)}
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
        size="xs"
      >
        <DialogBody className="p-5">
          {stepOne && (
            <>
              <div className="flex items-center justify-between">
                <h5 className="text-md font-redhat-bold text-black">
                  Strategy Setup
                </h5>
                <Button
                  className="shadow-none hover:shadow-none p-2 rounded-full bg-white hover:bg-black hover:bg-opacity-10 transition-all duration-300 ease-in-out"
                  onClick={() => setStrategySetup(!strategySetup)}
                >
                  <IoClose className="w-4 h-4 text-black" />
                </Button>
              </div>
              <div className="mt-5">
                <div
                  className={`w-full ${
                    !dropdownClicked
                      ? "rounded-md"
                      : "rounded-t-md rounded-b-none"
                  } bg-[#008037] hover:bg-opacity-80 p-2 pr-5 relative cursor-pointer transition-all duration-300 ease-in-out`}
                  onClick={() => setDropdownClicked(!dropdownClicked)}
                >
                  <DropdownOption
                    name={selectedOption.name}
                    image={selectedOption.image}
                    strategyName={selectedOption.strategyName}
                  />

                  {dropdownClicked && (
                    <div className="absolute w-full top-16 left-0 bg-[#F6F6F6] rounded-b-md h-[20vh] overflow-auto overflow-x-hidden overflow-y-auto p-3">
                      {stocksList.map((data, index) => (
                        <div
                          className="mt-2 first:mt-0 rounded-md w-full bg-white hover:bg-black hover:bg-opacity-10 p-2 pr-5 relative cursor-pointer transition-all duration-300 ease-in-out"
                          key={index}
                          onClick={() =>
                            setSelectedOption({
                              name: data.name,
                              image: data.image,
                              strategyName: data.strategyName,
                            })
                          }
                        >
                          <OptionBox
                            name={data.name}
                            image={data.image}
                            strategyName={data.strategyName}
                          />
                        </div>
                      ))}
                      {stocksList.map((data, index) => (
                        <div
                          className="mt-2 first:mt-0 rounded-md w-full bg-white hover:bg-black hover:bg-opacity-10 p-2 pr-5 relative cursor-pointer transition-all duration-300 ease-in-out"
                          key={index}
                        >
                          <OptionBox
                            name={data.name}
                            image={data.image}
                            strategyName={data.strategyName}
                          />
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
              <div className="mt-5">
                <p className="text-base font-redhat-semibold text-black">
                  How often will you invest?
                </p>
                <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-5 mt-2">
                  <div className="col-span-1">
                    <Select
                      options={contributingOptions}
                      classNamePrefix="invest"
                      placeholder="Contribution"
                      className="react-select"
                    />
                  </div>
                  <div className="col-span-1">
                    <ReactDatePicker
                      className="w-full rounded-md px-4 py-3 font-redhat-semibold bg-[#F6F6F6]"
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      placeholderText="Starting"
                      minDate={moment().toDate()}
                      excludeDates={[new Date()]}
                      filterDate={(date) => !isDateExcluded(date)}
                    />
                  </div>
                </div>
              </div>
              <div className="mt-5">
                <label className="text-base font-redhat-semibold text-black">
                  How much will you invest each time?
                </label>
                <div className="mt-2 relative">
                  <InputBox
                    type="number"
                    name="investmentValue"
                    id="investmentValue"
                    placeholder="10000"
                  />

                  <div className="absolute top-3 right-5">
                    <p className="text-base font-redhat-semibold text-greyText">
                      USD
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-5">
                <div
                  className="w-full bg-[#F6F6F6] rounded-md p-2 pr-5 relative"
                  onClick={() => setBrokerDropdown(!brokerDropdown)}
                >
                  <BrokerBox
                    name={selectedBroker.name}
                    image={selectedBroker.image}
                    balance={selectedBroker.balance}
                    text={selectedBroker.text}
                  />

                  {brokerDropdown && (
                    <div className="absolute w-full top-16 left-0 bg-[#F6F6F6] rounded-b-md h-[20vh] overflow-auto overflow-x-hidden overflow-y-auto p-3">
                      {brokers.map((data, index) => (
                        <div
                          className="mt-2 first:mt-0 rounded-md w-full bg-white hover:bg-black hover:bg-opacity-10 p-2 pr-5 relative cursor-pointer transition-all duration-300 ease-in-out"
                          key={index}
                          onClick={() =>
                            setSelectedBroker({
                              name: data.name,
                              image: data.image,
                              text: data.strategyName,
                              balance: data.balance,
                            })
                          }
                        >
                          <BrokerBox
                            name={data.name}
                            image={data.image}
                            text={data.text}
                            balance={data.balance}
                          />
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
              <div className="mt-5">
                <p className="text-base font-redhat-semibold text-black">
                  Automation review
                </p>
                <div className="mt-2 bg-[#F6F6F6] rounded-md p-3">
                  <p className="text-sm font-redhat-semibold text-black">
                    Every month, you’ll invest $1,500 according to Warren
                    Buffett’s 90/10 Strategy via the selected TD Ameritrade
                    account.{" "}
                  </p>
                </div>
              </div>
              <Button
                className="mt-5 w-full shadow-none hover:shadow-none bg-logoGreen hover:bg-opacity-80 rounded-md normal-case text-base font-redhat-bold transition-all duration-300 ease-in-out"
                onClick={() => {
                  setStepOne(!stepOne);
                  setStepTwo(!stepTwo);
                }}
              >
                Start Now
              </Button>
            </>
          )}

          {stepTwo && (
            <div className="p-0">
              <h5 className="text-lg font-redhat-bold text-black text-center">
                Your Automation is Ready!
              </h5>
              <div className="mt-3">
                <img
                  src={require("../../../assets/images/icons/automation-success.png")}
                  className="w-4/5 mx-auto h-auto"
                  alt="Your automation is ready"
                />
              </div>
              <div className="mt-5">
                <h5 className="text-md font-redhat-semibold text-black text-center">
                  Start your ScaleTrade plan to begin using your new automation.{" "}
                </h5>
              </div>
              <div className="flex items-center justify-center">
                <Button
                  className="mt-5 w-fit mx-auto px-10 shadow-none hover:shadow-none bg-logoGreen hover:bg-opacity-80 rounded-md normal-case text-base font-redhat-bold transition-all duration-300 ease-in-out"
                  onClick={() => {
                    setStrategySetup(!strategySetup);
                  }}
                >
                  Start ScaleTrade Plan
                </Button>
              </div>
            </div>
          )}
        </DialogBody>
      </Dialog>

      {/* AUTOMATION STARTED MODAL */}
      <Dialog
        open={automationStarted}
        handler={() => setAutomationStarted(!automationStarted)}
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
        size="xs"
      >
        <DialogBody className="p-5">
          <div className="p-0">
            <h5 className="text-lg font-redhat-bold text-black text-center">
              Your New Automation is{" "}
              <span className="italic font-redhat-bold">STARTED</span>!
            </h5>
            <div className="mt-3">
              <img
                src={require("../../../assets/images/icons/automation-success.png")}
                className="w-4/5 mx-auto h-auto"
                alt="Your automation is ready"
              />
            </div>
            <div className="mt-5">
              <h5 className="text-md font-redhat-bold text-black text-center">
                Your first investment will be made on 5/24/24
              </h5>
              <h5 className="mt-1 text-md font-redhat-regular text-black text-center">
                Keep your automations running to stay on track with your
                investment plan.
              </h5>
            </div>
            <div className="flex items-center justify-center">
              <Button
                className="mt-5 w-fit mx-auto px-10 shadow-none hover:shadow-none bg-logoGreen hover:bg-opacity-80 rounded-md normal-case text-base font-redhat-bold transition-all duration-300 ease-in-out"
                onClick={() => {
                  setAutomationStarted(!automationStarted);
                }}
              >
                Great! Let's go
              </Button>
            </div>
          </div>
        </DialogBody>
      </Dialog>

      {/* PAUSE MODAL */}
      <Dialog
        open={pauseAutomation}
        handler={() => setPauseAutomation(!pauseAutomation)}
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
        size="xs"
      >
        <DialogBody className="p-5">
          <div className="p-0">
            <h5 className="text-lg font-redhat-bold text-black text-center">
              Are you sure?
            </h5>
            <div className="mt-3">
              <img
                src={require("../../../assets/images/icons/automation-pause.png")}
                className="w-4/5 mx-auto h-auto"
                alt="Pause automation"
              />
            </div>
            <div className="mt-5">
              <h5 className="text-md font-redhat-regular text-black text-center">
                By pausing your automation, you’re interrupting the powerful
                compounding process.
              </h5>
            </div>
            <div className="flex items-center gap-0">
              <Button
                className="mt-5 w-fit mx-auto whitespace-nowrap shadow-none hover:shadow-none bg-logoGreen hover:bg-opacity-80 rounded-md normal-case text-base font-redhat-bold transition-all duration-300 ease-in-out"
                onClick={() => {
                  setPauseAutomation(!pauseAutomation);
                }}
              >
                Actually, don't pause
              </Button>
              <Button className="mt-5 w-fit whitespace-nowrap mx-auto shadow-none hover:shadow-none bg-[#494949] hover:bg-opacity-80 rounded-md normal-case text-base text-white font-redhat-bold transition-all duration-300 ease-in-out">
                Confirm pause
              </Button>
            </div>
          </div>
        </DialogBody>
      </Dialog>
    </section>
  );
};

export default Selection;
