/* IMPORT ACTIONS FOR USER REDUCER */
import actions from "../actions/userNotification.action";

/* INITIALIZE STATE VARIABLES */
const initialState = {
  notificationList: [],
};

/* INITIALIZE ACTIONS */
const { ADD_NOTIFICATION, MARK_AS_READ } = actions;

/* CREATE USER REDUCER FUNCTION */
const userReducer = (state = initialState, action) => {
  const { type, data } = action;

  /* CREATE SWITCH CASE FOR ACTIONS */
  switch (type) {
    case ADD_NOTIFICATION:
      return {
        ...state,
        notificationList: data,
      };
    case MARK_AS_READ:
      let oldArrayList = state.notificationList;
      let newArrayList = [];
      oldArrayList.forEach((element) => {
        newArrayList = [
          ...newArrayList,
          {
            id: element?.id,
            header: element?.header,
            content: element?.content,
            status: false,
          },
        ];
      });
      return {
        ...state,
        notificationList: newArrayList,
      };
    /* DEFAULT RETURN GLOBAL STATE */
    default:
      return state;
  }
};

export default userReducer;
