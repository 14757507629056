/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Select from "react-select";
import ReactDatePicker from "react-datepicker";

/* MATERIAL TAILWIND COMPONENTS */
import { Button, Dialog, DialogBody } from "@material-tailwind/react";

/* ICONS */
import { IoClose } from "react-icons/io5";
import { FaChevronUp, FaChevronDown } from "react-icons/fa6";
import { FaPlus } from "react-icons/fa";

/* ASSETS */
import Alpaca from "assets/images/logos/alpaca.png";
import Robinhood from "assets/images/logos/robinhood.png";

import Tradier from "assets/images/logos/tradier.png";
import TradeStation from "assets/images/logos/trade-station.png";
import Webull from "assets/images/logos/webull.png";
import CharlesSchwab from "assets/images/logos/charles-schwab.jpeg";
import ETrade from "assets/images/logos/e-trade.webp";

/* API IMPORTS */
import {
  getAutomationInformationApi,
  userStrategySetApi,
} from "../../service/api";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import moment from "moment";
import { SET_FORMAT } from "config";
import { stocksList } from "data/investors";

/* CONTRIBUTION OPTIONS */
const contributionOptions = [
  // { value: "Per week", label: "Per week", isDisabled: true },
  { value: "Monthly", label: "Per Month" },
  {
    value: "Bi-weekly (Coming Soon)",
    label: "Bi-weekly (Coming Soon)",
    isDisabled: true,
  },
];

/* INPUT BOX */
const InputBox = (props) => {
  return (
    <input
      type={props.type}
      name={props.name}
      id={props.id}
      value={props.value}
      onChange={props.onChange}
      placeholder={props.placeholder}
      className={`w-full block  ${
        props?.error ? "bg-red-50 placeholder:text-red-500 " : "bg-[#F6F6F6] "
      } rounded-md px-5 py-2.5 outline-none focus:outline-none text-base placeholder:font-redhat-regular text-black`}
    />
  );
};

const NewCreateAutomationComponent = (props) => {
  const userId = useSelector((state) => state?.user?.userId);
  const brokerAccountsList = useSelector((state) => state?.user?.allAccounts);
  const accountId = useSelector((state) => state?.user?.accountId);
  const allConnectedAccountsList = useSelector(
    (state) => state?.user?.allConnectedAccounts
  );
  const [brokerDropdown, setBrokerDropdown] = useState(false);
  const [dropdownClicked, setDropdownClicked] = useState(false);

  const [loading, setLoading] = useState(false);
  const [automationList, setAutomationList] = useState([]);
  const [startDate, setStartDate] = useState();
  const [showOtherAccounts, setShowOtherAccounts] = useState(false);
  const [meanRiskValue, setMeanRiskValue] = useState(0);
  const [noETFDialog, setNoETFDialog] = useState(false);
  const [noEFTStep, setNoETFStep] = useState(1);

  const [investingAmount, setInvestingAmount] = useState(null);

  const [errorMessage, setErrorMessage] = useState(false);

  const [newBrokerages, setNewBrokerages] = useState(false);

  const [selectedAccountId, setSelectedAccountId] = useState(null);

  const handleAccordionStateChange = (id) => {
    setErrorMessage("");
    let currentState = automationList.find((item) => item?.id === id);
    setAutomationList((prevItems) =>
      prevItems.map((item) =>
        item.id === id ? { ...item, state: !currentState?.state } : item
      )
    );
  };

  const isDateExcluded = (date) => {
    const day = date.getDate();
    return day === 29 || day === 30 || day === 31;
  };

  useEffect(() => {
    handleAmountChange(
      automationList.find((ele) => ele?.selected === true)?.id
    );
  }, [investingAmount]);
  const handleSelectedStateChange = (id) => {
    setErrorMessage("");
    let currentState = automationList.find((item) => item?.id === id);
    currentState.selected = true;
    currentState.investingAmount = null;
    let tempArray = [];

    automationList
      .filter((item) => item?.id !== id)
      ?.forEach((ele) => {
        tempArray.push({ ...ele, selected: false, investingAmount: null });
      });

    tempArray.push(currentState);
    if (investingAmount) {
      setInvestingAmount(investingAmount);
    } else {
      setInvestingAmount(0);
    }
    setAutomationList(
      tempArray.sort((a, b) => {
        return a.id - b.id;
      })
    );
  };

  /* ACCOUNT OPTIONS */
  const AccountOptions = (props) => {
    return (
      <div className="bg-transparent hover:bg-black hover:bg-opacity-5 transition-all duration-300 ease-in-out flex items-center gap-5 cursor-pointer p-2">
        <div
          className={`${
            props.type !== "dropdown" ? "w-full" : "w-11/12"
          } flex items-center gap-3`}
        >
          <img
            src={props.img}
            className="w-12 h-12 rounded-md object-cover"
            alt={props.name}
          />
          <div className="p-0">
            <div className="flex items-center justify-between">
              <h5 className="text-base font-redhat-semibold text-black">
                {props.brokerName}
              </h5>
              <h5 className="text-base font-redhat-semibold text-black">
                {SET_FORMAT(props.balance)}
              </h5>
            </div>
            <p className="text-sm font-redhat-regular text-greyText leading-snug">
              You have{" "}
              <span className="font-redhat-bold">
                {SET_FORMAT(props.investmentBalance)}
              </span>{" "}
              in cash to invest in this account.
            </p>
          </div>
        </div>

        {props.type !== "dropdown" && (
          <div className="w-1/12 flex items-center justify-center">
            {brokerDropdown ? (
              <FaChevronUp className="w-3 h-3 text-black" />
            ) : (
              <FaChevronDown className="w-3 h-3 text-black" />
            )}
          </div>
        )}
      </div>
    );
  };

  /* OPTION BOX */
  const OptionBox = (props) => {
    return (
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-3">
          <img
            src={props.image}
            className="w-12 h-12 rounded-md object-cover"
            alt={props.name}
          />
          <div className="p-0">
            <h5 className="text-base font-redhat-semibold text-black">
              {props.name}
            </h5>
            <p className="text-sm font-redhat-regular text-greyText">
              {props.strategyName}
            </p>
          </div>
        </div>
      </div>
    );
  };

  const handleGetAutomationInformationAllFunction = () => {
    getAutomationInformationApi().then((res) => {
      let tempArrayList = [];

      stocksList?.forEach((element, index) => {
        setInvestingAmount(props?.calculatorDetails?.monthlyContribution);

        props?.selectedInvestment
          ? tempArrayList.push({
              ...element,
              id: element?.id,
              state: false,
              selected:
                props?.selectedInvestment?.id === element?.id ? true : false,
              investingAmount: props?.calculatorDetails?.monthlyContribution
                ? props?.calculatorDetails?.monthlyContribution
                : null,
              error: false,
              startDate: new Date(),
            })
          : tempArrayList.push({
              ...element,
              id: element?.id,
              state: false,
              selected: index === 0 ? true : false,
              investingAmount: null,
              error: false,
              startDate: new Date(),
            });
      });
      setAutomationList(tempArrayList);
    });
  };

  const handleAmountChange = (id) => {
    setErrorMessage("");
    setAutomationList((prevItems) =>
      prevItems.map((item) =>
        item.id === id
          ? {
              ...item,
              investingAmount: parseFloat(investingAmount),
              error: false,
            }
          : item
      )
    );
  };

  const calculateMeanValue = () => {
    let filteredList = automationList?.filter((el) => el?.selected === true);

    if (filteredList?.length > 0) {
      const extractedValues = filteredList.map((obj) => obj.risk_score);

      const sumValues = extractedValues.reduce((acc, val) => acc + val, 0);
      const numElements = extractedValues.length;

      // Calculate the mean (average)
      const meanValue = sumValues / numElements;

      setMeanRiskValue(meanValue?.toFixed(2));
    } else {
      setMeanRiskValue(0);
    }
  };

  const handleSubmitForm = () => {
    automationList?.forEach((element) => {
      if (
        element?.selected === true &&
        (element?.investingAmount === null || element?.investingAmount === 0)
      ) {
        setAutomationList((prevItems) =>
          prevItems.map((item) =>
            item.id === element?.id ? { ...item, error: true } : item
          )
        );
      }
    });

    let tempArray = [];
    automationList?.forEach((element) => {
      if (
        element?.selected === true &&
        (element?.investingAmount !== null || element?.investingAmount !== 0)
      ) {
        tempArray.push({
          accountId: selectedAccountId,
          underlying: element?.underlying,
          amount: parseFloat(element?.investingAmount),
          strategy: "monthly-" + moment(startDate).format("DD"),
        });
      }
    });

    if (automationList?.filter((el) => el?.selected === true)?.length === 0) {
      toast.error("Please select any one ticker");
      setErrorMessage("Please select any one ticker");
    } else {
      let cumulativeCashRequired = 0;
      automationList
        ?.filter((el) => el?.selected === true)
        ?.forEach((el) => {
          cumulativeCashRequired += parseFloat(el?.investingAmount);
        });

      if (
        allConnectedAccountsList?.accounts?.find(
          (el) => el?.accountId === selectedAccountId
        )?.cash < cumulativeCashRequired
      ) {
        setErrorMessage(
          "Minimum cash balance of  " +
            SET_FORMAT(cumulativeCashRequired) +
            " required to start automation"
        );
      } else {
        if (
          automationList?.filter(
            (el) => el?.selected === true && el?.error === true
          ).length === 0 &&
          automationList?.find((el) => el?.selected === true)
            ?.investingAmount !== null &&
          automationList?.find((el) => el?.selected === true)
            ?.investingAmount !== 0 &&
          !isNaN(
            automationList?.find((el) => el?.selected === true)?.investingAmount
          )
        ) {
          setLoading(true);
          userStrategySetApi(userId, { add: tempArray, delete: [] })
            .then((res) => {
              toast.success(res?.message);
              props.setStartDate(startDate);
              props.setStep(2);
            })
            .catch((er) => {
              console.log("Error: ", er);
              toast.error(er?.response?.data?.message);
            })
            .finally(() => {
              setLoading(false);
            });
        } else {
          if (
            automationList?.find((el) => el?.selected === true)
              ?.investingAmount === null ||
            automationList?.find((el) => el?.selected === true)
              ?.investingAmount === 0 ||
            isNaN(
              automationList?.find((el) => el?.selected === true)
                ?.investingAmount
            )
          ) {
            setErrorMessage("Investment Amount should not be empty or zero");
            toast.error("Investment Amount should not be empty or zero");
          } else {
            setErrorMessage("Please select any one ticker");
            toast.error("Please select any one ticker");
          }
        }
      }
    }
  };

  const handleReview = () => {
    let finalContent = "";
    if (automationList?.filter((el) => el?.selected === true)?.length > 0) {
      automationList
        ?.filter((el) => el?.selected === true)
        ?.forEach((element, index) => {
          if (index === 0) {
            finalContent += `$${
              element?.investingAmount ? element?.investingAmount : 0
            } into the ${element?.name}'s ${element?.strategyName}`;
          } else if (
            index + 1 ===
            automationList?.filter((el) => el?.selected === true)?.length
          ) {
            finalContent += ` and $${
              element?.investingAmount ? element?.investingAmount : 0
            } into the ${element?.name}'s ${element?.strategyName}`;
          } else {
            finalContent += `, $${
              element?.investingAmount ? element?.investingAmount : 0
            } into the ${element?.name}'s ${element?.strategyName}`;
          }
        });
    }
    return finalContent;
  };

  /* USEEFFECTS */
  useEffect(() => {
    handleGetAutomationInformationAllFunction();
  }, []);

  useEffect(() => {
    calculateMeanValue();
  }, [automationList]);

  useEffect(() => {
    setSelectedAccountId(brokerAccountsList?.accounts?.[0]?.accountId);
  }, [accountId]);

  return (
    <>
      <>
        <div className="flex items-center justify-between">
          <h5 className="text-md font-redhat-bold text-black">
            Strategy Setup
          </h5>
          <Button
            className="shadow-none hover:shadow-none p-2 rounded-full bg-white hover:bg-black hover:bg-opacity-10 transition-all duration-300 ease-in-out"
            onClick={() => {
              props.setAutomationDialog(!props.strategySetup);
            }}
          >
            <IoClose className="w-4 h-4 text-black" />
          </Button>
        </div>
        <div className="mt-5">
          <div
            className={`w-full ${
              !dropdownClicked ? "rounded-md" : "rounded-t-md rounded-b-none"
            } bg-[#008037] hover:bg-opacity-80 p-2 pr-5 relative cursor-pointer transition-all duration-300 ease-in-out`}
            onClick={() => {
              setDropdownClicked(!dropdownClicked);
            }}
          >
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-3">
                <img
                  src={
                    automationList.find((ele) => ele?.selected === true)?.image
                  }
                  className="w-12 h-12 rounded-md object-cover"
                  alt={
                    automationList.find((ele) => ele?.selected === true)?.name
                  }
                />
                <div className="p-0">
                  <h5 className="text-base font-redhat-semibold text-white">
                    {automationList.find((ele) => ele?.selected === true)?.name}
                  </h5>
                  <p className="text-sm font-redhat-regular text-white text-opacity-80">
                    {
                      automationList.find((ele) => ele?.selected === true)
                        ?.strategyName
                    }
                  </p>
                </div>
              </div>
              {dropdownClicked ? (
                <FaChevronUp className="w-3 h-3 text-white" />
              ) : (
                <FaChevronDown className="w-3 h-3 text-white" />
              )}
            </div>

            {dropdownClicked && (
              <div className="absolute w-full  top-16 left-0 bg-[#F6F6F6] rounded-b-md h-[30vh] overflow-auto overflow-x-hidden overflow-y-auto p-3 z-50">
                {automationList.map(
                  (data, index) =>
                    data.selected === false && (
                      <div
                        className="mt-2 first:mt-0 rounded-md w-full bg-white hover:bg-black hover:bg-opacity-10 p-2 pr-5 relative cursor-pointer transition-all duration-300 ease-in-out"
                        key={index}
                        onClick={() => {
                          handleSelectedStateChange(data?.id);
                        }}
                      >
                        <OptionBox
                          name={data.name}
                          image={data.image}
                          strategyName={data.strategyName}
                        />
                      </div>
                    )
                )}
              </div>
            )}
          </div>
        </div>

        {/* HOW OFTEN WILL YOU INVEST */}
        <div className="mt-5">
          <p className="text-base font-redhat-semibold text-black">
            How often will you invest?
          </p>
          <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-5 mt-2">
            <div className="col-span-1">
              <Select
                options={contributionOptions}
                classNamePrefix="invest"
                value={contributionOptions?.[0]}
                placeholder="Contribution"
                className="react-select"
              />
            </div>
            <div className="col-span-1">
              <ReactDatePicker
                className="w-full rounded-md px-4 py-3 font-redhat-semibold bg-[#F6F6F6]"
                selected={startDate}
                onChange={(date) => {
                  setStartDate(date);
                  props.setStartDate(moment(date).format("mm/dd/yyy"));
                }}
                placeholderText="Starting"
                minDate={moment().toDate()}
                excludeDates={[new Date()]}
                filterDate={(date) => !isDateExcluded(date)}
              />
            </div>
          </div>
        </div>

        {/* HOW MUCH WILL YOU INVEST EACH TIME */}
        <div className="mt-5">
          <label className="text-base font-redhat-semibold text-black">
            How much will you invest each time?
          </label>
          <div className="mt-2 relative">
            <InputBox
              type="number"
              name="amount"
              id="amount"
              placeholder={
                automationList.find((ele) => ele?.selected === true)?.error
                  ? "Enter Amount"
                  : "10000"
              }
              error={
                automationList.find((ele) => ele?.selected === true)?.error
              }
              value={investingAmount}
              onChange={(e) => {
                setInvestingAmount(e?.target?.value);
              }}
            />

            <div className="absolute top-3 right-5">
              <p className="text-base font-redhat-semibold text-greyText">
                USD
              </p>
            </div>
          </div>
        </div>

        {/* BROKERAGE SELECTION */}
        <div className="mt-5">
          {brokerAccountsList?.accounts?.length === 0 ||
          brokerAccountsList?.accounts?.length === undefined ||
          brokerAccountsList?.length === 0 ? (
            <div
              onClick={() => {
                props.setAutomationDialog(!props.strategySetup);
                props.setBrokerDialog(!props?.brokerDialog);
              }}
              className="cursor-pointer w-full bg-[#F6F6F6] hover:bg-black hover:bg-opacity-10 rounded-md p-3 transition-all duration-300 ease-in-out"
            >
              <div className="flex items-center gap-4">
                <div className="w-[85%]">
                  <div className="flex items-center gap-3">
                    <img
                      src={require("../../assets/images/icons/connect.png")}
                      className="w-10 h-10"
                      alt="Connect brokerage account"
                    />
                    <p className="text-base font-redhat-regular text-black leading-snug">
                      Connect your brokerage account to start your first
                      automation
                    </p>
                  </div>
                </div>
                <div className="w-[15%] flex items-center justify-end">
                  <FaPlus className="w-3 h-3 text-black" />
                </div>
              </div>
            </div>
          ) : (
            <div
              onClick={() => {
                setShowOtherAccounts(!showOtherAccounts);
              }}
              className="w-full bg-[#F6F6F6] rounded-md relative"
            >
              <AccountOptions
                img={
                  brokerAccountsList?.accounts?.find(
                    (el) => el?.accountId === selectedAccountId
                  )?.brokerage === "ALPACA" ||
                  brokerAccountsList?.accounts?.find(
                    (el) => el?.accountId === selectedAccountId
                  )?.brokerage === "ALPACA_PAPER"
                    ? Alpaca
                    : brokerAccountsList?.accounts?.find(
                        (el) => el?.accountId === selectedAccountId
                      )?.brokerage === "TRADIER"
                    ? Tradier
                    : brokerAccountsList?.accounts?.find(
                        (el) => el?.accountId === selectedAccountId
                      )?.brokerage === "ROBINHOOD"
                    ? Robinhood
                    : brokerAccountsList?.accounts?.find(
                        (el) => el?.accountId === selectedAccountId
                      )?.brokerage === "ETRADE"
                    ? ETrade
                    : brokerAccountsList?.accounts?.find(
                        (el) => el?.accountId === selectedAccountId
                      )?.brokerage === "SCHWAB"
                    ? CharlesSchwab
                    : brokerAccountsList?.accounts?.find(
                        (el) => el?.accountId === selectedAccountId
                      )?.brokerage === "WEBULL"
                    ? Webull
                    : brokerAccountsList?.accounts?.find(
                        (el) => el?.accountId === selectedAccountId
                      )?.brokerage === "TRADESTATION_SIM" ||
                      brokerAccountsList?.accounts?.find(
                        (el) => el?.accountId === selectedAccountId
                      )?.brokerage === "TRADESTATION"
                    ? TradeStation
                    : null
                }
                name={
                  brokerAccountsList?.accounts?.find(
                    (el) => el?.accountId === selectedAccountId
                  )?.brokerage
                }
                brokerName={
                  brokerAccountsList?.accounts?.find(
                    (el) => el?.accountId === selectedAccountId
                  )?.brokerage +
                  " # --" +
                  selectedAccountId?.slice(-4)
                }
                balance={
                  allConnectedAccountsList?.accounts?.find(
                    (el) => el?.accountId === selectedAccountId
                  )?.cash
                    ? allConnectedAccountsList?.accounts?.find(
                        (el) => el?.accountId === selectedAccountId
                      )?.cash
                    : 0.0
                }
                investmentBalance={
                  allConnectedAccountsList?.accounts?.find(
                    (el) => el?.accountId === selectedAccountId
                  )?.cash
                    ? allConnectedAccountsList?.accounts?.find(
                        (el) => el?.accountId === selectedAccountId
                      )?.cash
                    : 0.0
                }
                firstList={showOtherAccounts}
              />

              {/* BROKERAGE OPTION */}
              {showOtherAccounts && (
                <div className="absolute w-full top-[8vh] left-0 bg-white border border-[#E5E5E5] shadow-md rounded-b-md max-h-[30vh] overflow-auto overflow-x-hidden overflow-y-auto p-2 z-0">
                  {allConnectedAccountsList?.accounts
                    ?.filter((el) => el?.accountId !== selectedAccountId)
                    ?.map((element, index) => {
                      return (
                        <div
                          onClick={() => {
                            setShowOtherAccounts(!showOtherAccounts);
                            setSelectedAccountId(element?.accountId);
                            setErrorMessage("");
                          }}
                        >
                          <AccountOptions
                            type="dropdown"
                            img={
                              brokerAccountsList?.accounts?.find(
                                (el) => el?.accountId === element?.accountId
                              )?.brokerage === "ALPACA" ||
                              brokerAccountsList?.accounts?.find(
                                (el) => el?.accountId === element?.accountId
                              )?.brokerage === "ALPACA_PAPER"
                                ? Alpaca
                                : brokerAccountsList?.accounts?.find(
                                    (el) => el?.accountId === element?.accountId
                                  )?.brokerage === "TRADIER"
                                ? Tradier
                                : brokerAccountsList?.accounts?.find(
                                    (el) => el?.accountId === element?.accountId
                                  )?.brokerage === "SCHWAB"
                                ? CharlesSchwab
                                : brokerAccountsList?.accounts?.find(
                                    (el) => el?.accountId === element?.accountId
                                  )?.brokerage === "ETRADE"
                                ? ETrade
                                : brokerAccountsList?.accounts?.find(
                                    (el) => el?.accountId === element?.accountId
                                  )?.brokerage === "ROBINHOOD"
                                ? Robinhood
                                : brokerAccountsList?.accounts?.find(
                                    (el) => el?.accountId === element?.accountId
                                  )?.brokerage === "WEBULL"
                                ? Webull
                                : brokerAccountsList?.accounts?.find(
                                    (el) => el?.accountId === element?.accountId
                                  )?.brokerage === "TRADESTATION_SIM" ||
                                  brokerAccountsList?.accounts?.find(
                                    (el) => el?.accountId === element?.accountId
                                  )?.brokerage === "TRADESTATION"
                                ? TradeStation
                                : null
                            }
                            name={
                              brokerAccountsList?.accounts?.find(
                                (el) => el?.accountId === element?.accountId
                              )?.brokerage
                            }
                            brokerName={
                              brokerAccountsList?.accounts?.find(
                                (el) => el?.accountId === element?.accountId
                              )?.brokerage +
                              " # --" +
                              element?.accountId?.slice(-4)
                            }
                            balance={
                              allConnectedAccountsList?.accounts?.find(
                                (el) => el?.accountId === element?.accountId
                              )?.cash
                                ? allConnectedAccountsList?.accounts?.find(
                                    (el) => el?.accountId === element?.accountId
                                  )?.cash
                                : 0.0
                            }
                            investmentBalance={
                              allConnectedAccountsList?.accounts?.find(
                                (el) => el?.accountId === element?.accountId
                              )?.cash
                                ? allConnectedAccountsList?.accounts?.find(
                                    (el) => el?.accountId === element?.accountId
                                  )?.cash
                                : 0.0
                            }
                            secondList={showOtherAccounts}
                          />
                        </div>
                      );
                    })}
                </div>
              )}
            </div>
          )}

          {/* <div
            className={
              showOtherAccounts
                ? "block w-full  rounded-md p-2 pr-3 relative first:rounded-b-md"
                : "hidden"
            }
          >
            {allConnectedAccountsList?.accounts
              ?.filter((el) => el?.accountId !== selectedAccountId)
              ?.map((element, index) => {
                return (
                  <div
                    onClick={() => {
                      setShowOtherAccounts(!showOtherAccounts);
                      setSelectedAccountId(element?.accountId);
                      setErrorMessage("");
                    }}
                  >
                    <AccountOptions
                      img={
                        brokerAccountsList?.accounts?.find(
                          (el) => el?.accountId === element?.accountId
                        )?.brokerage === "ALPACA" ||
                        brokerAccountsList?.accounts?.find(
                          (el) => el?.accountId === element?.accountId
                        )?.brokerage === "ALPACA_PAPER"
                          ? Alpaca
                          : brokerAccountsList?.accounts?.find(
                              (el) => el?.accountId === element?.accountId
                            )?.brokerage === "TRADIER"
                          ? Tradier
                          : brokerAccountsList?.accounts?.find(
                              (el) => el?.accountId === element?.accountId
                            )?.brokerage === "ROBINHOOD"
                          ? Robinhood
                          : brokerAccountsList?.accounts?.find(
                              (el) => el?.accountId === element?.accountId
                            )?.brokerage === "WEBULL"
                          ? Webull
                          : brokerAccountsList?.accounts?.find(
                              (el) => el?.accountId === element?.accountId
                            )?.brokerage === "TRADESTATION_SIM" ||
                            brokerAccountsList?.accounts?.find(
                              (el) => el?.accountId === element?.accountId
                            )?.brokerage === "TRADESTATION"
                          ? TradeStation
                          : null
                      }
                      name={
                        brokerAccountsList?.accounts?.find(
                          (el) => el?.accountId === element?.accountId
                        )?.brokerage
                      }
                      brokerName={
                        brokerAccountsList?.accounts?.find(
                          (el) => el?.accountId === element?.accountId
                        )?.brokerage +
                        " # --" +
                        element?.accountId?.slice(-4)
                      }
                      balance={
                        allConnectedAccountsList?.accounts?.find(
                          (el) => el?.accountId === element?.accountId
                        )?.cash
                          ? allConnectedAccountsList?.accounts?.find(
                              (el) => el?.accountId === element?.accountId
                            )?.cash
                          : 0.0
                      }
                      investmentBalance={
                        allConnectedAccountsList?.accounts?.find(
                          (el) => el?.accountId === element?.accountId
                        )?.cash
                          ? allConnectedAccountsList?.accounts?.find(
                              (el) => el?.accountId === element?.accountId
                            )?.cash
                          : 0.0
                      }
                      secondList={showOtherAccounts}
                    />
                  </div>
                );
              })}
          </div> */}
        </div>

        {/* AUTOMATION REVIEW */}
        <div className="mt-5">
          <p className="text-base font-redhat-semibold text-black">
            Automation review
          </p>
          <div className="mt-2 bg-[#F6F6F6] rounded-md p-3">
            <p className="text-sm font-redhat-semibold text-black">
              Every month, you’ll invest {handleReview()} via the selected{" "}
              {
                brokerAccountsList?.accounts?.find(
                  (el) => el?.accountId === selectedAccountId
                )?.brokerage
              }{" "}
              account.{" "}
            </p>
          </div>
        </div>

        {/* ERROR MESSAGE */}
        {errorMessage && (
          <p className="text-base font-redhat-semibold mt-6 text-red-500">
            {errorMessage}
          </p>
        )}

        {/* SUBMIT BUTTON */}
        <Button
          className="mt-5 w-full shadow-none hover:shadow-none bg-logoGreen hover:bg-opacity-80 rounded-md normal-case text-base font-redhat-bold transition-all duration-300 ease-in-out"
          disabled={loading}
          onClick={() => {
            handleSubmitForm();
          }}
        >
          {loading ? "Please wait..." : "Start Now"}
        </Button>
      </>

      {/* DON'T SEE ETF */}
      <Dialog
        open={noETFDialog}
        handler={() => setNoETFDialog(!noETFDialog)}
        size="xs"
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
        className="bg-[#1C1C1C] overflow-visible"
      >
        <DialogBody className="px-5 relative">
          <div className="py-5 border-b border-inputBox border-opacity-30">
            <img
              src={require("../../assets/images/scaletrade-logo.png")}
              className="h-10 w-auto mx-auto"
              alt="ScaleTrade"
            />
          </div>
          <div className="py-5">
            {noEFTStep === 1 && (
              <>
                <h5 className="text-lg font-redhat-bold text-white">
                  We add new ETF's all the time
                </h5>
                <p className="text-base font-redhat-semibold text-white mt-1">
                  Please tell us which ETF you're looking for, and we will
                  notify you when it is added to ScaleTrade.
                </p>
                <form className="mt-3">
                  <input
                    type="text"
                    name="etfName"
                    id="etfName"
                    placeholder="Enter the ETF.."
                    className="block w-full bg-white border border-inputBox outline-none focus:outline-none text-base font-redhat-semibold text-black px-5 py-3 rounded-md"
                  />
                  <Button
                    className="mt-5 w-full shadow-none hover:shadow-none text-base font-redhat-semibold text-white bg-gradient normal-case"
                    onClick={() => setNoETFStep(2)}
                  >
                    Submit and notify me when it's added
                  </Button>
                </form>
              </>
            )}

            {noEFTStep === 2 && (
              <>
                <h5 className="text-lg font-redhat-bold text-white">
                  Your request has been submitted!
                </h5>
                <p className="text-base font-redhat-semibold text-white mt-1">
                  We will notify you via email when this ETF is added to
                  ScaleTrade.
                </p>
                <Button
                  className="mt-5 w-full shadow-none hover:shadow-none text-base font-redhat-semibold text-white bg-gradient normal-case"
                  onClick={() => setNoETFDialog(!noETFDialog)}
                >
                  Done
                </Button>
              </>
            )}
          </div>

          <div className="absolute top-2 right-2">
            <Button
              className="shadow-none hover:shadow-none bg-transparent rounded-full p-2 hover:bg-white hover:bg-opacity-10 transition-all duration-300 ease-in-out"
              onClick={() => setNoETFDialog(!noETFDialog)}
            >
              <IoClose className="w-4 h-4 text-white text-opacity-50s" />
            </Button>
          </div>
        </DialogBody>
      </Dialog>

      {/* NEW BROEKRAGES */}
      <Dialog
        open={newBrokerages}
        handler={() => setNewBrokerages(!newBrokerages)}
        size="xs"
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
        className="bg-[#1C1C1C] overflow-visible"
      >
        <DialogBody className="px-5 relative">
          <div className="py-5 border-b border-inputBox border-opacity-30">
            <img
              src={require("../../assets/images/scaletrade-logo.png")}
              className="h-10 w-auto mx-auto"
              alt="ScaleTrade"
            />
          </div>
          <div className="py-5">
            {noEFTStep === 1 && (
              <>
                <h5 className="text-lg font-redhat-bold text-white">
                  We add new brokers all the time
                </h5>
                <p className="text-base font-redhat-semibold text-white mt-1">
                  Please tell us which broker you're looking for, and we will
                  notify you when it is added to ScaleTrade.
                </p>
                <form className="mt-3">
                  <input
                    type="text"
                    name="etfName"
                    id="etfName"
                    placeholder="Enter the broker name"
                    className="block w-full bg-white border border-inputBox outline-none focus:outline-none text-base font-redhat-semibold text-black px-5 py-3 rounded-md"
                  />
                  <Button
                    className="mt-5 w-full shadow-none hover:shadow-none text-base font-redhat-semibold text-white bg-gradient normal-case"
                    onClick={() => setNoETFStep(2)}
                  >
                    Submit and notify me when it's added
                  </Button>
                </form>
              </>
            )}

            {noEFTStep === 2 && (
              <>
                <h5 className="text-lg font-redhat-bold text-white">
                  Your request has been submitted!
                </h5>
                <p className="text-base font-redhat-semibold text-white mt-1">
                  We will notify you via email when this broker is added to
                  ScaleTrade.
                </p>
                <Button
                  className="mt-5 w-full shadow-none hover:shadow-none text-base font-redhat-semibold text-white bg-gradient normal-case"
                  onClick={() => setNoETFDialog(!noETFDialog)}
                >
                  Done
                </Button>
              </>
            )}
          </div>

          <div className="absolute top-2 right-2">
            <Button
              className="shadow-none hover:shadow-none bg-transparent rounded-full p-2 hover:bg-white hover:bg-opacity-10 transition-all duration-300 ease-in-out"
              onClick={() => setNoETFDialog(!noETFDialog)}
            >
              <IoClose className="w-4 h-4 text-white text-opacity-50s" />
            </Button>
          </div>
        </DialogBody>
      </Dialog>
    </>
  );
};

export default NewCreateAutomationComponent;
