/* DATA */
import brokers from "../../data/brokers";

const BrokerComponent = () => {
  return (
    <div className="py-5 px-8">
      <h5 className="text-xl font-redhat-extrabold text-black">
        Success! Now link your broker
      </h5>
      <p className="mt-2 text-base font-redhat-regular text-[#494949]">
        Let's get you on your way to stress-free trading with fully automated
        trading strategies that you control.
      </p>
      <div className="mt-5">
        {brokers.map((data) => (
          <div
            className="mt-2 first:mt-0 bg-body bg-opacity-50 rounded-md border border-inputBox border-opacity-50 p-3 cursor-pointer hover:border-logoGreen transition-all duration-300 ease-in-out hover:shadow-md"
            key={data.id}
          >
            <div className="flex items-center gap-3">
              <img
                src={data.brokerImg}
                className="w-10 h-10 rounded-md flex-shrink-0"
                alt={data.brokerName}
              />
              <p className="text-base font-redhat-semibold text-black">
                {data.brokerName}
              </p>
            </div>
          </div>
        ))}
      </div>
      <div className="mt-5">
        <p className="text-base font-redhat-semibold text-greyText text-center">
          By linking your brokerage and starting automations, you agree to the
          terms of our{" "}
          <a
            href="https://scaletrade.ai/disclosure-agreement"
            rel="noreferrer"
            target="_blank"
            className="underline"
          >
            Disclosure Agreement
          </a>
        </p>
      </div>
    </div>
  );
};

export default BrokerComponent;
