import { useState } from "react";

import { Link } from "react-router-dom";

/* ASSETS */
import Logo from "assets/images/scaletrade-logo.png";

/* MATERIAL TAILWIND COMPONENTS */
import { Button } from "@material-tailwind/react";
import AccountCreation from "components/others/accountCreation";

const WebsiteFooter = () => {
  const [currentTab, setCurrentTab] = useState("login");
  const [currentState, setCurrentState] = useState(false);

  return (
    <footer className="bg-header py-5">
      <div className="container mx-auto my-auto">
        {/* NAVIGATION OPTIONS */}
        <div className="py-0 pb-5 xl:py-10 lg:py-10 block xl:flex lg:flex items-center justify-between">
          <Link to="/">
            <img
              src={Logo}
              className="h-10 w-auto object-contain mx-auto"
              alt="ScaleTrade"
            />
          </Link>

          {/* NAVIGATION LINKS */}
          <div className="mt-5 xl:mt-0 lg:mt-0 flex items-center justify-center xl:justify-normal lg:justify-normal gap-5 xl:gap-10 lg:gap-10 flex-wrap">
            <Link
              to="/pricing"
              className="block text-base font-redhat-semibold text-white hover:text-logoGreen transition-all duration-300 ease-in-out"
            >
              Pricing
            </Link>
            {/* <a
              href="https://resources.scaletrade.ai/about-scaletrade/meet-the-team"
              target="_blank"
              rel="noreferrer"
              className="block text-base font-redhat-semibold text-white hover:text-logoGreen transition-all duration-300 ease-in-out"
            >
              Docs
            </a> */}
            <Link
              to="/about"
              className="block text-base font-redhat-semibold text-white hover:text-logoGreen transition-all duration-300 ease-in-out"
            >
              About
            </Link>
            <Button
              onClick={() => {
                setCurrentTab("login");
                setCurrentState(true);
              }}
              className="shadow-none hover:shadow-none bg-transparent p-0 normal-case font-redhat-semibold text-base transition-all duration-300 hover:text-logoGreen"
            >
              Login
            </Button>
            <Button
              onClick={() => {
                setCurrentTab("register");
                setCurrentState(true);
              }}
              className="shadow-none hover:shadow-none bg-transparent hover:bg-white hover:bg-opacity-10 rounded-md px-4 py-2 border border-white border-opacity-50 normal-case font-redhat-semibold text-base transition-all duration-300"
            >
              Create account
            </Button>
          </div>
        </div>

        {/* COPYRIGHT FOOTER */}
        <div className="py-5 xl:py-8 lg:py-8 border-t border-white border-opacity-50">
          <div className="block xl:flex lg:flex items-center justify-center xl:justify-between lg:justify-between">
            {/* LEFT SECTION */}
            <div className="flex justify-center xl:block lg:block">
              <Button   onClick={() => {
                setCurrentTab("register");
                setCurrentState(true);
              }} className="shadow-none hover:shadow-none green-gradient normal-case text-base font-redhat-semibold text-white rounded-md">
                Setup your investment
              </Button>
            </div>

            {/* CENTER SECTION */}
            <div className="mt-5 xl:mt-0 lg:mt-0 text-center">
              <p className="text-base font-redhat-semibold text-white">
                © 2024 ScaleTrade. All Rights Reserved.
              </p>
            </div>

            {/* RIGHT SECTION */}
            <div className="flex items-center gap-10 mt-5 xl:mt-0 lg:mt-0 justify-center xl:justify-normal lg:justify-normal">
              <Link
                to="/cookies-policy"
                className="block text-base font-redhat-semibold text-white hover:text-logoGreen transition-all duration-300 ease-in-out"
              >
                Cookies Policy
              </Link>
              <Link
                to="/terms-of-service"
                className="block text-base font-redhat-semibold text-white hover:text-logoGreen transition-all duration-300 ease-in-out"
              >
                Terms of Service
              </Link>
            </div>
          </div>
        </div>
      </div>
      <AccountCreation
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        currentState={currentState}
        setCurrentState={setCurrentState}
      />
    </footer>
  );
};

export default WebsiteFooter;
