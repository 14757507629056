const investmentOptions = [
  {
    id: 1,
    symbol: "SPY",
    name: "S&P 500 ETF (VOO)",
    description:
      "America's 500 largest companies.",
    returnPercentage: "10.26",
    endingText: "%/ year since 1957",
    backgroundColor: "#D7E7FF",
    borderColor: "#5B99F7",
    riskScore: 3.6,
  },
  {
    id: 2,
    symbol: "QQQ",
    name: "Nasdaq ETF (QQQ)",
    description:
      "Biggest tech companies in the world.",
    returnPercentage: "13.65",
    endingText: "%/ year since 2011",
    backgroundColor: "#FFD6D6",
    borderColor: "#F75B5B",
    riskScore: 3.75,
  },
  {
    id: 3,
    symbol: "SCHD",
    name: "Dividend Stocks ETF (SCHD)",
    description: "Companies that pay you to own them.",
    returnPercentage: "13.34",
    endingText: "%/ year since 2011",
    backgroundColor: "#E3FFFF",
    borderColor: "#0AA3A3",
    riskScore: 3.7,
  },
  {
    id: 4,
    symbol: "GLD",
    name: "Gold ETF (GLD)",
    description: "Invest in the value of gold.",
    returnPercentage: "8.31",
    endingText: "%/ year since 2004",
    backgroundColor: "#FFFCB9",
    borderColor: "#A0AA27",
    riskScore: 2.6,
  },
  {
    id: 5,
    symbol: "VUG",
    name: "Growth Stocks ETF (VUG)",
    description: "Large companies growing quickly.",
    returnPercentage: "10.90",
    endingText: "%/ year since 2004",
    backgroundColor: "#F3E9FF",
    borderColor: "#7C00C8",
    riskScore: 3.7,
  },
  {
    id: 6,
    symbol: "IWM",
    name: "Russell 200 (IWM)",
    description: "Companies worth $300m to $2bn.",
    returnPercentage: "7.47",
    endingText: "%/ year since 2000",
    backgroundColor: "#FFE9E9",
    borderColor: "#FD00F2",
    riskScore: 4.45,
  },
  {
    id: 7,
    symbol: "VXUS",
    name: "Total International ETF (VXUS)",
    description: "Largest companies outside the U.S.",
    returnPercentage: "4.66",
    endingText: "%/ year since 1997",
    backgroundColor: "#FDE1C8",
    borderColor: "#F30000",
    riskScore: 3.75,
  },
  {
    id: 8,
    symbol: "DIA",
    name: "Industrials ETF (DIA)",
    description: "Tracks the U.S. economy.",
    returnPercentage: "8.48",
    endingText: "%/ year since 1998",
    backgroundColor: "#EDFFEB",
    borderColor: "#159800",
    riskScore: 3.6,
  },
];

export default investmentOptions;
