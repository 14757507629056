import { useSelector } from "react-redux";
import { getAuthorizationLinkApi } from "service/api";

const BrokerTile = (props) => {
  const userId = useSelector((state) => state?.user?.userId);

  const handleGetAuthLink = (slug) => {
    getAuthorizationLinkApi(userId, slug)
      .then((res) => {
        if (res?.data?.url) {
          window.location.href = res?.data?.url;
        }
      })
      .catch((err) => {
        console.error("handleGetAuthLink", err);
      });
  };
  return (
    <div
      onClick={() => handleGetAuthLink(props?.slug)}
      className="cursor-pointer w-full h-[15vh] bg-white border border-inputBox rounded-lg shadow hover:shadow-md flex items-center justify-center hover:border-logoGreen transition-all duration-300 ease-in-out"
    >
      <div className="text-center">
        <img
          src={props.brokerImg}
          className="w-12 h-12 rounded-md mx-auto"
          alt={props.brokerName}
        />
        <p className="mt-3 text-base font-redhat-semibold text-black text-center">
          {props.brokerName}
        </p>
      </div>
    </div>
  );
};

export default BrokerTile;
