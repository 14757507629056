import { useState } from "react";

/* PACKAGES */
import { motion } from "framer-motion";

/* ANIMATION */
import { animation, containerAnimation } from "config/animation";

/* ICONS */
import { IoMdAdd } from "react-icons/io";
import { FiMinus } from "react-icons/fi";

/* IMAGES */
import ConorFlynn from "assets/images/website/conor-flynn.png";
import AndreWilliams from "assets/images/website/andre-williams.png";
import MatthewHuo from "assets/images/website/matthew-huo.png";

const AboutPage = () => {
  const [currentOpen, setCurrentOpen] = useState("Conor Flynn");

  /* FOUNDER BOX */
  const FounderBox = (props) => {
    return (
      <div className="p-0">
        <img
          src={props.image}
          className="w-full h-[35vh] object-cover rounded-xl"
          alt={props.name}
        />
        <div
          className="cursor-pointer group py-4 border-b border-black hover:border-logoGreen transition-all duration-300 ease-in-out"
          onClick={props.onClick}
        >
          <div className="flex items-center justify-between">
            <div className="p-0">
              <h5 className="text-lg font-redhat-bold text-black">
                {props.name}
              </h5>
              <p className="text-md font-redhat-regular text-black">
                {props.designation}
              </p>
            </div>
            {props.open ? (
              <FiMinus className="w-5 h-5 text-black group-hover:text-logoGreen" />
            ) : (
              <IoMdAdd className="w-5 h-5 text-black group-hover:text-logoGreen" />
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <motion.section
      variants={containerAnimation}
      initial="hidden"
      animate="visible"
      className="bg-transparent"
    >
      {/* BANNER */}
      <motion.div
        variants={animation}
        className="py-10 xl:py-20 lg:py-20 pb-[28vh] xl:pb-[35vh] lg:pb-[35vh] bg-aboutBanner"
      >
        <div className="container mx-auto">
          <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-y-10 xl:gap-20 lg:gap-20 items-center">
            <div className="col-span-1">
              <div className="grid grid-cols-2 gap-5 items-center">
                <div className="col-span-1">
                  <img
                    src={ConorFlynn}
                    className="w-full h-[25vh] object-cover rounded-lg"
                    alt="Conor Flynn"
                  />
                </div>
                <div className="col-span-1">
                  <img
                    src={AndreWilliams}
                    className="w-full h-[25vh] object-cover rounded-lg"
                    alt="Andre Williams"
                  />
                  <div className="mt-5">
                    <img
                      src={MatthewHuo}
                      className="w-full h-[25vh] object-cover rounded-lg"
                      alt="Matthew Huo"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-1 relative">
              <div className="relative z-20">
                <h1 className="text-6xl font-redhat-semibold text-white leading-snug z-20">
                  here to build you <br />
                  <span className="text-gradient">
                    stress-free
                    <br />
                    consistent
                    <br />
                    easy-to-use
                  </span>
                  <br />
                  investing systems
                </h1>
              </div>

              {/* UNDERLINE */}
              <div className="absolute -bottom-3 left-0 z-10">
                <img
                  src={require("../../assets/images/website/underline.png")}
                  className="w-full h-auto"
                  alt="here to build you stress free consistent easy-to-use investing systems"
                />
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* OUR TEAM */}
      <motion.div
        variants={animation}
        className="pb-5 xl:pb-12 lg:pb-12 relative"
      >
        {/* BANNER */}
        <div className="absolute -top-[25vh] left-0">
          <img
            src={require("../../assets/images/website/about-bg.png")}
            className="w-full h-fit  overflow-y-clip"
            alt="Join scaletrade"
          />
        </div>

        <div className="container mx-auto z-20 relative">
          <h2 className="text-2xl font-redhat-regular text-black text-center">
            Our team has worked at
          </h2>
          <div className="mt-5">
            <div className="flex items-center justify-center gap-14 flex-wrap">
              <img
                src={require("../../assets/images/website/pwc-logo.png")}
                className="w-auto h-16 object-cover"
                alt="PWC"
              />
              <img
                src={require("../../assets/images/website/boeing-logo.png")}
                className="w-auto h-10 object-cover"
                alt="Boeing"
              />
              <img
                src={require("../../assets/images/website/at-t-logo.png")}
                className="w-auto h-16 object-cover"
                alt="AT&T"
              />
              <img
                src={require("../../assets/images/website/hercules-capital-logo.png")}
                className="w-auto h-10 object-cover"
                alt="Hercules Capital"
              />
              <img
                src={require("../../assets/images/website/team-client.png")}
                className="w-auto h-16 object-cover"
                alt="Team worked at"
              />
            </div>
          </div>
        </div>
      </motion.div>

      {/* ABOUT FOUNDERS */}
      <motion.div
        variants={animation}
        className="py-6 xl:py-12 lg:py-12 z-20 relative"
      >
        <div className="container mx-auto">
          <h2 className="text-2xl xl:text-3xl lg:text-3xl font-redhat-extrabold text-black">
            We are data-driven engineers and finance professionals
          </h2>
          <div className="mt-5">
            <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 gap-5">
              <div className="col-span-1">
                <FounderBox
                  image={ConorFlynn}
                  name="Conor Flynn"
                  designation="CTO & Co-Founder"
                  open={currentOpen === "Conor Flynn"}
                  onClick={() => setCurrentOpen("Conor Flynn")}
                />
              </div>
              <div className="col-span-1">
                <FounderBox
                  image={AndreWilliams}
                  name="Andre Williams"
                  designation="CEO & Co-Founder"
                  open={currentOpen === "Andre Williams"}
                  onClick={() => setCurrentOpen("Andre Williams")}
                />
              </div>
              <div className="col-span-1">
                <FounderBox
                  image={MatthewHuo}
                  name="Matthew Huo"
                  designation="CMO & Co-Founder"
                  open={currentOpen === "Matthew Huo"}
                  onClick={() => setCurrentOpen("Matthew Huo")}
                />
              </div>
            </div>
            <div className="mt-5">
              <div className="transition-all duration-300 ease-in-out bg-white border border-black p-5">
                {currentOpen === "Conor Flynn" && (
                  <p className="text-md font-redhat-regular text-black leading-relaxed">
                    <span className="font-redhat-bold">Conor Flynn</span> is a
                    quantitative developer who has been writing code since the
                    age of nine. He once built a high-frequency trading system
                    from scratch, by himself, as a contract job for the
                    quantitative development firm that he founded, Quantify.
                    Through that firm, he’s also built algorithms for
                    institutional clients and ex-hedge fund managers. He’s now
                    pursuing a degree in computational neuroscience, and using
                    ScaleTrade to help pay for his tuition.
                  </p>
                )}

                {currentOpen === "Andre Williams" && (
                  <p className="text-md font-redhat-regular text-black leading-relaxed">
                    <span className="font-redhat-bold">Andre Williams</span> is
                    a data scientist and software engineer who finished an
                    accelerated 2-year degree in CS. Since then, he’s built
                    everything from neural networks for autonomous
                    flight to data ingestion pipelines for fintech
                    startups that were eventually acquired. Now, works at a
                    SAP and stashes away a good chunk of his
                    salary to be automated by ScaleTrade.
                  </p>
                )}

                {currentOpen === "Matthew Huo" && (
                  <p className="text-md font-redhat-regular text-black leading-relaxed">
                    <span className="font-redhat-bold">Matthew Huo</span> is a
                    2x founder who has consulted high-growth finance companies
                    like Kalshi and worked as a management consultant at PwC. He
                    recently made his first real estate investment in an
                    apartment in Boston and is already using ScaleTrade to
                    compound his money for his next real estate purchase.
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* OUR MISSION */}
      <motion.div variants={animation} className="py-5 xl:py-12 lg:py-12">
        <div className="container mx-auto">
          <h2 className="text-3xl font-redhat-extrabold text-black">
            Our Vision
          </h2>
          <div className="mt-5">
            <p className="text-lg font-redhat-regular text-black leading-relaxed">
              We want to build a world where investing is {" "}
              <span className="font-redhat-bold italic">
                simple and accessible
              </span>{" "}
              for all people.
            </p>
          </div>
          <div className="mt-5">
            <p className="text-lg font-redhat-regular text-black leading-relaxed">
            We want to help everyone take advantage of the wealth-building power of the stock market.
            Our team believes it’s the #1 way to help people improve their financial health and retirement outcomes.
            </p>
          </div>
          <div className="mt-5">
            <p className="text-lg font-redhat-regular text-black leading-relaxed">
              We want to make it as simple as possible to learn how to invest,
              and make sure people take a safe, responsible approach to their
              finances.
            </p>
          </div>
        </div>
      </motion.div>
    </motion.section>
  );
};

export default AboutPage;
