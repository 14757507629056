/* IMPORT ACTIONS FOR USER REDUCER */
import actions from "../actions/user.action";

/* INITIALIZE STATE VARIABLES */
const initialState = {
  user: null,
  token: null,
  loggedIn: false,
  loading: false,
  allAccounts: [],
  allConnectedAccounts: [],
  selectedAccount: null,
  userId: null,
  accountId: null,
  termsAndConditions: false,
  filledOrder: [],
  openOrder: [],
  switch: false,
  callApi: true,
};

/* INITIALIZE ACTIONS */
const {
  SET_USER,
  SET_TOKEN,
  SET_LOGGED_IN,
  SET_LOADING,
  SET_TERMS_AND_CONDITIONS,
  SET_ALL_ACCOUNTS,
  SET_ALL_CONNECTED_ACCOUNTS,
  SET_SELECTED_ACCOUNT,
  SET_USER_ID,
  SET_ACCOUNT_ID,
  SET_FILLED_ORDER,
  SET_OPEN_ORDER,
  SET_CALL_API_STATUS,
  SWITCH,
  LOGOUT,
} = actions;

/* CREATE USER REDUCER FUNCTION */
const userReducer = (state = initialState, action) => {
  const { type, data } = action;

  /* CREATE SWITCH CASE FOR ACTIONS */
  switch (type) {
    /* SET USER DATA */
    case LOGOUT:
      return {};
    /* SET USER DATA */
    case SET_USER:
      return {
        ...state,
        user: data,
      };
    /* SET USER TOKEN */
    case SET_TOKEN:
      return {
        ...state,
        token: data,
      };
    /* SET USER LOGGED-IN STATUS */
    case SET_LOGGED_IN:
      return {
        ...state,
        loggedIn: data,
      };
    /* SET TERMS AND CONDITIONS STATUS */
    case SET_TERMS_AND_CONDITIONS:
      return {
        ...state,
        termsAndConditions: data,
      };

    /* SET LOADING STATUS*/
    case SET_LOADING:
      return {
        ...state,
        loading: data,
      };
    /* SET_ALL_CONNECTED_ACCOUNTS */
    case SET_ALL_CONNECTED_ACCOUNTS:
      return {
        ...state,
        allConnectedAccounts: data,
      };
    /* SET_ALL_ACCOUNTS */
    case SET_ALL_ACCOUNTS:
      return {
        ...state,
        allAccounts: data,
      };
    /* SET_SELECTED_ACCOUNT */
    case SET_SELECTED_ACCOUNT:
      return {
        ...state,
        selectedAccount: data,
      };
    /* SET_USER_ID */
    case SET_USER_ID:
      return {
        ...state,
        userId: data,
      };
    /* SET_ACCOUNT_ID */
    case SET_ACCOUNT_ID:
      return {
        ...state,
        accountId: data,
      };
    /* SET_FILLED_ORDER */
    case SET_FILLED_ORDER:
      return {
        ...state,
        filledOrder: data,
      };
    /* SET_OPEN_ORDER */
    case SET_OPEN_ORDER:
      return {
        ...state,
        openOrder: data,
      };
    /* SET_CALL_API_STATUS */
    case SET_CALL_API_STATUS:
      return {
        ...state,
        callApi: data,
      };
    case SWITCH:
      return {
        ...state,
        switch: data,
      };

    /* DEFAULT RETURN GLOBAL STATE */
    default:
      return state;
  }
};

export default userReducer;
