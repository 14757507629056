import React from "react";
import ReactDOM from "react-dom/client";

/* MATERIAL TAILWIND THEMEPROVIDER */
import { ThemeProvider } from "@material-tailwind/react";

import { Provider } from "react-redux";

import { ToastContainer } from "react-toastify";

/* MAIN APPLICATION */
import App from "routes/routes";

import store from "./redux/store";

import "react-toastify/dist/ReactToastify.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider>
        <App />
        <ToastContainer />
      </ThemeProvider>
    </Provider>
  // </React.StrictMode>
);
