/* LAYOUT COMPONENTS */
import WebsiteHeader from "./header";
import WebsiteFooter from "./footer";

const WebsiteLayout = ({ children }) => {
  return (
    <>
      <WebsiteHeader />
      <main>{children}</main>
      <WebsiteFooter />
    </>
  );
};

export default WebsiteLayout;
