import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Select from "react-select";
import ReactImageUploading from "react-images-uploading";
/* ASSETS */
import Logo from "assets/images/scaletrade-logo.png";

/* MATERIAL TAILWIND COMPONENTS */
import { Button, Drawer, Dialog, DialogBody } from "@material-tailwind/react";

/* ICONS */
import { HiBars2 } from "react-icons/hi2";
import { FaRegUserCircle } from "react-icons/fa";
import { IoClose, IoTrash } from "react-icons/io5";

import * as yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";

/* SIDEBAR DATA */
import sidebarNav from "data/sidebar";

/* UTILS */
import HelperGetData from "components/others/helpers";
import { useSelector } from "react-redux";

import { newSupportTicket } from "../../../service/api";

/* REASONS */
const reasonOptions = [
  { value: "Feature Request", label: "Feature Request" },
  { value: "General Feedback", label: "General Feedback" },
  { value: "Connecting a brokerage", label: "Connecting a brokerage" },
  { value: "Account balance", label: "Account balance" },
  { value: "Trade history", label: "Trade history" },
  { value: "Strategy setup", label: "Strategy setup" },
  { value: "Automation start/pausing", label: "Automation start/pausing" },
  { value: "Trades not being made", label: "Trades not being made" },
  {
    value: "Change the number of contracts",
    label: "Change the number of contracts",
  },
  { value: "Disconnecting a brokerage", label: "Disconnecting a brokergae" },
  { value: "Account settings", label: "Account settings" },
  { value: "Other", label: "Other" },
];

/* PRIORITY */
const priorityOptions = [
  { value: "Normal", label: "Normal" },
  { value: "High", label: "High" },
];

const validationSchema = yup.object().shape({
  reason: yup.string().required("Please Select Reason For Reaching Out"),
  subject: yup.string().required("Subject is Required"),
  desc: yup.string().required("Description is required"),
  priority: yup.string().required("Please Select priority"),
});

/* DIALOG INPUT */
const DialogInputBox = (props) => {
  return (
    <input
      type={props.type}
      name={props.name}
      id={props.id}
      value={props.value}
      onChange={props.onChange}
      placeholder={props.placeholder}
      className={
        props?.error
          ? "w-full block bg-white border border-red-600 rounded-md px-5 py-3 outline-none focus:border-logoGreen focus:shadow-md text-base font-redhat-semibold text-black transition-all duration-300 ease-in-out"
          : "w-full block bg-white border border-inputBox rounded-md px-5 py-3 outline-none focus:border-logoGreen focus:shadow-md text-base font-redhat-semibold text-black transition-all duration-300 ease-in-out"
      }
    />
  );
};

/* DIALOG INPUT */
const DialogTextarea = (props) => {
  return (
    <textarea
      rows={props.rows}
      name={props.name}
      id={props.id}
      value={props.value}
      onChange={props.onChange}
      placeholder={props.placeholder}
      className={
        props?.error
          ? "w-full block bg-white border border-red-600 rounded-md px-5 py-3 outline-none focus:border-logoGreen focus:shadow-md text-base font-redhat-semibold text-black transition-all duration-300 ease-in-out"
          : "w-full block bg-white border border-inputBox rounded-md px-5 py-3 outline-none focus:border-logoGreen focus:shadow-md text-base font-redhat-semibold text-black transition-all duration-300 ease-in-out"
      }
    />
  );
};

const Header = () => {
  const userDetails = useSelector((state) => state?.user?.user);
  const [sidebar, setSidebar] = useState(false);
  const location = useLocation();

  const [supportDialog, setSupportDialog] = useState(false);
  const [successModal, setSuccessModal] = useState(false);

  const [supportLoading, setSupportLoading] = useState(false);

  const [attachments, setAttachments] = useState([]);

  const maxLimit = 2;

  /* SIDEBAR OPTION */
  const SidebarOption = (props) => {
    return (
      <Link
        to={props.link}
        className="group block mt-7 first:mt-0"
        onClick={() => setSidebar(!sidebar)}
      >
        <div className="text-center transition-all duration-300 ease-in-out">
          <props.icon
            className={`w-5 h-5 ${
              props.link === location.pathname
                ? "text-logoGreen"
                : "text-sidebarGrey"
            } group-hover:text-logoGreen mx-auto transition-all duration-300 ease-in-out`}
          />
          <h5
            className={`text-sm hidden font-redhat-semibold ${
              props.link === location.pathname
                ? "text-logoGreen"
                : "text-sidebarGrey"
            } group-hover:text-logoGreen mt-1 transition-all duration-300 ease-in-out`}
          >
            {props.name}
          </h5>
        </div>
      </Link>
    );
  };

  /* HEADER OPTION */
  const HeaderOption = (props) => {
    return (
      <Link to={props.link} className="group block first:mt-0 relative">
        <div className="text-center transition-all duration-300 ease-in-out">
          <h5 className="text-base hidden lg:block xl:block font-redhat-semibold text-white mt-1 transition-all duration-300 ease-in-out">
            {props.name}
          </h5>
        </div>

        {props.link === location.pathname && (
          <div className="absolute -bottom-5 left-0 w-full">
            <div className="w-full h-1 bg-logoGreen" />
          </div>
        )}
      </Link>
    );
  };

  const onChange = (imageList) => {
    setAttachments(imageList);
  };

  const formik = useFormik({
    initialValues: {
      reason: "",
      subject: "",
      desc: "",
      priority: "Normal",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setSupportLoading(true);
      let data = new FormData();
      data.append("reason", values.reason);
      data.append("subject", values.subject);
      data.append("desc", values.desc);
      data.append("priority", values.priority);
      if (attachments?.length > 0) {
        data.append("attachment", attachments?.[0]?.file);
      }

      newSupportTicket(data)
        .then((res) => {
          if (res?.status) {
            toast.success(res?.message);
            setSupportDialog(!supportDialog);
            setSuccessModal(!successModal);
            setAttachments([]);
            formik.resetForm();
          } else {
            toast.error(res?.message);
          }
        })
        .catch((e) => {
          setSupportLoading(false);
          if (typeof e?.response?.data.message === "string") {
            toast.error(e?.response?.data.message);
          } else {
            toast.error(e?.response?.data.message?.details?.[0]?.message);
          }
        })
        .finally(() => {
          setSupportLoading(false);
        });
    },
  });

  return (
    <header className="bg-header py-4 px-3 xl:px-5 lg:px-5">
      <HelperGetData />
      <div className="flex justify-between">
        {/* LEFT OPTION */}
        <div className="flex items-center gap-3">
          <Button
            className="block xl:hidden lg:hidden shadow-none hover:shadow-none rounded-full bg-transparent border border-white border-opacity-30 p-2"
            onClick={() => setSidebar(!sidebar)}
          >
            <HiBars2 className="w-5 h-5 text-white text-opacity-80" />
          </Button>

          {/* LOGO */}
          <img
            src={Logo}
            className="h-8 w-auto object-contain"
            alt="ScaleTrade"
          />
        </div>

        {/* NAVIGATION OPTIONS */}
        <div className="flex items-center gap-10">
          {sidebarNav.map((data) => (
            <React.Fragment key={data.id}>
              <HeaderOption
                name={data.name}
                link={data.link}
                icon={data.icon}
              />
            </React.Fragment>
          ))}
          <div
            className="group block first:mt-0 relative cursor-pointer"
            onClick={() => setSupportDialog(!supportDialog)}
          >
            <div className="text-center transition-all duration-300 ease-in-out">
              <h5 className="text-base hidden lg:block xl:block font-redhat-semibold text-white mt-1 transition-all duration-300 ease-in-out">
                Ticket
              </h5>
            </div>

            {supportDialog && (
              <div className="absolute -bottom-5 left-0 w-full">
                <div className="w-full h-1 bg-logoGreen" />
              </div>
            )}
          </div>
        </div>

        <Link to="/account" className="block">
          <Button className="shadow-none hover:shadow-none bg-transparent hover:bg-white hover:bg-opacity-10 rounded-md transition-all duration-300 ease-in-out flex items-center gap-3 px-5 py-2">
            <p className="text-base font-redhat-base text-white normal-case">
              {userDetails?.email}
            </p>
            <FaRegUserCircle className="w-5 h-5 text-white" />
          </Button>
        </Link>
      </div>

      {/* DRAWER */}
      <Drawer
        open={sidebar}
        onClose={() => setSidebar(!sidebar)}
        className="h-screen !max-w-max p-5"
      >
        {sidebarNav.map((data) => (
          <React.Fragment key={data.id}>
            <SidebarOption name={data.name} link={data.link} icon={data.icon} />
          </React.Fragment>
        ))}
      </Drawer>

      <Dialog
        open={supportDialog}
        handler={() => setSupportDialog(!supportDialog)}
        size="sm"
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
        className="overflow-visible"
      >
        <DialogBody className="p-5">
          <div className="flex items-center justify-between">
            <div>
              <h5 className="text-2xl font-redhat-bold text-black">
                Share feedback or request support
              </h5>
              <p className="text-base font-redhat-semibold text-black">
                Feedback is the #1 we can improve ScaleTrade, so please let us
                know how we can make your experience better.
              </p>
            </div>
            <Button
              className="shadow-none hover:shadow-none bg-transparent hover:bg-black hover:bg-opacity-10 rounded-full p-1 transition-all duration-300 ease-in-out"
              onClick={() => setSupportDialog(!supportDialog)}
            >
              <IoClose className="w-5 h-5 text-greyText" />
            </Button>
          </div>
          <form className="mt-5" onSubmit={formik.handleSubmit}>
            <div className="form-group">
              <label className="text-base font-redhat-semibold text-black">
                Reason for reaching out
              </label>
              <div className="mt-1">
                <Select
                  options={reasonOptions}
                  className="react-select"
                  classNamePrefix={
                    formik.errors.reason && formik.touched.reason
                      ? "order-error"
                      : "order"
                  }
                  placeholder="Select.."
                  value={reasonOptions.find(
                    (option) => option.value === formik.values.reason
                  )}
                  onChange={(e) => {
                    formik.setFieldValue("reason", e.value);
                  }}
                />
              </div>
            </div>
            <div className="form-group mt-5">
              <label className="text-base font-redhat-semibold text-black">
                Subject *
              </label>
              <DialogInputBox
                type="text"
                name="subject"
                id="subject"
                placeholder="Subject"
                value={formik.values.subject}
                onChange={formik.handleChange}
                error={formik.errors.subject && formik.touched.subject}
              />
            </div>
            <div className="form-group mt-5">
              <label className="text-base font-redhat-semibold text-black">
                Description *
              </label>
              <DialogTextarea
                rows={4}
                name="desc"
                id="description"
                placeholder="Description"
                value={formik.values.desc}
                onChange={formik.handleChange}
                error={formik.errors.desc && formik.touched.desc}
              />
              <p className="text-base font-redhat-semibold text-greyText">
                Please enter the details of your request
              </p>
            </div>
            <div className="form-group mt-5">
              <label className="text-base font-redhat-semibold text-black">
                Attachments
              </label>
              <div className="mt-2">
                <ReactImageUploading
                  multiple
                  value={attachments}
                  onChange={onChange}
                  maxNumber={maxLimit}
                  dataURLKey="data_url"
                >
                  {({ imageList, onImageUpload, onImageRemove }) => (
                    <div className="grid grid-cols-4 gap-5">
                      <div className="col-span-1">
                        <div
                          className="flex items-center justify-center w-full h-20 bg-white hover:bg-logoGreen hover:bg-opacity-10 border border-greyBorder hover:border-logoGreen rounded-md cursor-pointer transition-all duration-300 ease-in-out"
                          onClick={onImageUpload}
                        >
                          <p className="text-base font-redhat-semibold text-black">
                            Select file{" "}
                          </p>
                        </div>
                      </div>

                      {imageList.map((image, index) => (
                        <div className="col-span-1 relative" key={index}>
                          <img
                            src={image["data_url"]}
                            alt=""
                            className="w-full h-20 rounded-md"
                          />

                          <div className="absolute -top-2 -right-2">
                            <Button
                              className="shadow-none hover:shadow-none p-2 bg-white border border-greyBorder rounded-full"
                              onClick={onImageRemove}
                            >
                              <IoTrash className="w-4 h-4 text-greyText" />
                            </Button>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </ReactImageUploading>
              </div>
            </div>
            <div className="form-group mt-5">
              <label className="text-base font-redhat-semibold text-black">
                Priority
              </label>
              <div className="mt-1">
                <Select
                  options={priorityOptions}
                  className="react-select"
                  classNamePrefix={
                    formik.errors.priorityOptions &&
                    formik.touched.priorityOptions
                      ? "order-error"
                      : "order"
                  }
                  placeholder="Select.."
                  onChange={(e) => {
                    formik.setFieldValue("priority", e.value);
                  }}
                  value={priorityOptions.find(
                    (option) => option.value === formik.values.priority
                  )}
                />
              </div>
            </div>
            <div className="mt-5">
              <Button
                type="submit"
                disabled={supportLoading}
                className="shadow-none hover:shadow-none green-gradient normal-case text-base font-redhat-semibold text-white rounded-md"
              >
                {supportLoading ? "Please wait..." : "Create support ticket"}
              </Button>
            </div>
          </form>
        </DialogBody>
      </Dialog>

      <Dialog
        open={successModal}
        handler={() => setSuccessModal(!successModal)}
        size="sm"
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
        className="overflow-visible"
      >
        <DialogBody className="support-ticket-modalbody">
          <div>
            <h5 className="text-2xl font-redhat-bold  text-center">
              Your Support Ticket was submitted!
            </h5>
            <img
              src={require("../../../assets/images/icons/check-icon.png")}
              alt="Ticket submitted"
              className="w-20 h-20 mt-5 mx-auto"
            />
            <div className="mt-5">
              <p className="w-4/5 text-lg font-redhat-semibold  text-center mx-auto">
                Our typical response time is 24-48 hours
              </p>
            </div>
          </div>
          <div className="absolute top-1 right-1">
            <Button
              className="shadow-none hover:shadow-none rounded-full bg-transparent hover:bg-black hover:bg-opacity-10 p-2 transition-all duration-300 ease-in-out"
              onClick={() => setSuccessModal(!successModal)}
            >
              <IoClose className="w-5 h-5 text-greyText" />
            </Button>
          </div>
          {/* <Button
            className="close-icon"
            onClick={() => {
              setSuccessModal(!successModal);
            }}
          >
            <IoClose color="#000" size="18" />
          </Button> */}
        </DialogBody>
      </Dialog>
    </header>
  );
};

export default Header;
