/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Link, useLocation } from "react-router-dom";

/* PACKAGES */
import { Helmet } from "react-helmet";

/* MATERIAL TAILWIND COMPONENTS */
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  Button,
} from "@material-tailwind/react";

/* USER DEFINED COMPONENTS */


/* ASSETS */
import Alpaca from "assets/images/logos/alpaca.png";

/* ICONS */
import { GoArrowLeft } from "react-icons/go";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

/* SAMPLE DATA */
import accountsConnected from "data/accounts";
import { filledOrders } from "data/stocks";

/* ASSETS */
import CharlesSchwab from "assets/images/logos/charles-schwab.jpeg";
import {
  getAccountListApi,
  getActiveSingleApi,
  getAccountAllDetailsApi,
  getAccountDetailsApi,
} from "service/api";
import CreateOrderComponent from "components/forms/createOrder";
import { useDispatch, useSelector } from "react-redux";

import userAction from "redux/actions/user.action";
import TradeHistory from "components/others/tradeHistory";

/* ACCORDIONN ANIMATION */
const CUSTOM_ANIMATION = {
  mount: { scale: 1 },
  unmount: { scale: 0.9 },
};

/* TIMING OPTIONS */
const timingOptions = [
  { value: "Day only", label: "Day only" },
  { value: "Post market closure", label: "Post market closure" },
];

const CreateTradePage = () => {
  /* GLOBAL STATE */

  const dispatch = useDispatch();

  const location = useLocation();
  const query = new URLSearchParams(location.search);

  /* STATE VARIABLES */
  const selectedSymbol = query.get("symbol");

  const [symbolDetails, setSymbolDetails] = useState(null);

  const [accountList, setAccountList] = useState([]);

  const [orderType, setOrderType] = useState("Buy");
  const [advancedOptions, setAdvancedOptions] = useState(false);

  /* VIEW HANDLER STATES */
  const [view, setView] = useState("create");

  /* ACCORDION STATES */
  const [accountAccordion, setAccountAccordion] = useState(0);

  /* ACCORDION HANDLING */
  const handleOpen = (value) => {
    setAccountAccordion(accountAccordion === value ? 0 : value);
  };

  // const getAccountsList = () => {
  //   getAccountListApi(userDetails?._id).then((res) => {
  //     setAccountsList(res?.data?.accounts);
  //   });
  // };

  useEffect(() => {
    dispatch(userAction.setApiStatus(true));
  }, [view]);

  /* ORDER PLACED COMPONENT */
  const OrderPlacedComponent = () => {
    return (
      <div className="w-full  bg-greyBg border border-greyBorder rounded-md p-5">
        <h5 className="text-2xl font-redhat-extrabold text-black text-center">
          Your order was placed!
        </h5>
        <div className="mt-3">
          <img
            src={require("../../../../assets/images/icons/check.png")}
            className="w-16 h-16 mx-auto"
            alt="Order placed"
          />
        </div>
        <div className="mt-3">
          <p className="text-lg font-redhat-semibold text-black text-center">
            Check your order status below
          </p>
          <div className="w-11/12  xl:w-1/3  lg:w-1/3 mx-auto">
            <div className="flex justify-around items-center gap-3 mt-5">
              <Button
                className=" w-full shadow-none hover:shadow-none bg-black hover:bg-opacity-80 rounded-md text-white font-redhat-bold text-sm px-5 py-3 normal-case transition-all duration-300 ease-in-out"
                onClick={() => setView("create")}
              >
                Go back
              </Button>
              <Button
                className="w-full shadow-none hover:shadow-none bg-logoGreen hover:bg-opacity-80 rounded-md text-white font-redhat-bold text-sm px-5 py-3 normal-case transition-all duration-300 ease-in-out whitespace-nowrap"
                onClick={() => setView("create")}
              >
                New order
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  /* CANCEL ORDER COMPONENT */
  const OrderCancelledComponent = () => {
    return (
      <div className="w-full bg-greyBg border border-greyBorder rounded-md p-5">
        <h5 className="text-2xl font-redhat-extrabold text-black text-center">
          Your order was cancelled!
        </h5>
        <div className="mt-3">
          <img
            src={require("../../../../assets/images/icons/remove.png")}
            className="w-16 h-16 mx-auto"
            alt="Order cancelled"
          />
        </div>
        <div className="mt-3">
          <p className="text-lg font-redhat-semibold text-black text-center">
            Check your order status below
          </p>
          <div className="w-11/12  xl:w-1/3  lg:w-1/3 mx-auto">
            <div className="flex items-center gap-3 mt-5">
              <Button
                className="w-full shadow-none hover:shadow-none bg-black hover:bg-opacity-80 rounded-md text-white font-redhat-bold text-sm px-5 py-3 normal-case transition-all duration-300 ease-in-out"
                onClick={() => setView("create")}
              >
                Go back
              </Button>
              <Button
                className="w-full shadow-none hover:shadow-none bg-logoGreen hover:bg-opacity-80 rounded-md text-white font-redhat-bold text-sm px-5 py-3 normal-case transition-all duration-300 ease-in-out whitespace-nowrap"
                onClick={() => setView("create")}
              >
                New order
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  /* UPDATE ORDER COMPONENT */
  // const OrderUpdateComponent = (props) => {
  //   return (
  //     <div className="w-full xl:w-3/5 lg:w-3/5 bg-greyBg border border-greyBorder rounded-md p-5">
  //       <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-y-5 xl:gap-10 lg:gap-10">
  //         <div className="col-span-1">
  //           <div className="w-full xl:w-4/5 lg:w-4/5">
  //             {/* STOCK INFORMATION */}
  //             <div className="mt-0">
  //               <p className="text-lg font-redhat-extrabold text-black">
  //                 1. Create your trade
  //               </p>
  //               <div className="mt-3">
  //                 <div className="w-full bg-white border border-inputBox rounded-md py-2 px-3">
  //                   <div className="flex items-center justify-between">
  //                     <h5 className="text-base font-redhat-semibold text-black">
  //                       {props?.underlying}
  //                     </h5>
  //                     <div className="p-0 text-right">
  //                       <p className="text-sm font-redhat-regular text-black">
  //                         ${props?.price}
  //                       </p>
  //                       <p className="text-sm font-redhat-regular text-black">
  //                         -$
  //                         {(
  //                           (props?.price * props?.percent_change) /
  //                           100
  //                         ).toFixed(5)}{" "}
  //                         ({props?.percent_change}%)
  //                       </p>
  //                     </div>
  //                   </div>
  //                 </div>
  //               </div>
  //               <div className="mt-2">
  //                 <div className="flex items-center justify-between">
  //                   <div className="p-0">
  //                     <p className="text-sm font-redhat-regular text-black">
  //                       Bid: ${props?.bid}
  //                     </p>
  //                   </div>
  //                   <div className="p-0">
  //                     <p className="text-sm font-redhat-regular text-black">
  //                       Ask: ${props?.ask}
  //                     </p>
  //                   </div>
  //                   <div className="p-0">
  //                     <p className="text-sm font-redhat-regular text-black">
  //                       Last: ${props?.last}
  //                     </p>
  //                   </div>
  //                 </div>
  //               </div>
  //             </div>

  //             {/* BUY/SELL/SHORT ORDER */}
  //             <div className="mt-5">
  //               <div className="grid grid-cols-3 gap-5">
  //                 <div className="col-span-1">
  //                   <Button
  //                     className={`w-full shadow-none hover:shadow-none ${
  //                       orderType !== "Buy"
  //                         ? "bg-white hover:bg-lightGreen hover:bg-opacity-10 border border-inputBox"
  //                         : "bg-lightGreen bg-opacity-10 border border-lightGreen"
  //                     }  rounded-md py-2 text-black normal-case font-redhat-semibold text-base transition-all duration-300 ease-in-out`}
  //                     onClick={() => setOrderType("Buy")}
  //                   >
  //                     Buy
  //                   </Button>
  //                 </div>
  //                 <div className="col-span-1">
  //                   <Button
  //                     className={`w-full shadow-none hover:shadow-none ${
  //                       orderType !== "Sell"
  //                         ? "bg-white hover:bg-lightGreen hover:bg-opacity-10 border border-inputBox"
  //                         : "bg-lightGreen bg-opacity-10 border border-lightGreen"
  //                     }  rounded-md py-2 text-black normal-case font-redhat-semibold text-base transition-all duration-300 ease-in-out`}
  //                     onClick={() => setOrderType("Sell")}
  //                   >
  //                     Sell
  //                   </Button>
  //                 </div>
  //                 <div className="col-span-1">
  //                   <Button
  //                     className={`w-full shadow-none hover:shadow-none ${
  //                       orderType !== "Short"
  //                         ? "bg-white hover:bg-lightGreen hover:bg-opacity-10 border border-inputBox"
  //                         : "bg-lightGreen bg-opacity-10 border border-lightGreen"
  //                     }  rounded-md py-2 text-black normal-case font-redhat-semibold text-base transition-all duration-300 ease-in-out`}
  //                     onClick={() => setOrderType("Short")}
  //                   >
  //                     Short
  //                   </Button>
  //                 </div>
  //               </div>
  //             </div>

  //             {advancedOptions ? (
  //               <>
  //                 {/* ORDER TYPE */}
  //                 <div className="mt-5">
  //                   <div className="form-group">
  //                     <label className="text-sm font-redhat-semibold text-black">
  //                       Order type
  //                     </label>
  //                     <div className="mt-1">
  //                       <Select
  //                         options={orderTypeOptions}
  //                         classNamePrefix="order"
  //                         placeholder="Select order type"
  //                         className="react-select"
  //                       />
  //                     </div>
  //                   </div>
  //                 </div>

  //                 {/* LIMIT PRICE */}
  //                 <div className="mt-5">
  //                   <div className="form-group">
  //                     <label className="text-sm font-redhat-semibold text-black">
  //                       Limit Price
  //                     </label>
  //                     <div className="mt-1">
  //                       <input
  //                         name="limitPrice"
  //                         id="limitPrice"
  //                         placeholder="Limit Price"
  //                         className="block w-full bg-white border border-inputBox px-4 py-2 rounded-md outline-none focus:outline-none text-sm font-redhat-semibold text-black placeholder:font-redhat-regular"
  //                       />
  //                     </div>
  //                   </div>
  //                 </div>

  //                 {/* TIMING */}
  //                 <div className="mt-5">
  //                   <div className="form-group">
  //                     <label className="text-sm font-redhat-semibold text-black">
  //                       Timing
  //                     </label>
  //                     <div className="mt-1">
  //                       <Select
  //                         options={timingOptions}
  //                         classNamePrefix="order"
  //                         placeholder="Select timing"
  //                         className="react-select"
  //                       />
  //                     </div>
  //                   </div>
  //                 </div>

  //                 {/* ADVANCED OPTIONS ANND GO BACK */}
  //                 <div className="mt-5">
  //                   <Button
  //                     className="p-0 shadow-none hover:shadow-none text-base font-redhat-semibold text-black underline normal-case bg-transparent"
  //                     onClick={() => setAdvancedOptions(!advancedOptions)}
  //                   >
  //                     Advanced options
  //                   </Button>

  //                   <Link
  //                     to="/trade"
  //                     className="flex items-center gap-1 mt-5 text-black hover:text-logoGreen transition-all duration-300 ease-in-out"
  //                   >
  //                     <GoArrowLeft className="w-5 h-5" />
  //                     <p className="text-sm font-redhat-semibold">Go back</p>
  //                   </Link>
  //                 </div>
  //               </>
  //             ) : (
  //               <>
  //                 <div className="mt-5">
  //                   <Button
  //                     className="p-0 shadow-none hover:shadow-none text-base font-redhat-semibold text-black underline normal-case bg-transparent"
  //                     onClick={() => setAdvancedOptions(!advancedOptions)}
  //                   >
  //                     Advanced options
  //                   </Button>

  //                   <Link
  //                     to="/trade"
  //                     className="flex items-center gap-1 mt-5 text-black hover:text-logoGreen transition-all duration-300 ease-in-out"
  //                   >
  //                     <GoArrowLeft className="w-5 h-5" />
  //                     <p className="text-sm font-redhat-semibold">Go back</p>
  //                   </Link>
  //                 </div>
  //               </>
  //             )}
  //           </div>
  //         </div>
  //         <div className="col-span-1">
  //           <div className="w-full">
  //             {/* HEADING */}
  //             <div className="mt-0">
  //               <p className="text-lg font-redhat-extrabold text-black">
  //                 2. Select the account and amount of shares
  //               </p>
  //             </div>

  //             {/* ACCOUNTS LIST */}
  //             <div className="mt-5">
  //               {accountsConnected.map((data) => (
  //                 <div className="mt-5 first:mt-0" key={data.id}>
  //                   <p className="text-base font-redhat-bold text-black">
  //                     {data.brokerName}
  //                   </p>
  //                   {data.accounts.map((item) => (
  //                     <AccountAccordion
  //                       index={item.id}
  //                       image={item.logo}
  //                       broker={data.brokerName}
  //                       brokerText={item.brokerText}
  //                       brokerID={item.brokerID}
  //                       stockPrice={item.stockPrice}
  //                       stock={item.stock}
  //                       price={item.price}
  //                       orderValue={item.orderValue}
  //                     />
  //                   ))}
  //                 </div>
  //               ))}

  //               {/* OPTIONS */}
  //               <div className="flex items-center gap-5 mt-5">
  //                 <Button
  //                   onClick={() => setView("cancel")}
  //                   className="w-full shadow-none hover:shadow-none bg-[#E2E2E2] hover:bg-opacity-80 rounded-md text-black font-redhat-bold text-sm px-5 py-3 normal-case transition-all duration-300 ease-in-out"
  //                 >
  //                   Cancel Trade
  //                 </Button>
  //                 <Button
  //                   className="w-full shadow-none hover:shadow-none bg-logoGreen hover:bg-opacity-80 rounded-md text-white font-redhat-bold text-sm px-5 py-3 normal-case transition-all duration-300 ease-in-out"
  //                   onClick={() => setView("placed")}
  //                 >
  //                   Place Trade
  //                 </Button>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  /* ACCOUNT ACCORDION */
  // const AccountAccordion = (props) => {
  //   return (
  //     <div className="mt-2 first:mt-0">
  //       <Accordion
  //         open={accountAccordion === props.index}
  //         animate={CUSTOM_ANIMATION}
  //         className="border border-inputBox rounded-md"
  //       >
  //         <AccordionHeader
  //           className="w-full bg-white p-3 rounded-md flex items-center justify-between trade-accordion border-b-0"
  //           onClick={() => handleOpen(props.index)}
  //         >
  //           <div className="flex items-center gap-3">
  //             <img
  //               src={props.image}
  //               className="w-10 h-10 rounded"
  //               alt={props.broker}
  //             />
  //             <div className="p-0">
  //               <h5 className="text-base font-redhat-semibold text-black">
  //                 {props.brokerText} # ---{props.brokerID}
  //               </h5>
  //               <p className="text-sm font-redhat-semibold text-greyText mt-1">
  //                 ${props.stockPrice} available to trade {props.stock}
  //               </p>
  //             </div>
  //           </div>
  //           <div className="">
  //             <h5 className="text-base font-redhat-bold text-black">
  //               ${props.price}
  //             </h5>
  //             {accountAccordion === props.index ? (
  //               <IoIosArrowUp className="w-5 h-5 text-greyText mt-1 ml-auto" />
  //             ) : (
  //               <IoIosArrowDown className="w-5 h-5 text-greyText mt-1 ml-auto" />
  //             )}
  //           </div>
  //         </AccordionHeader>
  //         <AccordionBody className="bg-white p-3 border-t border-inputBox rounded-b-md">
  //           <div className="flex items-center gap-2">
  //             <p className="text-sm font-redhat-semibold text-black">
  //               Quantity of shares
  //             </p>
  //             <input
  //               name="sharesQty"
  //               id="sharesQty"
  //               placeholder="Quantity"
  //               className="w-1/3 block bg-white border border-inputBox px-4 py-2 rounded-md outline-none focus:outline-none text-sm font-redhat-semibold text-black placeholder:font-redhat-regular"
  //             />
  //           </div>
  //           <div className="mt-2">
  //             <p className="text-sm font-redhat-semibold text-black">
  //               Estimated order value:{" "}
  //               <span className="font-redhat-extrabold">
  //                 ${parseFloat(props.orderValue).toFixed(2)}
  //               </span>
  //             </p>
  //           </div>
  //         </AccordionBody>
  //       </Accordion>
  //     </div>
  //   );
  // };

  /* AMOUNT AND ACCOUNT SELECTION BOX */
  // const AmountSelectionBox = (props) => {
  //   return (
  //     <div className="w-full">
  //       {/* HEADING */}
  //       <div className="mt-0">
  //         <p className="text-lg font-redhat-extrabold text-black">
  //           2. Select the account and amount of shares
  //         </p>
  //       </div>

  //       {/* ACCOUNTS LIST */}
  //       <div className="mt-5">
  //         {accountsConnected.map((data) => (
  //           <div className="mt-5 first:mt-0" key={data.id}>
  //             <p className="text-base font-redhat-bold text-black">
  //               {data.brokerName}
  //             </p>
  //             {data.accounts.map((item) => (
  //               <AccountAccordion
  //                 index={item.id}
  //                 image={item.logo}
  //                 broker={data.brokerName}
  //                 brokerText={item.brokerText}
  //                 brokerID={item.brokerID}
  //                 stockPrice={item.stockPrice}
  //                 stock={item.stock}
  //                 price={item.price}
  //                 orderValue={item.orderValue}
  //               />
  //             ))}
  //           </div>
  //         ))}

  //         {/* OPTIONS */}
  //         {view === "change" && (
  //           <div className="flex items-center gap-5 mt-5">
  //             <Button onClick={() => setView("cancel")} className="w-full shadow-none hover:shadow-none bg-[#E2E2E2] hover:bg-opacity-80 rounded-md text-black font-redhat-bold text-sm px-5 py-3 normal-case transition-all duration-300 ease-in-out">
  //               Cancel Trade
  //             </Button>
  //             <Button
  //               className="w-full shadow-none hover:shadow-none bg-logoGreen hover:bg-opacity-80 rounded-md text-white font-redhat-bold text-sm px-5 py-3 normal-case transition-all duration-300 ease-in-out"
  //               onClick={() => setView("placed")}
  //             >
  //               Place Trade
  //             </Button>
  //           </div>
  //         )}

  //         {view === "create" && (
  //           <Button
  //             className="mt-5 w-full shadow-none hover:shadow-none bg-logoGreen hover:bg-opacity-80 rounded-md text-white font-redhat-bold text-sm px-5 py-3 normal-case transition-all duration-300 ease-in-out"
  //             onClick={() => setView("placed")}
  //           >
  //             Place Trades
  //           </Button>
  //         )}
  //       </div>
  //     </div>
  //   );
  // };

  const getSymbolDetailsFunction = () => {
    getActiveSingleApi(selectedSymbol).then((res) => {
      setSymbolDetails(res?.data?.security);
    });
  };

  // const getAccountListFunction = () => {
  //   getAccountListApi(userDetails?._id).then((res) => {
  //     setAccountList(res?.data?.accounts);
  //   });
  // };

  useEffect(() => {
    // getAccountListFunction();
    if (selectedSymbol) {
      getSymbolDetailsFunction();
    }
  }, [selectedSymbol]);

  return (
    <section className="px-3 xl:px-10 lg:px-10 py-3 xl:py-7 lg:py-7">
      {/* PAGE TITLE */}
      <Helmet>
        <title>Create your trade | ScaleTrade</title>
      </Helmet>

      <div className="container  mx-auto">
        {/* CONTENT */}
        <div className="w-full overflow-auto overflow-x-hidden overflow-y-auto hide-scrollbar mx-auto">
          {/* TRADE CREATION */}
          {/* {view === "change" ? (
            <div className="w-3/5 bg-greyBg border border-greyBorder rounded-md p-5">
              <div className="grid grid-cols-2 gap-10">
                <div className="col-span-1">
                  <TradeCreationBox stockName="AAPL" />
                </div>
                <div className="col-span-1">
                  <AmountSelectionBox />
                </div>
              </div>
            </div>
          ) : view === "placed" ? (
            <div className="w-3/5 bg-greyBg border border-greyBorder rounded-md p-5">
              <h5 className="text-2xl font-redhat-extrabold text-black text-center">
                Your order was placed!
              </h5>
              <div className="mt-3">
                <img
                  src={require("../../../assets/images/icons/check.png")}
                  className="w-16 h-16 mx-auto"
                  alt="Order placed"
                />
              </div>
              <div className="mt-3">
                <p className="text-lg font-redhat-semibold text-black text-center">
                  Check your order status below
                </p>
                <div className="w-1/4 mx-auto">
                  <div className="flex items-center gap-3 mt-5">
                    <Button
                      className="w-full shadow-none hover:shadow-none bg-black hover:bg-opacity-80 rounded-md text-white font-redhat-bold text-sm px-5 py-3 normal-case transition-all duration-300 ease-in-out"
                      onClick={() => setView("create")}
                    >
                      Go back
                    </Button>
                    <Button
                      className="w-full shadow-none hover:shadow-none bg-logoGreen hover:bg-opacity-80 rounded-md text-white font-redhat-bold text-sm px-5 py-3 normal-case transition-all duration-300 ease-in-out whitespace-nowrap"
                      onClick={() => setView("create")}
                    >
                      New order
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          ) : view === "cancel" ? (
            <div className="w-3/5 bg-greyBg border border-greyBorder rounded-md p-5">
              <h5 className="text-2xl font-redhat-extrabold text-black text-center">
                Your order was cancelled!
              </h5>
              <div className="mt-3">
                <img
                  src={require("../../../assets/images/icons/check.png")}
                  className="w-16 h-16 mx-auto"
                  alt="Order cancelled"
                />
              </div>
              <div className="mt-3">
                <p className="text-lg font-redhat-semibold text-black text-center">
                  Check your order status below
                </p>
                <div className="w-1/4 mx-auto">
                  <div className="flex items-center gap-3 mt-5">
                    <Button
                      className="w-full shadow-none hover:shadow-none bg-black hover:bg-opacity-80 rounded-md text-white font-redhat-bold text-sm px-5 py-3 normal-case transition-all duration-300 ease-in-out"
                      onClick={() => setView("create")}
                    >
                      Go back
                    </Button>
                    <Button
                      className="w-full shadow-none hover:shadow-none bg-logoGreen hover:bg-opacity-80 rounded-md text-white font-redhat-bold text-sm px-5 py-3 normal-case transition-all duration-300 ease-in-out whitespace-nowrap"
                      onClick={() => setView("create")}
                    >
                      New order
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="w-3/5 bg-greyBg border border-greyBorder rounded-md p-5">
              <div className="grid grid-cols-2 gap-10">
                <div className="col-span-1">
                  <TradeCreationBox stockDetails={symbolDetails} stockName={symbolDetails?.underlying} />
                </div>
                <div className="col-span-1">
                  <AmountSelectionBox />
                </div>
              </div>
            </div>
          )} */}

          {view === "create" && (
            <CreateOrderComponent
              accountList={accountList}
              symbolDetails={symbolDetails}
              setView={setView}
            />
          )}

          {view === "placed" && <OrderPlacedComponent />}

          {view === "cancel" && <OrderCancelledComponent />}

          {/* ACCOUNT ORDERS */}
          <TradeHistory />
        </div>
      </div>
    </section>
  );
};

export default CreateTradePage;
