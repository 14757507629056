/* eslint-disable no-unreachable */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */

/* IMPORT REACT  */
import React, { useState, useEffect, useCallback } from "react";

/* IMPORT OTP INPUT PACKAGE */
import OtpInput from "react-otp-input";

/* IMPORT PASSWORD CHECKLIST PACKAGE */
import PasswordChecklist from "react-password-checklist";

/* IMPORT ROUTER DOM FOR LINK */
import { Link, useNavigate } from "react-router-dom";

/* MATERIAL TAILWIND COMPONENTS */
import { Button } from "@material-tailwind/react";

/* IMPORT react-toastify FOR TOAST NOTIFICATION  */
import { toast } from "react-toastify";

/* IMPORT REACT COOKIE  */
import { useCookies } from "react-cookie";

/* REDUX IMPORTS */
import { useDispatch, useSelector } from "react-redux";
import userAction from "../../redux/actions/user.action";

/* ICONS */
import { IoEye, IoEyeOff, IoClose } from "react-icons/io5";

/* API IMPORTS */
import {
  authLogin,
  authRegisterEmailResend,
  authRegisterEmailVerify,
  authLoginSendOTP,
  authUpdatePassword,
} from "../../service/api";

/* IMPORT FORMIK FOR FORM-SUBMIT */
import { useFormik } from "formik";
import * as yup from "yup";
import { FaLeftLong } from "react-icons/fa6";

/* VALIDATION SCHEMA FOR FORGOT PASSWORD FORM */
const forgotPasswordFormValidationSchema = yup.object().shape({
  email: yup.string().email().required("Email is required."),
});
/* VALIDATION SCHEMA FOR VERIFY OTP FORM */
const verifyEmailFormValidationSchema = yup.object().shape({
  otp: yup
    .string()
    .required("OTP is required.")
    .min(6, "Must be exactly 6 digits")
    .max(6, "Must be exactly 6 digits"),
});

/* VALIDATION SCHEMA FOR RESET PASSWORD FORM */
const resetPasswordFormValidationSchema = yup.object().shape({
  // oldPassword: yup.string().required("Old Password  is required."),
  password: yup.string().required("New Password is required."),
  confirmPassword: yup.string().required("New Password is required."),
});


/* DIALOG INPUT */
const DialogInputBox = (props) => {
  return (
    <input
      type={props.type}
      name={props.name}
      id={props.id}
      value={props.value}
      onChange={props.onChange}
      onFocus={props.onFocus}
      placeholder={props.placeholder}
      className={
        props?.error
          ? "w-full block bg-white border border-red-600 rounded-md px-5 py-3 outline-none focus:border-logoGreen focus:shadow-md text-base font-redhat-semibold text-black transition-all duration-300 ease-in-out"
          : "w-full block bg-white border border-inputBox rounded-md px-5 py-3 outline-none focus:border-logoGreen focus:shadow-md text-base font-redhat-semibold text-black transition-all duration-300 ease-in-out"
      }
    />
  );
};

const ChangePasswordFormComponent = (props) => {
  /* INITIALIZE FUNCTIONS */
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [cookies, setCookie] = useCookies(["user"]);
  const user = useSelector((state) => state?.user);

  /* INITIALIZE STATE VARIABLES */
  const [loading, setLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState("sendOTP"); // Available State Variables  [login,verifyOTP]
  const [rememberMeOption, setRememberMeOption] = useState(false); // Remember Me Option

  const [passwordCheck, setPasswordCheck] = useState(true); // Check Password
  const [passwordCheckDisplay, setPasswordCheckDisplay] = useState(false); // Check Password

  const [forgotPasswordFormLoading, setForgotPasswordFormLoading] = useState(false);
  const [otpValidationFormLoading, setOtpValidationFormLoading] = useState(false);
  const [changePasswordFormLoading, setChangePasswordLoading] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [currentView, setCurrentView] = useState("register");

  const [timer, setTimer] = useState(0);
  const timeOutCallback = useCallback(
    () => setTimer((currTimer) => currTimer - 1),
    []
  );



  const handleCookieChange = (email, password) => {
    setCookie("email", email, { path: "/" });
    setCookie("password", password, { path: "/" });
  };

  // RESEND OTP / RESET TIMER
  const resetTimer = function () {
    if (timer === 0) {
      /* RESEND EMAIL OTP */
      const objectSend = {
        email: user?.user?.email,
      };
      /* CALL RESEND EMAIL API */
      authRegisterEmailResend(objectSend)
        .then((response) => {
          /* CHECK RESPONSE STATUS */
          if (response?.status) {
            /* SUCCESS NOTIFICATION */
            toast.success(response?.message);

            /* SHOW OTP VERIFY TAB */
            setCurrentTab("verifyOTP");
          }
        })
        .catch((e) => {
          console.log("Error: ", e);
        });
    }
    if (!timer) {
      setTimer(120);
    }
  };


  const forgotPasswordFormikFunction = useFormik({
    /* SET INITIAL VALUES FOR LOGIN FORM */
    initialValues: {
      email: "",
    },
    /* VALIDATION SCHEMA  */
    validationSchema: forgotPasswordFormValidationSchema,
    /* HANDLE FORM SUBMIT */
    onSubmit: (values) => {
      /* SET LOADING AS TRUE */
      setForgotPasswordFormLoading(true);
      /* CALL LOGIN API */
      authLoginSendOTP(values)
        .then((res) => {
          /* CHECK RESPONSE STATUS */
          if (res?.status) {
            /* SET USER DATA TO GLOBAL STATE */
            dispatch(userAction.setToken(res?.data?.token));
            dispatch(userAction.setUser(res?.data?.userData));

            toast.success(res?.message);
            /* SHOW OTP VERIFY TAB */
            setCurrentTab("verifyOTP");
            setTimer(120);

            /* SET USER LOGGED IN STATUS TRUE */
            dispatch(userAction.setLoggedIn(false));
          } else {
            /* ERROR NOTIFICATION */
            toast.error(res?.message);
          }
          setForgotPasswordFormLoading(false);
        })
        .catch((e) => {
          setLoading(false);
          toast.error(e?.response?.data?.message);
        })
        .finally((res) => {
          setForgotPasswordFormLoading(false);
        });
    },
  });

  /* HANDLE OTP VALIDATION FORM USING FORMIK */
  const verifyEmailFormikFunction = useFormik({
    /* SET INITIAL VALUES FOR LOGIN FORM */
    initialValues: {
      otp: "",
    },
    /* VALIDATION SCHEMA  */
    validationSchema: verifyEmailFormValidationSchema,
    /* HANDLE FORM SUBMIT */
    onSubmit: (values) => {
      /* SET LOADING AS TRUE */
      setOtpValidationFormLoading(true);
      /* CALL LOGIN API */
      const objectSend = {
        code: values?.otp,
        type: "reset",
      };
      authRegisterEmailVerify(objectSend)
        .then((res) => {
          /* CHECK RESPONSE STATUS */
          if (res?.status) {
            /* SHOW NOTIFICATION */
            toast.success(res?.message);
            setCurrentTab("resetPassword");
            // /* SET TIMEOUT FOR 1.5 SECOND THEN NAVIGATE TO DASHBOARD */
            // setTimeout(() => {
            //   navigate("/dashboard"); // navigate to DASHBOARD
            // }, 1500);
            // /* SET USER LOGGED IN STATUS TRUE */
            // dispatch(userAction.setLoggedIn(true));
          } else {
            /* ERROR NOTIFICATION */
            toast.error(res?.message);
          }
        })
        .catch((e) => {
          setOtpValidationFormLoading(false);
          toast.error(e?.response?.data?.message);
        })
        .finally((res) => {
          setOtpValidationFormLoading(false);
        });
    },
  });

  /* HANDLE CHANGE PASSWORD FORM USING FORMIK */
  const resetPasswordFormikFunction = useFormik({
    /* SET INITIAL VALUES FOR LOGIN FORM */
    initialValues: {
      // oldPassword: "",
      password: "",
      confirmPassword: "",
    },
    /* VALIDATION SCHEMA  */
    validationSchema: resetPasswordFormValidationSchema,
    /* HANDLE FORM SUBMIT */
    onSubmit: (values) => {
      /* CHECK PASSWORD  */
      if (passwordCheck) {
        if (values.password !== values.confirmPassword) {
          toast.error("Confirm Password should be similar to New Password");
        } else {
          /* SET LOADING AS TRUE */
          setChangePasswordLoading(true);
          /* CALL LOGIN API */
          authUpdatePassword({ password: values?.password })
            .then((res) => {
              /* CHECK RESPONSE STATUS */
              if (res?.status) {
                /* SHOW NOTIFICATION */
                toast.success(res?.message);
                /* SET TIMEOUT FOR 1.5 SECOND THEN NAVIGATE TO DASHBOARD */
                setTimeout(() => {
                  props.setCurrentView("login") 
                }, 1500);
              } else {
                /* ERROR NOTIFICATION */
                toast.error(res?.message);
              }
            })
            .catch((e) => {
              setChangePasswordLoading(false);
              toast.error(e?.response?.data?.message);
            })
            .finally((res) => {
              setChangePasswordLoading(false);
            });
        }
      } else {
        toast.error("Please check Password");
      }
    },
  });

  /* USE-EFFECT TIMER */
  useEffect(() => {
    timer > 0 && setTimeout(timeOutCallback, 1000);
  }, [timer, timeOutCallback]);

  return (
    <>
      {/* SEND OTP */}
      <div className={currentTab === "sendOTP" ? "py-5 px-8" : "hidden"}>
        <h5 className="text-xl font-redhat-extrabold text-black">
          Forgot your password?
        </h5>
        <p className="mt-2 text-base font-redhat-regular text-[#494949]">
          No worries, we'll send your reset instructions.
        </p>
        <form className="mt-5" onSubmit={forgotPasswordFormikFunction.handleSubmit}>
          <div className="form-group mt-5">
            <DialogInputBox
              type="email"
              name="email"
              id="email"
              placeholder="Email address"
              value={forgotPasswordFormikFunction.values.email}
              onChange={forgotPasswordFormikFunction.handleChange}
              error={
                forgotPasswordFormikFunction.errors.email &&
                  forgotPasswordFormikFunction.touched.email
                  ? true
                  : false
              }
            />
          </div>
          <Button
            type="submit"
            disabled={forgotPasswordFormLoading}
            className="mt-5 w-full bg-gradient font-redhat-semibold text-white text-base normal-case"
          >
            {forgotPasswordFormLoading ? "Please wait..." : "Verify Email"}
          </Button>
        </form>
        <div className="mt-5">
          <p onClick={() => { props.setCurrentView("login") }} className="text-base font-redhat-semibold text-greyText text-center flex mx-auto  justify-center cursor-pointer">
            <FaLeftLong color="#A1A1A1" className="mt-1 mr-2" /> Back to sign in
          </p>
          <p className="text-base font-redhat-semibold text-greyText text-center">
            Don't have an account?{" "}
            <span
              className="underline cursor-pointer"
              onClick={() =>
                // (window.location.href = "/calculator?pageType=register")
                props.setCurrentView("register")
              }
            >
              Create one for free
            </span>
          </p>
        </div>
      </div>

      {/* VERIFY */}
      <div className={currentTab === "verifyOTP" ? "py-5 px-8" : "hidden"}>
        <h5 className="text-xl font-redhat-extrabold text-black">
          Please Verify Account!
        </h5>
        <p className="mt-2 text-base font-redhat-regular text-[#494949]">
          Enter OTP Sent to your email to verify Account
        </p>
        <form className="mt-5" onSubmit={verifyEmailFormikFunction.handleSubmit}>
          <div className="form-group mt-5">
            <OtpInput
              value={verifyEmailFormikFunction?.values.otp}
              onChange={(e) =>
                verifyEmailFormikFunction.setFieldValue("otp", e)
              }
              containerStyle={"verificationOTP"}
              numInputs={6}
              renderSeparator={<span>-</span>}
              renderInput={(props) => <input {...props} />}
            />
            {verifyEmailFormikFunction.errors.otp &&
              verifyEmailFormikFunction.touched.otp && (
                <small style={{ color: "red" }}>
                  {verifyEmailFormikFunction.errors.otp}
                </small>
              )}
          </div>
          <div className="otp-line">
            <p>Didn't receive code?</p>
            <Button
              disabled={timer !== 0}
              className="resend-otp-btn"
              onClick={resetTimer}
            >
              Resend code
            </Button>
            <p>({timer}s)</p>
          </div>
          <Button
            type="submit"
            disabled={otpValidationFormLoading}
            className="mt-5 w-full bg-gradient font-redhat-semibold text-white text-base normal-case"
          >
            {otpValidationFormLoading ? "Please wait..." : "Verify "}
          </Button>
        </form>
        <div className="mt-5">
          <p onClick={() => { props.setCurrentView("login") }} className="text-base font-redhat-semibold text-greyText text-center flex mx-auto  justify-center cursor-pointer">
            <FaLeftLong color="#A1A1A1" className="mt-1 mr-2" /> Back to sign in
          </p>
          <p className="text-base font-redhat-semibold text-greyText text-center">
            Don't have an account?{" "}
            <span
              className="underline cursor-pointer"
              onClick={() =>
                // (window.location.href = "/calculator?pageType=register")
                props.setCurrentView("register")
              }
            >
              Create one for free
            </span>
          </p>
        </div>
      </div>

      {/* CHANGE PASSWORD */}
      <div className={currentTab === "resetPassword" ? "py-5 px-8" : "hidden"}>
        <h5 className="text-xl font-redhat-extrabold text-black">
          Please Enter New Password
        </h5>
        <p className="mt-2 text-base font-redhat-regular text-[#494949]">
          No worries, we'll send your reset instructions.
        </p>
        <form className="mt-5" onSubmit={resetPasswordFormikFunction.handleSubmit}>
          <div className="form-group mt-5">
            <DialogInputBox
              type="password"
              placeholder="New Password"
              name="password"
              error={
                resetPasswordFormikFunction.errors.password &&
                resetPasswordFormikFunction.touched.password
                  ? true
                  : false
              }
              value={resetPasswordFormikFunction.values.password}
              onChange={resetPasswordFormikFunction.handleChange}
              onFocus={() => {
                setPasswordCheckDisplay(true);
              }}
            />
            {!passwordCheck &&
              resetPasswordFormikFunction.touched.password &&
              !resetPasswordFormikFunction.errors.password && (
                <small style={{ color: "red" }}>
                  Please check Password
                </small>
              )}
            {passwordCheckDisplay && (
              <PasswordChecklist
                rules={[
                  "minLength",
                  "lowercase",
                  "capital",
                  "number",
                  "specialChar",
                ]}
                iconSize={12}
                minLength={8}
                value={resetPasswordFormikFunction.values.password}
                messages={{
                  minLength: "At least 8 characters long",
                  lowercase: "One lowercase character",
                  capital: "One uppercase character",
                  number: "One number character",
                  specialChar: "One special character",
                }}
                onChange={(e) => {
                  setPasswordCheck(e);
                }}
                className="password-checklist mt-3"
              />
            )}
          </div>

          <div className="form-group mt-5">
            <DialogInputBox
              type="password"
              placeholder="Confirm Password"
              name="confirmPassword"
              error={
                resetPasswordFormikFunction.errors.confirmPassword &&
                resetPasswordFormikFunction.touched.confirmPassword
                  ? true
                  : false
              }
              value={
                resetPasswordFormikFunction.values.confirmPassword
              }
              onChange={resetPasswordFormikFunction.handleChange}
            />

          </div>
          <Button
            type="submit"
            disabled={changePasswordFormLoading}
            className="mt-5 w-full bg-gradient font-redhat-semibold text-white text-base normal-case"
          >
            {changePasswordFormLoading ? "Please wait..." : "Reset Password"}
          </Button>
        </form>
        <div className="mt-5">
          <p onClick={() => { props.setCurrentView("login") }} className="text-base font-redhat-semibold text-greyText text-center flex mx-auto  justify-center cursor-pointer">
            <FaLeftLong color="#A1A1A1" className="mt-1 mr-2" /> Back to sign in
          </p>
          <p className="text-base font-redhat-semibold text-greyText text-center">
            Don't have an account?{" "}
            <span
              className="underline cursor-pointer"
              onClick={() =>
                // (window.location.href = "/calculator?pageType=register")
                props.setCurrentView("register")
              }
            >
              Create one for free
            </span>
          </p>
        </div>
      </div>
    </>
  );
};
export default ChangePasswordFormComponent;
