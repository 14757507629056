/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unreachable */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */

/* IMPORT REACT  */
import React, { useState, useEffect, useCallback } from "react";

/* IMPORT FORMIK FOR FORM-SUBMIT */
import { useFormik } from "formik";
import * as yup from "yup";

/* MATERIAL TAILWIND COMPONENTS */
import { Button } from "@material-tailwind/react";

/* IMPORT ROUTER DOM FOR LINK */
import { Link, useNavigate } from "react-router-dom";

/* IMPORT react-toastify FOR TOAST NOTIFICATION  */
import { toast } from "react-toastify";

/* IMPORT OTP INPUT PACKAGE */
import OtpInput from "react-otp-input";

/* IMPORT FRAMER FOR ANIMATION */
import { motion } from "framer-motion";

/* REDUX IMPORTS */
import { useDispatch, useSelector } from "react-redux";
import userAction from "../../redux/actions/user.action";

/* API IMPORTS */
import {
  authRegisterEmailResend,
  authRegisterEmailVerify,
} from "../../service/api";

/* VALIDATION SCHEMA FOR VERIFY OTP FORM */
const verifyEmailFormValidationSchema = yup.object().shape({
  otp: yup
    .string()
    .required("OTP is required.")
    .min(6, "Must be exactly 6 digits")
    .max(6, "Must be exactly 6 digits"),
});

/* DIALOG INPUT */
const DialogInputBox = (props) => {
  return (
    <input
      type={props.type}
      name={props.name}
      id={props.id}
      value={props.value}
      onChange={props.onChange}
      onFocus={props.onFocus}
      placeholder={props.placeholder}
      className={
        props?.error
          ? "w-full block bg-white border border-red-600 rounded-md px-5 py-3 outline-none focus:border-logoGreen focus:shadow-md text-base font-redhat-semibold text-black transition-all duration-300 ease-in-out"
          : "w-full block bg-white border border-inputBox rounded-md px-5 py-3 outline-none focus:border-logoGreen focus:shadow-md text-base font-redhat-semibold text-black transition-all duration-300 ease-in-out"
      }
    />
  );
};

const VerificationComponent = (props) => {
  /* INITIALIZE FUNCTIONS */
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user);

  /* INITIALIZE STATE VARIABLES */
  const [loading, setLoading] = useState(false);
  const [timer, setTimer] = useState(30);
  const timeOutCallback = useCallback(
    () => setTimer((currTimer) => currTimer - 1),
    []
  );

  // RESEND OTP / RESET TIMER
  const resetTimer = function () {
    if (timer === 0) {
      /* RESEND EMAIL OTP */
      const objectSend = {
        email: user?.user?.email,
      };
      /* CALL RESEND EMAIL API */
      authRegisterEmailResend(objectSend)
        .then((response) => {
          /* CHECK RESPONSE STATUS */
          if (response?.status) {
            /* SUCCESS NOTIFICATION */
            toast.success(response?.message);
          }
        })
        .catch((e) => {
          console.log("Error: ", e);
        });
    }
    if (!timer) {
      setTimer(30);
    }
  };

  const verifyEmailFormikFunction = useFormik({
    /* SET INITIAL VALUES FOR LOGIN FORM */
    initialValues: {
      otp: "",
    },
    /* VALIDATION SCHEMA  */
    validationSchema: verifyEmailFormValidationSchema,
    /* HANDLE FORM SUBMIT */
    onSubmit: (values) => {
      /* SET LOADING AS TRUE */
      setLoading(true);
      /* CALL LOGIN API */
      const objectSend = {
        code: values?.otp,
      };
      authRegisterEmailVerify(objectSend)
        .then((res) => {
          /* CHECK RESPONSE STATUS */
          if (res?.status) {
            /* SHOW NOTIFICATION */
            toast.success("Account Verified!");

            /* SET TIMEOUT FOR 1.5 SECOND THEN NAVIGATE TO DASHBOARD */
            setTimeout(() => {
              if (!res?.data?.firstName) {
                props.setCurrentView("onBoarding");
              } else {
                navigate("/dashboard"); // navigate to DASHBOARD
              }
            }, 1500);
            /* SET USER LOGGED IN STATUS TRUE */
            dispatch(userAction.setLoggedIn(true));
          } else {
            /* ERROR NOTIFICATION */
            toast.error(res?.message);
          }
        })
        .catch((e) => {
          setLoading(false);
          toast.error(e?.response?.data?.message);
        })
        .finally((res) => {
          setLoading(false);
        });
    },
  });

  /* USE-EFFECT TIMER */
  useEffect(() => {
    timer > 0 && setTimeout(timeOutCallback, 1000);
  }, [timer, timeOutCallback]);

  return (
    <div className="py-5 px-8">
      <h5 className="text-xl font-redhat-extrabold text-black">
        Email Verification
      </h5>
      <p className="mt-2 text-base font-redhat-regular text-[#494949]">
        Please enter the verification code sent on your email.
      </p>
      <form className="mt-5" onSubmit={verifyEmailFormikFunction.handleSubmit}>
        <div className="form-group">
          <OtpInput
            value={verifyEmailFormikFunction?.values.otp}
            onChange={(e) => verifyEmailFormikFunction.setFieldValue("otp", e)}
            containerStyle={
              verifyEmailFormikFunction.errors.otp &&
              verifyEmailFormikFunction.touched.otp
                ? "verificationErrorOTP"
                : "verificationOTP"
            }
            numInputs={6}
            renderSeparator={<span>-</span>}
            renderInput={(props) => <input {...props} />}
          />
          {verifyEmailFormikFunction.errors.otp &&
            verifyEmailFormikFunction.touched.otp && (
              <small style={{ color: "red" }}>
                {verifyEmailFormikFunction.errors.otp}
              </small>
            )}
        </div>
        <div className="flex mt-5 align-middle self-center gap-2">
          <p>Didn't receive code?</p>
          <div className="flex gap-1">
            <Button
              disabled={timer !== 0}
              className=" text-sm font-redhat-semibold bg-transparent text-black capitalize p-0 shadow-none hover:shadow-none mt-0"
              onClick={resetTimer}
            >
              Resend code
            </Button>
            <p>({timer}s)</p>
          </div>
        </div>
        <Button
          type="submit"
          className="mt-5 w-full bg-gradient font-redhat-semibold text-white text-base normal-case"
          disabled={loading}
        >
          {loading ? "Please wait..." : "Verify Account"}
        </Button>
      </form>
      <div className="mt-5">
        <p className="text-base font-redhat-semibold text-greyText text-center">
          Already have an account?{" "}
          <span
            className="underline cursor-pointer"
            onClick={() => {
              props.setCurrentView("login");
            }}
          >
            Sign in
          </span>
        </p>
      </div>
    </div>
  );
};
export default VerificationComponent;
