
import { combineReducers } from "redux";
import userReducer from "./reducer/user.reducer";
import userNotificationReducers from "./reducer/userNotification.reducer";

const rootReducer = combineReducers({
  user: userReducer,
  notifications : userNotificationReducers
});

export default rootReducer;