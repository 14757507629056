/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

function kFormatter(num) {
  return Math.abs(num) > 999
    ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "K"
    : Math.sign(num) * Math.abs(num);
}

function formatNumber(value) {
  const number = parseFloat(value);

  if (isNaN(number)) {
    throw new Error("Invalid number");
  }

  if (number >= 1000000) {
    return (number / 1000000).toFixed(1) + "M";
  } else if (number >= 1000) {
    return (number / 1000).toFixed(1) + "K";
  } else {
    return number.toLocaleString("en-US");
  }
}

const SelectionChart = ({ values, labels, withoutInvestment }) => {
  useEffect(() => {
    setState({
      series: [
        {
          name: "Estimated Investment Value",
          data: values,
        },
        {
          name: "Total Investment",
          data: withoutInvestment,
        },
      ],
      options: {
        chart: {
          height: "100%",
          type: "area",
          fontFamily: "Roboto-Medium, sans-serif",
          dropShadow: {
            enabled: false,
            blur: 5,
            opacity: 0.2,
            top: 5,
            left: 5,
          },
          toolbar: {
            show: false,
          },
        },
        colors: ["#008037", "#65d4db"],
        dataLabels: {
          enabled: false,
        },
        grid: {
          show: true,
        },
        stroke: {
          curve: "straight",
          borderColor: "#E5E5E5",
          position: "back",
        },
        xaxis: {
          categories: labels,
          tickPlacement: "between",
          tickAmount: 4,
          labels: {
            rotate: 0,
          },
        },
      },
    });
  }, [values, labels]);

  const [state, setState] = useState({
    series: [
      {
        name: "Estimated Investment Value",
        data: values,
      },
      {
        name: "Total Investment",
        data: withoutInvestment,
      },
    ],
    options: {
      chart: {
        height: "100%",
        type: "line",
        fontFamily: "Roboto-Medium, sans-serif",
        dropShadow: {
          enabled: true,
          blur: 5,
          opacity: 0.2,
          top: 5,
          left: 5,
        },
        toolbar: {
          show: false,
        },
      },
      colors: ["#008037", "#65d4db"],
      dataLabels: {
        enabled: false,
      },
      grid: {
        show: true,
      },
      stroke: {
        curve: "straight",
      },
      xaxis: {
        categories: labels,
        tickPlacement: "between",
        tickAmount: 4,
        labels: {
          style: {
            colors: ["#777"],
          },
        },
      },
      yaxis: {
        labels: {
          show: true,
          formatter: (value) => {
            return `$${value / 1000000}m`;
          },
          style: {
            colors: ["#777"],
          },
        },
      },
      tooltip: {
        x: {
          formatter: (value) => {
            return `Year ${value}`;
          },
        },
        y: {
          formatter: (value) => {
            return "$" + parseFloat(value).toLocaleString("en-US");
          },
        },
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          return (
            `<div class="custom-tooltip">` +
            `<p class="label">` +
            dataPointIndex +
            ` Years</p>` +
            `<p class="value">$` +
            formatNumber(series[seriesIndex][dataPointIndex]) +
            `</p>` +
            `</div>`
          );
        },
      },
    },
  });

  return (
    <div className="chart selection-page">
      <ReactApexChart
        series={state.series}
        options={state.options}
        type="area"
        height="100%"
      />
    </div>
  );
};

export default SelectionChart;
