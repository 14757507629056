import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

const DashboardChart = ({ values, labels }) => {
  useEffect(() => {
    setState({
      series: [
        {
          name: "Estimated Investment Value",
          data: values,
        },
      ],
      options: {
        chart: {
          height: "100%",
          type: "area",
          fontFamily: "Roboto-Medium, sans-serif",
          dropShadow: {
            enabled: true,
            blur: 5,
            opacity: 0.2,
            top: 5,
            left: 5,
          },
          toolbar: {
            show: false,
          },
        },
        colors: ["#008037"],
        dataLabels: {
          enabled: false,
        },
        grid: {
          show: false,
        },
        stroke: {
          curve: "straight",
        },
        xaxis: {
          categories: labels,
          tickPlacement: "between",
          tickAmount: 4,
          labels: {
            rotate: 0,
          },
        },
        yaxis: {
          labels: {
            show: false,
          },
        },
      },
    });
  }, [values, labels]);

  const [state, setState] = useState({
    series: [
      {
        name: "Estimated Investment Value",
        data: values,
      },
    ],
    options: {
      chart: {
        height: "100%",
        type: "line",
        fontFamily: "Roboto-Medium, sans-serif",
        dropShadow: {
          enabled: true,
          blur: 5,
          opacity: 0.2,
          top: 5,
          left: 5,
        },
        toolbar: {
          show: false,
        },
      },
      colors: ["#008037"],
      dataLabels: {
        enabled: false,
      },
      grid: {
        show: false,
      },
      stroke: {
        curve: "straight",
      },
      xaxis: {
        categories: labels,
        tickPlacement: "between",
        tickAmount: 4,
      },
      yaxis: {
        labels: {
          show: false,
          offsetX: 10,
        },
      },
      tooltip: {
        x: {
          formatter: (value) => {
            return `Year ${value}`;
          },
        },
        y: {
          formatter: (value) => {
            return "$" + parseFloat(value).toLocaleString("en-US");
          },
        },
      },
    },
  });

  return (
    <div className="chart">
      <ReactApexChart
        series={state.series}
        options={state.options}
        type="area"
        height="100%"
      />
    </div>
  );
};

export default DashboardChart;
