/* ICONS */
import { FaArrowTrendUp, FaPercent, FaArrowRightLong } from "react-icons/fa6";
import { IoStatsChart } from "react-icons/io5";

const sidebarNav = [
  {
    id: 1,
    link: "/dashboard",
    name: "Dashboard",
    icon: FaArrowTrendUp,
  },
  {
    id: 2,
    link: "/selection",
    name: "Selection",
    icon: FaPercent,
  },
  {
    id: 3,
    link: "/automate",
    name: "Automations",
    icon: IoStatsChart,
  },
  {
    id: 4,
    link: "/trade",
    name: "Trade",
    icon: FaArrowRightLong,
  },
];

export default sidebarNav;
