/* PACKAGES */
import { motion } from "framer-motion";

/* ANIMATION */
import { animation, containerAnimation } from "config/animation";

const CookiesPolicy = () => {
  return (
    <motion.section
      variants={containerAnimation}
      initial="hidden"
      animate="visible"
      className="bg-aboutBanner"
    >
      <motion.div variants={animation} className="py-6 xl:py-12 lg:py-12">
        <div className="container mx-auto">
          <div className="p-0">
            <h1 className="text-3xl xl:text-4xl lg:text-4xl font-redhat-bold text-white text-center">
              Cookies Policy
            </h1>
            <h2 className="text-2xl xl:text-3xl lg:text-3xl px-5 xl:px-0 lg:px-0 font-redhat-regular text-gradient text-center">
              We take your security, data, and privacy seriously
            </h2>
          </div>
          <div className="mt-5 xl:mt-10 lg:mt-10">
            <div className="bg-white rounded-xl p-5 xl:p-10 lg:p-10">
              <div className="mt-0">
                <p className="text-md font-redhat-regular text-greyText">
                  This Cookies Policy explains what Cookies are and how we use
                  them.
                </p>
                <p className="mt-3 text-md font-redhat-regular text-greyText">
                  You should read this policy so you can understand what type of
                  cookies we use, or the information we collect using Cookies
                  and how that information is used.
                </p>
                <p className="mt-3 text-md font-redhat-regular text-greyText">
                  Cookies do not typically contain any information that
                  personally identifies a user, but personal information that we
                  store about You may be linked to the information stored in and
                  obtained from Cookies. For further information on how we use,
                  store and keep your personal data secure, see our Privacy
                  Policy.
                </p>
                <p className="mt-3 text-md font-redhat-regular text-greyText">
                  We do not store sensitive personal information, such as
                  mailing addresses, account passwords, etc. in the Cookies we
                  use.
                </p>
              </div>
              <div className="mt-10">
                <h5 className="text-xl font-redhat-bold text-black">
                  Interpretation and Definitions
                </h5>
                <div className="mt-5">
                  <p className="text-md font-redhat-regular text-greyText">
                    The words of which the initial letter is capitalized have
                    meanings defined under the following conditions. The
                    following definitions shall have the same meaning regardless
                    of whether they appear in singular or in plural.
                  </p>
                  <p className="mt-3 text-md font-redhat-semibold text-black">
                    For the purposes of this Cookies Policy:
                  </p>
                  <ul className="ml-8 mt-3 list-decimal">
                    <li className="mt-2 text-md font-redhat-regular text-greyText">
                      Company (referred to as either "the Company", "We", "Us"
                      or "Our" in this Cookies Policy) refers to ScaleTrade, 501
                      Wyoming Drive.
                    </li>
                    <li className="mt-2 text-md font-redhat-regular text-greyText">
                      Cookies means small files that are placed on Your
                      computer, mobile device or any other device by a website,
                      containing details of your browsing history on that
                      website among its many uses.
                    </li>
                    <li className="mt-2 text-md font-redhat-regular text-greyText">
                      Website refers to ScaleTrade, accessible from{" "}
                      <a
                        href="https://scaletrade.ai/"
                        target="_blank"
                        rel="noreferrer"
                        style={{ color: "#219653", fontWeight: "600" }}
                      >
                        https://scaletrade.ai/
                      </a>
                    </li>
                    <li className="mt-2 text-md font-redhat-regular text-greyText">
                      You means the individual accessing or using the Website,
                      or a company, or any legal entity on behalf of which such
                      individual is accessing or using the Website, as
                      applicable.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="mt-10">
                <h5 className="text-xl font-redhat-bold text-black">
                  The use of the Cookies
                </h5>
                <div className="mt-5">
                  <p className="text-md font-redhat-regular text-greyText">
                    Cookies can be "Persistent" or "Session" Cookies. Persistent
                    Cookies remain on your personal computer or mobile device
                    when You go offline, while Session Cookies are deleted as
                    soon as You close your web browser.
                  </p>
                  <p className="mt-3 text-md font-redhat-regular text-greyText">
                    We use both session and persistent Cookies for the purposes
                    set out below:
                  </p>
                  <ul className="ml-8 mt-3 list-decimal">
                    <li className="mt-2 text-md font-redhat-regular text-greyText">
                      Necessary / Essential Cookies
                    </li>
                    <li className="mt-2 text-md font-redhat-regular text-greyText">
                      Type: Session Cookies
                    </li>
                    <li className="mt-2 text-md font-redhat-regular text-greyText">
                      Administered by: Us
                    </li>
                    <li className="mt-2 text-md font-redhat-regular text-greyText">
                      Purpose: These Cookies are essential to provide You with
                      services available through the Website and to enable You
                      to use some of its features. They help to authenticate
                      users and prevent fraudulent use of user accounts. Without
                      these Cookies, the services that You have asked for cannot
                      be provided, and We only use these Cookies to provide You
                      with those services.
                    </li>
                    <li className="mt-2 text-md font-redhat-regular text-greyText">
                      Functionality Cookies
                    </li>
                    <li className="mt-2 text-md font-redhat-regular text-greyText">
                      Type: Persistent Cookies
                    </li>
                    <li className="mt-2 text-md font-redhat-regular text-greyText">
                      Administered by: Us
                    </li>
                    <li className="mt-2 text-md font-redhat-regular text-greyText">
                      Purpose: These Cookies allow us to remember choices You
                      make when You use the Website, such as remembering your
                      login details or language preference. The purpose of these
                      Cookies is to provide You with a more personal experience
                      and to avoid You having to re-enter your preferences every
                      time You use the Website.
                    </li>
                    <li className="mt-2 text-md font-redhat-regular text-greyText">
                      Tracking and Performance Cookies
                    </li>
                    <li className="mt-2 text-md font-redhat-regular text-greyText">
                      Type: Persistent Cookies
                    </li>
                    <li className="mt-2 text-md font-redhat-regular text-greyText">
                      Administered by: Third-Parties
                    </li>
                    <li className="mt-2 text-md font-redhat-regular text-greyText">
                      Purpose: These Cookies are used to track information about
                      traffic to the Website and how users use the Website. The
                      information gathered via these Cookies may directly or
                      indirectly identify you as an individual visitor. This is
                      because the information collected is typically linked to a
                      pseudonymous identifier associated with the device you use
                      to access the Website. We may also use these Cookies to
                      test new advertisements, pages, features or new
                      functionality of the Website to see how our users react to
                      them.
                    </li>
                    <li className="mt-2 text-md font-redhat-regular text-greyText">
                      Targeting and Advertising Cookies
                    </li>
                    <li className="mt-2 text-md font-redhat-regular text-greyText">
                      Type: Persistent Cookies
                    </li>
                    <li className="mt-2 text-md font-redhat-regular text-greyText">
                      Administered by: Third-Parties
                    </li>
                    <li className="mt-2 text-md font-redhat-regular text-greyText">
                      Purpose: These Cookies track your browsing habits to
                      enable Us to show advertising which is more likely to be
                      of interest to You. These Cookies use information about
                      your browsing history to group You with other users who
                      have similar interests. Based on that information, and
                      with Our permission, third party advertisers can place
                      Cookies to enable them to show adverts which We think will
                      be relevant to your interests while You are on third party
                      websites.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="mt-10">
                <h5 className="text-xl font-redhat-bold text-black">
                  Your Choices Regarding Cookies
                </h5>
                <div className="mt-5">
                  <p className="text-md font-redhat-regular text-greyText">
                    If You prefer to avoid the use of Cookies on the Website,
                    first You must disable the use of Cookies in your browser
                    and then delete the Cookies saved in your browser associated
                    with this website. You may use this option for preventing
                    the use of Cookies at any time. If You do not accept Our
                    Cookies, You may experience some inconvenience in your use
                    of the Website and some features may not function properly.
                  </p>
                  <p className="mt-3 text-md font-redhat-regular text-greyText">
                    If You'd like to delete Cookies or instruct your web browser
                    to delete or refuse Cookies, please visit the help pages of
                    your web browser.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </motion.section>
  );
};

export default CookiesPolicy;
