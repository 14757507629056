import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

const DonutChart = (props) => {
  const [state, setState] = useState({
    series: [100, 0, 0],
    options: {
      chart: {
        type: "donut",
        height: "100%",
        fontFamily: "Roboto-Medium, sans-serif",
        toolbar: {
          show: false,
        },
      },
      legend: {
        show: false,
      },

      dataLabels: {
        enabled: false,
      },
      tooltip: {
        enabled: false,
      },
      colors: ["#ebebeb"],
    },
  });

  useEffect(() => {
    if (props?.graphData) {
      setState({
        series: props?.graphData,
        options: {
          chart: {
            type: "donut",
            height: "100%",
            fontFamily: "Roboto-Medium, sans-serif",
            toolbar: {
              show: false,
            },
          },
          legend: {
            show: false,
          },

          dataLabels: {
            enabled: false,
          },
          tooltip: {
            enabled: false,
          },
          colors: ["#05D15A", "#52B6FF", "#e15f30"],
        },
      });
    }
  }, [props]);

  return (
    <div className="chart donut">
      <ReactApexChart
        series={state.series}
        options={state.options}
        type="donut"
        height="100%"
      />
    </div>
  );
};

export default DonutChart;
