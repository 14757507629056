// components/CustomTooltip.js
import { useState } from 'react';

const CustomTooltip = ({ text, children }) => {
  const [visible, setVisible] = useState(false);

  return (
    <div
      className="relative inline-block"
      onMouseEnter={() => setVisible(true)}
      onMouseLeave={() => setVisible(false)}
    >
      {children}
      {visible && (
        <div className="absolute bottom-full mb-2 px-2 py-1 text-sm text-white bg-black rounded-md whitespace-nowrap mt-10">
          {text}
          <div className="absolute left-1/2 transform -translate-x-1/2 bottom-[-5px] w-0 h-0 border-l-5 border-l-transparent border-r-5 border-r-transparent border-t-5 border-t-black"></div>
        </div>
      )}
    </div>
  );
};

export default CustomTooltip;
