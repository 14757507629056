/* eslint-disable no-unused-vars */
import { useEffect, useState, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";

/* MATERIAL TAILWIND COMPONENTS */
import { Button, Checkbox, Dialog, DialogBody } from "@material-tailwind/react";

/* CUSTOM COMPONENTS */
import LoginFormComponent from "../forms/login";
import RegisterFormComponent from "../forms/register";
import BrokerComponent from "../forms/broker";
import VerificationComponent from "../forms/verification";
import OnBoardingForm from "../forms/onBoardingForm";

/* ICONS */
import { IoClose } from "react-icons/io5";
import ChangePasswordFormComponent from "components/forms/changePassword";

const AccountCreation = (props) => {
  /* GLOBAL STATES */
  const navigate = useNavigate();

  useEffect(() => {
    // currentTab
    // currentState
    // setCurrentView(props?.currentState)
    // setAccountCreation(props?.handleState)
  }, [props]);

  return (
    <>
      {/* ACCOUNT CREATION */}
      <Dialog
        open={props?.currentState}
        handler={() => {
          props?.setCurrentState(!props?.currentState);
        }}
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
        size={props?.currentTab === "onBoarding" ? "sm" : "xs"}
        dismiss={{
          enabled: false,
        }}
        className="min-w-[90%] xl:min-w-max lg:min-w-max"
      >
        <DialogBody className="p-0 relative">
          <div className="py-5 border-b border-inputBox">
            <img
              src={require("../../assets/images/scaletrade-logo-black.png")}
              className="h-10 w-auto mx-auto"
              alt="ScaleTrade"
            />
          </div>

          {props?.currentTab === "login" && (
            <LoginFormComponent
              accountCreation={props?.currentState}
              setAccountCreation={props?.setCurrentState}
              currentView={props?.currentTab}
              setCurrentView={props?.setCurrentTab}
            />
          )}
          {props?.currentTab === "register" && (
            <RegisterFormComponent
              accountCreation={props?.currentState}
              setAccountCreation={props?.setCurrentState}
              currentView={props?.currentTab}
              setCurrentView={props?.setCurrentTab}
            />
          )}
          {props?.currentTab === "changePassword" && (
            <ChangePasswordFormComponent
              accountCreation={props?.currentState}
              setAccountCreation={props?.setCurrentState}
              currentView={props?.currentTab}
              setCurrentView={props?.setCurrentTab}
            />
          )}
          {props?.currentTab === "verify" && (
            <VerificationComponent
              accountCreation={props?.currentState}
              setAccountCreation={props?.setCurrentState}
              currentView={props?.currentTab}
              setCurrentView={props?.setCurrentTab}
            />
          )}
          {props?.currentTab === "broker" && (
            <BrokerComponent
              accountCreation={props?.currentState}
              setAccountCreation={props?.setCurrentState}
              currentView={props?.currentTab}
              setCurrentView={props?.setCurrentTab}
            />
          )}
          {props?.currentTab === "onBoarding" && (
            <OnBoardingForm
              accountCreation={props?.currentState}
              setAccountCreation={props?.setCurrentState}
              currentView={props?.currentTab}
              setCurrentView={props?.setCurrentTab}
            />
          )}

          <Button
            className="!absolute top-2 right-2 shadow-none hover:shadow-none bg-white rounded-full hover:bg-black hover:bg-opacity-10 p-2 transition-all duration-300 ease-in-out"
            onClick={() => {
              navigate("/");
              props?.setCurrentTab("register");
              props?.setCurrentState(!props?.currentState);
            }}
          >
            <IoClose className="w-4 h-4 text-greyText" />
          </Button>
        </DialogBody>
      </Dialog>
    </>
  );
};

export default AccountCreation;
