/* eslint-disable no-unreachable */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */

/* IMPORT REACT  */
import React, { useState } from "react";

/* IMPORT ROUTER DOM FOR LINK */
import { Link, useNavigate } from "react-router-dom";

/* MATERIAL TAILWIND COMPONENTS */
import { Button } from "@material-tailwind/react";

/* IMPORT react-toastify FOR TOAST NOTIFICATION  */
import { toast } from "react-toastify";

/* IMPORT PASSWORD CHECKLIST PACKAGE */
import PasswordChecklist from "react-password-checklist";

/* REDUX IMPORTS */
import { useDispatch, useSelector } from "react-redux";
import userAction from "../../redux/actions/user.action";

/* ICONS */
import { IoEye, IoEyeOff, IoClose } from "react-icons/io5";

/* API IMPORTS */
import {
  addUserAccountApi,
  authRegister,
} from "../../service/api";

/* IMPORT FORMIK FOR FORM-SUBMIT */
import { useFormik } from "formik";
import * as yup from "yup";

/* VALIDATION SCHEMA FOR LOGIN FORM */
const registerFormValidationSchema = yup.object().shape({
  email: yup.string().email().required("Email is required."),
  password: yup
    .string()
    .required("Password is required.")
    .min(8, "Password should be at least 8 characters long"),
});

/* DIALOG INPUT */
const DialogInputBox = (props) => {
  return (
    <input
      type={props.type}
      name={props.name}
      id={props.id}
      value={props.value}
      onChange={props.onChange}
      onFocus={props.onFocus}
      placeholder={props.placeholder}
      className={
        props?.error
          ? "w-full block bg-white border border-red-600 rounded-md px-5 py-3 outline-none focus:border-logoGreen focus:shadow-md text-base font-redhat-semibold text-black transition-all duration-300 ease-in-out"
          : "w-full block bg-white border border-inputBox rounded-md px-5 py-3 outline-none focus:border-logoGreen focus:shadow-md text-base font-redhat-semibold text-black transition-all duration-300 ease-in-out"
      }
    />
  );
};

const RegisterFormComponent = (props) => {
  /* INITIALIZE FUNCTIONS */
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user);

  /* INITIALIZE STATE VARIABLES */
  const [loading, setLoading] = useState(false);
  
  const [showPassword, setShowPassword] = useState(false);

  const [passwordCheck, setPasswordCheck] = useState(true); // Check Password
  const [passwordCheckDisplay, setPasswordCheckDisplay] = useState(false); // Check Password
  

  /* HANDLE LOGIN FORM USING FORMIK */
  const registrationFormikFunction = useFormik({
    /* SET INITIAL VALUES FOR LOGIN FORM */
    initialValues: {
      email: "",
      password: "",
    },
    /* VALIDATION SCHEMA  */
    validationSchema: registerFormValidationSchema,
    /* HANDLE FORM SUBMIT */
    onSubmit: (values) => {
      /* CHECK PASSWORD  */
      if (passwordCheck) {
        /* SET LOADING AS TRUE */
        setLoading(true);
        /* CALL LOGIN API */
        authRegister(values)
          .then((res) => {
            /* CHECK RESPONSE STATUS */
            if (res?.status) {
              /* SET USER DATA TO GLOBAL STATE */
              dispatch(userAction.clearData())
              dispatch(userAction.setToken(res?.data?.token));
              dispatch(userAction.setUser(res?.data?.userData));
              dispatch(userAction.setUserId(res?.data?.userData?._id));
              dispatch(userAction.setLoggedIn(false));
              addUserAccountApi(res?.data?.userData?._id)
                .then((response) => { })
                .catch((e) => {
                  console.log("Error: ", e);
                });

              /* SUCCESS NOTIFICATION */
              toast.success(res?.message);
              /* SHOW OTP VERIFY TAB */
              props.setCurrentView("verify")
              dispatch(userAction.setLoggedIn(false));
            } else {
              /* ERROR NOTIFICATION */
              toast.error(res?.message);
            }
            setLoading(false);
          })
          .catch((e) => {
            setLoading(false);
            toast.error(e?.response?.data?.message);
          })
          .finally((res) => {
            setLoading(false);
          });
      } else {
        toast.error("Please check Password");
      }
    },
  });

  return (
    <div className="py-5 px-8">
      <h5 className="text-xl font-redhat-extrabold text-black">
        Create your free account
      </h5>
      <p className="mt-2 text-base font-redhat-regular text-[#494949]">
        Let's get you on your way to stress-free trading with fully automated
        trading strategies that you control.
      </p>
      <form className="mt-5" onSubmit={registrationFormikFunction.handleSubmit}>
        <div className="form-group">
          <DialogInputBox
            type="email"
            name="email"
            id="email"
            placeholder="Email address"
            value={registrationFormikFunction.values.email}
            onChange={registrationFormikFunction.handleChange}
            error={
              registrationFormikFunction.errors.email &&
                registrationFormikFunction.touched.email
                ? true
                : false
            }
          />
        </div>
        <div className="form-group mt-5">
          <div className="relative">
            <DialogInputBox
              type={showPassword ? "text" : "password"}
              name="password"
              id="password"
              placeholder="Password"
              onFocus={() => {
                setPasswordCheckDisplay(true);
              }}
              value={registrationFormikFunction.values.password}
              onChange={registrationFormikFunction.handleChange}
              error={
                registrationFormikFunction.errors.password &&
                  registrationFormikFunction.touched.password
                  ? true
                  : false
              }
            />

            <Button
              className="!absolute top-2 right-2 shadow-none hover:shadow-none p-2 rounded-full bg-transparent hover:bg-black hover:bg-opacity-5 transition-all duration-300 ease-in-out"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? (
                <IoEyeOff className="w-5 h-5 text-greyText" />
              ) : (
                <IoEye className="w-5 h-5 text-greyText" />
              )}
            </Button>
          </div>
        </div>
        {!passwordCheck &&
          registrationFormikFunction.touched.password &&
          !registrationFormikFunction.errors.password && (
            <small style={{ color: "red" }}>Please check Password</small>
          )}
        <div className="mt-2">
          {passwordCheckDisplay && (
            <PasswordChecklist
              rules={[
                "minLength",
                "lowercase",
                "capital",
                "number",
                "specialChar",
              ]}
              iconSize={12}
              minLength={8}
              value={registrationFormikFunction.values.password}
              messages={{
                minLength: "At least 8 characters long",
                lowercase: "One lowercase character",
                capital: "One uppercase character",
                number: "One number character",
                specialChar: "One special character",
              }}
              onChange={(e) => {
                setPasswordCheck(e);
              }}
              className="password-checklist font-redhat-semibold text-black"
            />
          )}
        </div>
        <Button
          type="submit"
          className="mt-5 w-full bg-gradient font-redhat-semibold text-white text-base normal-case"
          // onClick={() => setCurrentView("broker")}
          disabled={loading}
        >
          {loading ? "Please wait.." : "Create my account"}
        </Button>
      </form>
      <div className="mt-5">
        <p className="text-base font-redhat-semibold text-greyText text-center">
          Already have an account?{" "}
          <span
            className="underline cursor-pointer"
            onClick={() =>
              // (window.location.href = "/calculator?pageType=login")
              props.setCurrentView("login")
            }
          >
            Sign in
          </span>
        </p>
      </div>
    </div>
  );
};
export default RegisterFormComponent;
