/* CREATE ACTIONS FOR USER REDUCER */
const actions = {
  ADD_NOTIFICATION: "ADD_NOTIFICATION",
  MARK_AS_READ: "MARK_AS_READ",

  addNotification: (data) => {
    return { type: actions.ADD_NOTIFICATION, data };
  },
  markAsRead: () => {
    return { type: actions.MARK_AS_READ };
  },
};

export default actions;
