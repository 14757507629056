/* CREATE ACTIONS FOR USER REDUCER */
const actions = {
	SET_USER: "SET_USER",
	SET_TOKEN: "SET_TOKEN",
	SET_LOGGED_IN: "SET_LOGGED_IN",
	SET_LOADING: "SET_LOADING",
	SET_TERMS_AND_CONDITIONS: "SET_TERMS_AND_CONDITIONS",
	SET_ALL_ACCOUNTS: "SET_ALL_ACCOUNTS",
	SET_ALL_CONNECTED_ACCOUNTS: "SET_ALL_CONNECTED_ACCOUNTS",
	SET_SELECTED_ACCOUNT: "SET_SELECTED_ACCOUNT",
	SET_USER_ID: "SET_USER_ID",
	SET_ACCOUNT_ID: "SET_ACCOUNT_ID",
	SWITCH: "SWITCH",
	SET_FILLED_ORDER: "SET_FILLED_ORDER",
	SET_OPEN_ORDER: "SET_OPEN_ORDER",
	SET_CALL_API_STATUS: "SET_CALL_API_STATUS",
	LOGOUT: "LOGOUT",
	clearData: (data) => {
		return { type: actions.LOGOUT, data };
	},
	setUser: (data) => {
		return { type: actions.SET_USER, data };
	},
	setToken: (data) => {
		return { type: actions.SET_TOKEN, data };
	},
	setLoggedIn: (data) => {
		return { type: actions.SET_LOGGED_IN, data };
	},
	setLoading: (data) => {
		return { type: actions.SET_LOADING, data };
	},
	setTermsAndConditions: (data) => {
		return { type: actions.SET_TERMS_AND_CONDITIONS, data };
	},
	setAllAccounts: (data) => {
		return { type: actions.SET_ALL_ACCOUNTS, data };
	},
	setAllConnectedAccounts: (data) => {
		return { type: actions.SET_ALL_CONNECTED_ACCOUNTS, data };
	},
	setSelectedAccount: (data) => {
		return { type: actions.SET_SELECTED_ACCOUNT, data };
	},
	setUserId: (data) => {
		return { type: actions.SET_USER_ID, data };
	},
	setAccountId: (data) => {
		return { type: actions.SET_ACCOUNT_ID, data };
	},
	setFilledOrdersList: (data) => {
		return { type: actions.SET_FILLED_ORDER, data };
	},
	setOpenOrdersList: (data) => {
		return { type: actions.SET_OPEN_ORDER, data };
	},
	setApiStatus: (data) => {
		return { type: actions.SET_CALL_API_STATUS, data };
	},
	setSwitch: (data) => {
		return { type: actions.SWITCH, data };
	}
};

export default actions;
