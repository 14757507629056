/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";

/* PACKAGES */
import { Helmet } from "react-helmet";

/* USER DEFINED COMPONENTS */
import { DropdownStockTile, StockTile } from "components/trade/stock-tile";

/* DATA */
import {
  getActiveAllApi,
  getActivePopularApi,
  getAccountAllDetailsApi,
  getAccountDetailsApi,
  getAccountListApi,
} from "service/api";
import { useDispatch, useSelector } from "react-redux";

import userAction from "redux/actions/user.action";

/* ASSETS */
import Alpaca from "assets/images/logos/alpaca.png";
import Robinhood from "assets/images/logos/robinhood.png";
import Tradier from "assets/images/logos/tradier.png";
import TradeStation from "assets/images/logos/trade-station.png";
import Webull from "assets/images/logos/webull.png";
import HelperGetData from "components/others/helpers";
import TradeHistory from "components/others/tradeHistory";

/* CLICK OUTSIDE HANDLER */
const useClickOutside = (handler) => {
  let domNode = useRef();

  useEffect(() => {
    let maybeHandler = (event) => {
      if (!domNode.current?.contains(event.target)) {
        handler();
      }
    };

    document.addEventListener("mousedown", maybeHandler);
    return () => {
      document.removeEventListener("mousedown", maybeHandler);
    };
  });

  return domNode;
};

const TradePage = () => {
  const dispatch = useDispatch();
  const accountsList = useSelector((state) => state?.user?.allAccounts);
  const selectedAccountDetails = useSelector(
    (state) => state?.user?.selectedAccount
  );
  const allConnectedAccountsList = useSelector(
    (state) => state?.user?.allConnectedAccounts
  );
  const accountId = useSelector((state) => state?.user?.accountId);
  const userId = useSelector((state) => state?.user?.userId);

  const open = useSelector((state) => state?.user?.openOrder);
  const filled = useSelector((state) => state?.user?.filledOrder);

  /* STATE VARIABLES */
  const [loadingDetails, setLoadingDetails] = useState(false);
  const [stocksList, setStocksList] = useState(false);
  const [stocks, setStocks] = useState([]);
  const [searchFilter, setSearchFilter] = useState([]);

  const [openOrdersViewMore, setOpenOrdersViewMore] = useState(false);
  const [filledOrdersViewMore, setFilledOrdersViewMore] = useState(false);

  /* FILLED AND OPEN ORDERS STATE */
  // const [filled, setFilled] = useState([]);
  // const [open, setOpen] = useState([]);

  /* CLOSE DROPDOWN WHEN CLICKED OUTSIDE */
  let domNode = useClickOutside(() => {
    setStocksList(false);
  });

  /* NO ORDERS */
  const NoOpenOrders = (props) => {
    return (
      <div className="w-full bg-white border border-inputBox rounded-md p-5">
        <p className="text-base font-redhat-semibold text-greyText text-center">
          {props.filled
            ? "There are no orders to be filled"
            : "You have no open orders"}
        </p>
      </div>
    );
  };

  /* STOCKS STATE */
  const [popularStocks, setPopularStocks] = useState([]);
  const [etfStocks, setEtfStocks] = useState([]);
  const [companyList, setCompanyList] = useState([]);

  /* USE EFFECT */
  useEffect(() => {
    const fetchData = async () => {
      const { csStocks, etfStocks } = await fetchAndProcessStockData();
      setPopularStocks(csStocks);
      setEtfStocks(etfStocks);
    };

    fetchData();
  }, []);

  /* FETCH STOCK DATA */
  const fetchAndProcessStockData = async () => {
    try {
      setLoadingDetails(true);
      const res = await getActivePopularApi();

      if (res.status_code === 200 && res.data && res.data.securities) {
        const securities = res.data.securities;

        const tempArray = [];
        securities.forEach((element, index) => {
          tempArray.push({
            id: index + 1,
            name: element.company,
            symbol: element?.underlying,
            price: element.price,
            percentage: element.percent_change,
          });
        });

        setStocks(tempArray);

        // Separate companies based on security_type and map them to the required structure
        const { csStocks, etfStocks } = securities.reduce(
          (acc, security) => {
            const stockData = {
              id: security.underlying,
              name: security.company,
              image: "", // Provide appropriate image or data as needed
              percentage: security.percent_change,
              price: security.price, // Provide appropriate price or data as needed
              symbol: security.underlying,
            };

            if (security.security_type === "CS") {
              acc.csStocks.push(stockData);
            } else if (
              security.security_type === "ETF" ||
              security.security_type === "ETV"
            ) {
              acc.etfStocks.push(stockData);
            }
            return acc;
          },
          { csStocks: [], etfStocks: [] }
        );
        setLoadingDetails(false);
        return {
          csStocks: csStocks.slice(0, 6),
          etfStocks: etfStocks.slice(0, 6),
        };
      } else {
        console.error("Unexpected response structure", res);
        return { csStocks: [], etfStocks: [] };
      }
    } catch (e) {
      console.log("Error: ", e);
      return { csStocks: [], etfStocks: [] };
    }
  };

  const getAccountsList = () => {
    getActiveAllApi().then((res) => {
      let tempArray = [];
      res?.data?.securities?.forEach((element) => {
        tempArray.push({
          symbol: element?.ticker,
          type: element?.type,
          name: element?.name,
        });
      });
      setCompanyList(tempArray?.sort((a, b) => b?.symbol - a?.symbol));
    });
  };

  useEffect(() => {
    dispatch(userAction.setApiStatus(true));
    getAccountsList();
  }, []);

  return (
    <section className="px-3 xl:px-10 lg:px-10 py-3 xl:py-7 lg:py-7">
      {/* PAGE TITLE */}
      <Helmet>
        <title>Trade | ScaleTrade</title>
      </Helmet>
      <HelperGetData />
      <div className="container mx-auto">
        {/* CONTENT */}
        <h1 className="text-2xl xl:text-4xl lg:text-4xl font-roboto-semibold text-black leading-tight">
          Make your trades from one place
        </h1>

        <div className="w-full overflow-auto overflow-x-hidden overflow-y-auto hide-scrollbar mx-auto">
          {/* TRADES SELECTION */}
          <div className="w-full mx-auto mt-3">
            <div className="form-group">
              <div className="relative">
                <input
                  ref={domNode}
                  type="text"
                  name="tradeSelection"
                  id="tradeSelection"
                  placeholder="Search.."
                  className="w-full bg-white border border-inputBox rounded-md px-4 py-3 focus:outline-logoGreen text-sm font-redhat-semibold text-black placeholder:font-redhat-semibold"
                  onClick={() => setStocksList(!stocksList)}
                  onChange={(e) => setSearchFilter(e?.target?.value)}
                />

                {/* STOCKS LIST */}
                {stocksList && (
                  <div
                    ref={domNode}
                    className="absolute top-14 left-0 w-full h-full transition-all duration-300 ease-in-out"
                  >
                    <div className="bg-white shadow rounded-md border border-inputBox max-h-[50vh] overflow-auto overflow-y-auto overflow-x-hidden hide-scrollbar">
                      {companyList
                        .filter(
                          (element) =>
                            new RegExp(searchFilter, "i").test(
                              element?.symbol
                            ) ||
                            new RegExp(searchFilter, "i").test(element?.name)
                        )
                        .map((data, index) => (
                          <React.Fragment key={index}>
                            <DropdownStockTile
                              image={data.image}
                              name={data.name}
                              percentage={data.percentage}
                              price={data.price}
                              symbol={data.symbol}
                              position={false}
                            />
                          </React.Fragment>
                        ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* POPULAR STOCKS AND POPULAR ETF'S */}
          <div className="w-full mt-5 xl:mt-8 lg:mt-8 mx-auto">
            <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-5">
              <div className="col-span-1">
                <h5 className="text-base font-redhat-bold text-black">
                  Most Popular Stocks
                </h5>
                <div className="mt-3">
                  {loadingDetails ? (
                    <div className="w-full bg-white border border-inputBox rounded-md p-5">
                      <p className="text-base font-redhat-semibold text-greyText text-center">
                        Please wait...
                      </p>
                    </div>
                  ) : (
                    popularStocks.map((data) => (
                      <div key={data.id} className="mt-2 first:mt-0">
                        <StockTile
                          image={data.image}
                          name={data.name}
                          percentage={data.percentage}
                          price={data.price}
                          symbol={data.symbol}
                        />
                      </div>
                    ))
                  )}
                </div>
              </div>
              <div className="col-span-1">
                <h5 className="text-base font-redhat-bold text-black">
                  Most Popular ETFs
                </h5>

                <div className="mt-3">
                  {loadingDetails ? (
                    <div className="w-full bg-white border border-inputBox rounded-md p-5">
                      <p className="text-base font-redhat-semibold text-greyText text-center">
                        Please wait...
                      </p>
                    </div>
                  ) : (
                    etfStocks.map((data) => (
                      <div key={data.id} className="mt-2 first:mt-0">
                        <StockTile
                          image={data.image}
                          name={data.name}
                          percentage={data.percentage}
                          price={data.price}
                          symbol={data.symbol}
                        />
                      </div>
                    ))
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* ACCOUNT ORDERS */}
          <TradeHistory />
        </div>
      </div>
    </section>
  );
};

export default TradePage;
