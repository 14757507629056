/* LAYOUT COMPONENTS */
import Header from "./header";
const Layout = ({ children }) => {
  return (
    <section className="bg-white overflow-x-hidden">
      <div className="container-full-width">
        <div className="w-full">
          <Header />
        </div>
        <div className="flex items-start">
          <div className="w-full h-auto xl:h-body lg:h-body overflow-auto overflow-x-hidden overflow-y-auto hide-scrollbar">
            <div className="w-full bg-white h-full">{children}</div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Layout;
