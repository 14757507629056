/* eslint-disable no-unreachable */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */

/* IMPORT REACT  */
import React, { useState, useEffect, useCallback } from "react";

/* IMPORT OTP INPUT PACKAGE */
import OtpInput from "react-otp-input";

/* IMPORT ROUTER DOM FOR LINK */
import { Link, useNavigate } from "react-router-dom";

/* MATERIAL TAILWIND COMPONENTS */
import { Button } from "@material-tailwind/react";

/* IMPORT react-toastify FOR TOAST NOTIFICATION  */
import { toast } from "react-toastify";

/* IMPORT REACT COOKIE  */
import { useCookies } from "react-cookie";

/* REDUX IMPORTS */
import { useDispatch, useSelector } from "react-redux";
import userAction from "../../redux/actions/user.action";

/* ICONS */
import { IoEye, IoEyeOff, IoClose } from "react-icons/io5";

/* API IMPORTS */
import {
  authLogin,
  authRegisterEmailResend,
  authRegisterEmailVerify,
  postSetUser,
} from "../../service/api";

/* IMPORT FORMIK FOR FORM-SUBMIT */
import { useFormik } from "formik";
import * as yup from "yup";

/* VALIDATION SCHEMA FOR LOGIN FORM */
const loginFormValidationSchema = yup.object().shape({
  email: yup.string().email().required("Email is required."),
  password: yup.string().required("Password is required."),
});

/* VALIDATION SCHEMA FOR VERIFY OTP FORM */
const verifyEmailFormValidationSchema = yup.object().shape({
  otp: yup
    .string()
    .required("OTP is required.")
    .min(6, "Must be exactly 6 digits")
    .max(6, "Must be exactly 6 digits"),
});

/* DIALOG INPUT */
const DialogInputBox = (props) => {
  return (
    <input
      type={props.type}
      name={props.name}
      id={props.id}
      value={props.value}
      onChange={props.onChange}
      placeholder={props.placeholder}
      className={
        props?.error
          ? "w-full block bg-white border border-red-600 rounded-md px-5 py-3 outline-none focus:border-logoGreen focus:shadow-md text-base font-redhat-semibold text-black transition-all duration-300 ease-in-out"
          : "w-full block bg-white border border-inputBox rounded-md px-5 py-3 outline-none focus:border-logoGreen focus:shadow-md text-base font-redhat-semibold text-black transition-all duration-300 ease-in-out"
      }
    />
  );
};

const LoginFormComponent = (props) => {
  /* INITIALIZE FUNCTIONS */
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [cookies, setCookie] = useCookies(["user"]);
  const user = useSelector((state) => state?.user);

  /* INITIALIZE STATE VARIABLES */
  const [loading, setLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState("login"); // Available State Variables  [login,verifyOTP]
  const [rememberMeOption, setRememberMeOption] = useState(false); // Remember Me Option

  const [showPassword, setShowPassword] = useState(false);
  const [currentView, setCurrentView] = useState("register");

  const [timer, setTimer] = useState(0);
  const timeOutCallback = useCallback(
    () => setTimer((currTimer) => currTimer - 1),
    []
  );

  /* USE-EFFECT TIMER */
  useEffect(() => {
    timer > 0 && setTimeout(timeOutCallback, 1000);
  }, [timer, timeOutCallback]);

  const handleCookieChange = (email, password) => {
    setCookie("email", email, { path: "/" });
    setCookie("password", password, { path: "/" });
  };

  /* HANDLE LOGIN FORM USING FORMIK */
  const loginFormikFunction = useFormik({
    /* SET INITIAL VALUES FOR LOGIN FORM */
    initialValues: {
      email: cookies?.email ? cookies?.email : "",
      password: cookies?.password ? `${cookies?.password}` : "",
    },
    /* VALIDATION SCHEMA  */
    validationSchema: loginFormValidationSchema,
    /* HANDLE FORM SUBMIT */
    onSubmit: (values) => {
      /* SET LOADING AS TRUE */
      setLoading(true);
      /* CALL LOGIN API */
      authLogin(values)
        .then((res) => {
          /* CHECK RESPONSE STATUS */
          if (res?.status) {
            if (rememberMeOption) {
              handleCookieChange(values?.email, values?.password);
            }

            /* SET USER DATA TO GLOBAL STATE */
            dispatch(userAction.setToken(res?.data?.token));
            dispatch(userAction.setUser(res?.data?.userData));
            dispatch(userAction.setUserId(res?.data?.userData?._id));
            // dispatch(
            //   userAction.setAccountId(res?.data?.userData?.connectedAccount)
            // );

            if(!res?.data?.userData?.isEmailVerified){
              props.setCurrentView("verify")
              dispatch(userAction.setLoggedIn(false));
            }else{
              dispatch(userAction.setLoggedIn(true));
              /* SUCCESS NOTIFICATION */
            toast.success(res?.message);
            /* SET USER LOGGED IN STATUS TRUE */
            
            /* SET TIMEOUT FOR 1.5 SECOND THEN NAVIGATE TO DASHBOARD */
            setTimeout(() => {
              if (!res?.data?.userData?.firstName) {
                props.setCurrentView("onBoarding");
              } else {
                navigate("/dashboard"); // navigate to DASHBOARD
              }
            }, 1500);
            }
            
            
          } else {
            /* ERROR NOTIFICATION */
            toast.error(res?.message);
          }
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
          toast.error(e?.response?.data?.message);
        })
        .finally((res) => {
          setLoading(false);
        });
    },
  });

  return (
    <div className="py-5 px-8">
      <h5 className="text-xl font-redhat-extrabold text-black">
        Welcome back!
      </h5>
      <p className="mt-2 text-base font-redhat-regular text-[#494949]">
        Login to your ScaleTrade dashboard.
      </p>
      <form className="mt-5" onSubmit={loginFormikFunction.handleSubmit}>
        <div className="form-group mt-5">
          <DialogInputBox
            type="email"
            name="email"
            id="email"
            placeholder="Email address"
            value={loginFormikFunction.values.email}
            onChange={loginFormikFunction.handleChange}
            error={
              loginFormikFunction.errors.email &&
                loginFormikFunction.touched.email
                ? true
                : false
            }
          />
        </div>
        <div className="form-group mt-5">
          <div className="relative">
            <DialogInputBox
              type={showPassword ? "text" : "password"}
              name="password"
              id="password"
              value={loginFormikFunction.values.password}
              onChange={loginFormikFunction.handleChange}
              placeholder="Password"
              error={
                loginFormikFunction.errors.password &&
                  loginFormikFunction.touched.password
                  ? true
                  : false
              }
            />

            <Button
              className="!absolute top-2 right-2 shadow-none hover:shadow-none p-2 rounded-full bg-transparent hover:bg-black hover:bg-opacity-5 transition-all duration-300 ease-in-out"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? (
                <IoEyeOff className="w-5 h-5 text-greyText" />
              ) : (
                <IoEye className="w-5 h-5 text-greyText" />
              )}
            </Button>
          </div>
        </div>
        <div className="mt-2 flex items-center justify-between">
          <div className="flex">
            <input
              type={"checkbox"}
              name="rememberMeOption"
              id="rememberMeOption"
              checked={rememberMeOption}
              onChange={(e) => {
                setRememberMeOption(e?.target?.checked);
              }}
            />
            <p
              className="cursor-pointer text-base font-redhat-semibold text-greyText ml-2"
            >
              Remember me
            </p>
          </div>
          <p
            className="cursor-pointer text-base font-redhat-semibold text-logoGreen"
            onClick={() => {
              props.setCurrentView("changePassword");
            }}
          >
            Forgot password
          </p>
        </div>
        <Button
          type="submit"
          disabled={loading}
          className="mt-5 w-full bg-gradient font-redhat-semibold text-white text-base normal-case"
        >
          {loading ? "Please wait..." : "Login"}
        </Button>
      </form>
      <div className="mt-5">
        <p className="text-base font-redhat-semibold text-greyText text-center">
          Don't have an account?{" "}
          <span
            className="underline cursor-pointer"
            onClick={() =>
              // (window.location.href = "/calculator?pageType=register")
              props.setCurrentView("register")
            }
          >
            Create one for free
          </span>
        </p>
      </div>
    </div>
  );
};
export default LoginFormComponent;
