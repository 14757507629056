/* PACKAGES */
import { motion } from "framer-motion";

/* ANIMATION */
import { animation, containerAnimation } from "config/animation";

const TermsofService = () => {
  return (
    <motion.section
      variants={containerAnimation}
      initial="hidden"
      animate="visible"
      className="bg-aboutBanner"
    >
      <motion.div variants={animation} className="py-6 xl:py-12 lg:py-12">
        <div className="container mx-auto">
          <div className="p-0">
            <h1 className="text-3xl xl:text-4xl lg:text-4xl font-redhat-bold text-white text-center">
              Terms of Service
            </h1>
            <h2 className="text-2xl xl:text-3xl lg:text-3xl px-5 xl:px-0 lg:px-0 font-redhat-regular text-gradient text-center">
              Our policies and conditions for providing our services
            </h2>
          </div>
          <div className="mt-5 xl:mt-10 lg:mt-10">
            <div className="bg-white rounded-xl p-5 xl:p-10 lg:p-10">
              <h5 className="text-2xl font-redhat-bold text-black">
                Terms and Conditions
              </h5>
              <p className="text-md font-redhat-regular text-greyText mt-2">
                Please read these terms and conditions carefully before using
                Our Service.
              </p>

              <div className="mt-5">
                <h5 className="text-xl font-redhat-bold text-black">
                  Interpretation and Definitions
                </h5>
                <div className="mt-5">
                  <h5 className="text-lg font-redhat-semibold text-black">
                    Interpretation
                  </h5>
                  <p className="mt-2 font-redhat-regular text-greyText">
                    The words of which the initial letter is capitalized have
                    meanings defined under the following conditions. The
                    following definitions shall have the same meaning regardless
                    of whether they appear in singular or in plural.
                  </p>
                </div>
                <div className="mt-5">
                  <h5 className="text-lg font-redhat-semibold text-black">
                    Definitions
                  </h5>
                  <p className="text-md font-redhat-regular text-greyText mt-2">
                    For the purposes of these Terms and Conditions:
                  </p>
                  <ul className="ml-8 list-disc mt-3">
                    <li className="mt-2 font-redhat-regular text-greyText text-md">
                      Affiliate means an entity that controls, is controlled by
                      or is under common control with a party, where "control"
                      means ownership of 50% or more of the shares, equity
                      interest or other securities entitled to vote for election
                      of directors or other managing authority.
                    </li>
                    <li className="mt-2 font-redhat-regular text-greyText text-md">
                      Account means a unique account created for You to access
                      our Service or parts of our Service.
                    </li>
                    <li className="mt-2 font-redhat-regular text-greyText text-md">
                      Country refers to: Texas, United States
                    </li>
                    <li className="mt-2 font-redhat-regular text-greyText text-md">
                      Company (referred to as either "the Company", "We", "Us"
                      or "Our" in this Agreement) refers to ScaleTrade, 501
                      Wyoming Drive.
                    </li>
                    <li className="mt-2 font-redhat-regular text-greyText text-md">
                      Device means any device that can access the Service such
                      as a computer, a cellphone or a digital tablet.
                    </li>
                    <li className="mt-2 font-redhat-regular text-greyText text-md">
                      Free Trial refers to a limited period of time that may be
                      free when purchasing a Subscription.
                    </li>
                    <li className="mt-2 font-redhat-regular text-greyText text-md">
                      Goods refer to the items offered for sale on the Service.
                    </li>
                    <li className="mt-2 font-redhat-regular text-greyText text-md">
                      Orders mean a request by You to purchase Goods from Us.
                    </li>
                    <li className="mt-2 font-redhat-regular text-greyText text-md">
                      Promotions refer to contests, sweepstakes or other
                      promotions offered through the Service.
                    </li>
                    <li className="mt-2 font-redhat-regular text-greyText text-md">
                      Service refers to the Website.
                    </li>
                    <li className="mt-2 font-redhat-regular text-greyText text-md">
                      Subscriptions refer to the services or access to the
                      Service offered on a subscription basis by the Company to
                      You.
                    </li>
                    <li className="mt-2 font-redhat-regular text-greyText text-md">
                      Terms and Conditions (also referred as "Terms") mean these
                      Terms and Conditions that form the entire agreement
                      between You and the Company regarding the use of the
                      Service.
                    </li>
                    <li className="mt-2 font-redhat-regular text-greyText text-md">
                      Third-party Social Media Service means any services or
                      content (including data, information, products or
                      services) provided by a third-party that may be displayed,
                      included or made available by the Service.
                    </li>
                    <li className="mt-2 font-redhat-regular text-greyText text-md">
                      Website refers to ScaleTrade, accessible from
                    </li>
                    <li className="mt-2 font-redhat-regular text-greyText text-md">
                      You means the individual accessing or using the Service,
                      or the company, or other legal entity on behalf of which
                      such individual is accessing or using the Service, as
                      applicable.
                    </li>
                  </ul>
                </div>
              </div>

              <div className="mt-10">
                <h5 className="text-xl font-redhat-bold text-black">
                  Acknowledgement
                </h5>
                <p className="mt-2 font-redhat-regular text-greyText">
                  These are the Terms and Conditions governing the use of this
                  Service and the agreement that operates between You and the
                  Company. These Terms and Conditions set out the rights and
                  obligations of all users regarding the use of the Service.
                </p>
                <p className="mt-2 font-redhat-regular text-greyText">
                  Your access to and use of the Service is conditioned on Your
                  acceptance of and compliance with these Terms and Conditions.
                  These Terms and Conditions apply to all visitors, users and
                  others who access or use the Service.
                </p>
                <p className="mt-2 font-redhat-regular text-greyText">
                  By accessing or using the Service You agree to be bound by
                  these Terms and Conditions. If You disagree with any part of
                  these Terms and Conditions then You may not access the
                  Service.{" "}
                </p>
                <p className="mt-2 font-redhat-regular text-greyText">
                  You represent that you are over the age of 18. The Company
                  does not permit those under 18 to use the Service.
                </p>
                <p className="mt-2 font-redhat-regular text-greyText">
                  Your access to and use of the Service is also conditioned on
                  Your acceptance of and compliance with the Privacy Policy of
                  the Company. Our Privacy Policy describes Our policies and
                  procedures on the collection, use and disclosure of Your
                  personal information when You use the Application or the
                  Website and tells You about Your privacy rights and how the
                  law protects You. Please read Our Privacy Policy carefully
                  before using Our Service.
                </p>
              </div>

              <div className="mt-10">
                <h5 className="text-xl font-redhat-bold text-black">
                  Placing Order for Goods
                </h5>
                <p className="mt-2 font-redhat-regular text-greyText">
                  By placing an Order for Goods through the Service, You warrant
                  that You are legally capable of entering into binding
                  contracts.
                </p>
                <p className="mt-2 font-redhat-regular text-greyText">
                  If You wish to place an Order for Goods available on the
                  Service, You may be asked to supply certain information
                  relevant to Your Order including, without limitation, Your
                  name, Your email, Your phone number, Your credit card number,
                  the expiration date of Your credit card, Your billing address,
                  and Your shipping information.
                </p>
                <p className="mt-2 font-redhat-regular text-greyText">
                  You represent and warrant that: (i) You have the legal right
                  to use any credit or debit card(s) or other payment method(s)
                  in connection with any Order; and that (ii) the information
                  You supply to us is true, correct and complete.
                </p>
                <p className="mt-2 font-redhat-regular text-greyText">
                  By submitting such information, You grant us the right to
                  provide the information to payment processing third parties
                  for purposes of facilitating the completion of Your Order.
                </p>
                <p className="mt-2 font-redhat-regular text-greyText">
                  Your access to and use of the Service is also conditioned on
                  Your acceptance of and compliance with the Privacy Policy of
                  the Company. Our Privacy Policy describes Our policies and
                  procedures on the collection, use and disclosure of Your
                  personal information when You use the Application or the
                  Website and tells You about Your privacy rights and how the
                  law protects You. Please read Our Privacy Policy carefully
                  before using Our Service.
                </p>
              </div>

              <div className="mt-10">
                <h5 className="text-xl font-redhat-bold text-black">
                  Order Cancellation
                </h5>
                <p className="mt-2 font-redhat-regular text-greyText">
                  We reserve the right to refuse or cancel Your Order at any
                  time for certain reasons including but not limited to:
                </p>
                <ul className="ml-8 list-disc mt-3">
                  <li className="mt-2 font-redhat-regular text-greyText text-md">
                    Goods availability
                  </li>
                  <li className="mt-2 font-redhat-regular text-greyText text-md">
                    Errors in the description or prices for Goods
                  </li>
                  <li className="mt-2 font-redhat-regular text-greyText text-md">
                    Errors in Your Order
                  </li>
                </ul>
                <p className="mt-2 font-redhat-regular text-greyText">
                  We reserve the right to refuse or cancel Your Order if fraud
                  or an unauthorized or illegal transaction is suspected
                </p>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </motion.section>
  );
};

export default TermsofService;
