/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import OrderTile from "components/trade/order-tile";

/* ASSETS */
import Alpaca from "assets/images/logos/alpaca.png";
import Robinhood from "assets/images/logos/robinhood.png";
import Tradier from "assets/images/logos/tradier.png";
import TradeStation from "assets/images/logos/trade-station.png";
import Webull from "assets/images/logos/webull.png";
import CharlesSchwab from "assets/images/logos/charles-schwab.jpeg";
import ETrade from "assets/images/logos/e-trade.webp";


const TradeHistory = () => {
  const accountsList = useSelector((state) => state?.user?.allAccounts);

  const open = useSelector((state) => state?.user?.openOrder);
  const filled = useSelector((state) => state?.user?.filledOrder);

  const [openOrdersViewMore, setOpenOrdersViewMore] = useState(false);
  const [filledOrdersViewMore, setFilledOrdersViewMore] = useState(false);

  /* NO ORDERS */
  const NoOpenOrders = (props) => {
    return (
      <div className="w-full bg-white border border-inputBox rounded-md p-5">
        <p className="text-base font-redhat-semibold text-greyText text-center">
          {props.filled
            ? "There are no orders to be filled"
            : "You have no open orders"}
        </p>
      </div>
    );
  };

  return (
    <div className="w-full mt-5 xl:mt-8 lg:mt-8 mx-auto">
      <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-5">
        <div className="col-span-1">
          <h5 className="text-base font-redhat-bold text-black">
            Filled Orders
          </h5>
          <div className="mt-3">
            {filled === undefined || filled?.length === 0 ? (
              <NoOpenOrders filled={true} />
            ) : (
              filled
                ?.slice(0, filledOrdersViewMore ? filled?.length + 1 : 5)
                .map((data, index) => {
                  var brokerage = accountsList?.accounts?.find(
                    (el) => el?.accountId === data?.accountId
                  )?.brokerage;
                  return (
                    <React.Fragment key={index}>
                      <OrderTile
                        image={
                          brokerage === "ALPACA" || brokerage === "ALPACA_PAPER"
                            ? Alpaca
                            : brokerage === "TRADIER"
                            ? Tradier
                            : brokerage === "ROBINHOOD"
                            ? Robinhood
                            : brokerage === "SCHWAB"
                            ? CharlesSchwab
                            : brokerage === "ETRADE"
                            ? ETrade
                            : brokerage === "WEBULL"
                            ? Webull
                            : brokerage === "TRADESTATION_SIM" ||
                              brokerage === "TRADESTATION"
                            ? TradeStation
                            : null
                        }
                        name={data.name}
                        units={data.units}
                        action={data.action}
                        price={data.price}
                        broker={brokerage}
                        accountId={data?.accountId}
                        position="Filled"
                        status={data.status}
                        date={data.date}
                        type={data.orderType}
                        details={data}
                      />
                    </React.Fragment>
                  );
                })
            )}
          {filled?.length > 5 &&  <p
              onClick={() => {
                setFilledOrdersViewMore(!filledOrdersViewMore);
              }}
              className={`cursor-pointer mt-3 text-base ${
                (filled === undefined || filled?.length === 0) && "hidden"
              } font-redhat-semibold text-greyText text-center`}
            >
              View {filledOrdersViewMore ? "Less" : "More"}
            </p>} 
          </div>
        </div>
        <div className="col-span-1">
          <h5 className="text-base font-redhat-bold text-black">Open Orders</h5>
          <div className="mt-3">
            {open === undefined || open?.length === 0 ? (
              <NoOpenOrders filled={false} />
            ) : (
              open
                ?.slice(0, openOrdersViewMore ? open?.length + 1 : 3)
                .map((data, index) => {
                  var brokerage = accountsList?.accounts?.find(
                    (el) => el?.accountId === data?.accountId
                  )?.brokerage;

                  return (
                    <React.Fragment key={index}>
                      <OrderTile
                        image={
                          brokerage === "ALPACA" || brokerage === "ALPACA_PAPER"
                            ? Alpaca
                            : brokerage === "TRADIER"
                            ? Tradier
                            : brokerage === "ROBINHOOD"
                            ? Robinhood
                            : brokerage === "SCHWAB"
                            ? CharlesSchwab
                            : brokerage === "ETRADE"
                            ? ETrade
                            : brokerage === "WEBULL"
                            ? Webull
                            : brokerage === "TRADESTATION_SIM" ||
                              brokerage === "TRADESTATION"
                            ? TradeStation
                            : null
                        }
                        name={data.name}
                        units={data.units}
                        action={data.action}
                        price={data.price}
                        broker={brokerage}
                        position="Open"
                        accountId={data?.accountId}
                        date={data.date}
                        type={data.orderType}
                        showButtons={true}
                        orderId={data?.details?.id}
                      />
                    </React.Fragment>
                  );
                })
            )}
            {open?.length > 5 && <p
              onClick={() => {
                setOpenOrdersViewMore(!openOrdersViewMore);
              }}
              className={`cursor-pointer mt-3 text-base ${
                (open === undefined || open?.length === 0) && "hidden"
              } font-redhat-semibold text-greyText text-center`}
            >
              View {openOrdersViewMore ? "Less" : "More"}
            </p> }
          </div>
        </div>
      </div>
    </div>
  );
};
export default TradeHistory;
