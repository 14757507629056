import { SET_FORMAT } from "config";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

const ColumnChart = (props) => {
  const [state, setState] = useState({
    series: [
      {
        name: "Dividend Income",
        data: [
          {
            x: "2024/01/01",
            y: 400,
          },
          {
            x: "2024/04/01",
            y: 430,
          },
          {
            x: "2024/07/01",
            y: 448,
          },
          {
            x: "2024/10/01",
            y: 470,
          },
          {
            x: "2024/01/01",
            y: 540,
          },
          {
            x: "2024/04/01",
            y: 580,
          },
          {
            x: "2024/07/01",
            y: 690,
          },
          {
            x: "2024/10/01",
            y: 690,
          },
        ],
      },
    ],
    options: {
      chart: {
        height: "10%",
        type: "bar",
        fontFamily: "Roboto-Medium, sans-serif",
        toolbar: {
          show: false,
        },
      },
      xaxis: {
        labels: {
          show: false,
        },
      },
      yaxis: {
        show: false,
        labels: {
          show: false,
        },
      },
      grid: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        enabled: false,
      },
      colors: ["#ebebeb"],
    },
  });

  useEffect(() => {
    if (props?.barGraphData) {
      setState({
        series: props?.barGraphData?.datasets,
        options: {
          chart: {
            height: "100%",
            type: "bar",
            fontFamily: "Roboto-Medium, sans-serif",
            toolbar: {
              show: false,
            },
          },
          xaxis: {
            categories: props?.barGraphData?.labels,
            labels: {
              show: false,
            },
            show: false,
          },
          yaxis: {
            show: false,
            labels: {
              show: false,
            },
          },
          grid: {
            show: false,
          },
          dataLabels: {
            enabled: false,
            formatter: (value) => value.toFixed(2),
          },
          colors: ["#52b6ff"],
          tooltip: {
            enabled: true,
            y: {
              formatter: (value) => SET_FORMAT(value),
            },
          },
        },
      });
    }
  }, [props]);

  return (
    <div className="chart bar">
      <ReactApexChart
        series={state.series}
        options={state.options}
        type="bar"
        height="100%"
      />
    </div>
  );
};

export default ColumnChart;
