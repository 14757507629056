/* MATERIAL TAILWIND COMPONENTS */
import { Button } from "@material-tailwind/react";
import { SET_FORMAT } from "config";
import moment from "moment";
import { useState } from "react";
import { useSelector } from "react-redux";

/* IMPORT ROUTER DOM FOR LINK */
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { submitCancelOrderApi } from "service/api";

const OrderTile = (props) => {
  const userId = useSelector((state) => state?.user?.userId);
  const [loading, setLoading] = useState(false);
  const handleOrderCancel = () => {
    setLoading(true);
    submitCancelOrderApi(userId, props?.accountId, props?.orderId)
      .then((res) => {
        /* CHECK RESPONSE STATUS */
        if (res?.status_code === 200) {
          toast.success(res?.message);
        } else {
          /* ERROR NOTIFICATION */
          toast.error(res?.message);
        }
      })
      .catch((e) => {
        toast.error(e?.response?.data?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="w-full bg-[#F6F6F6] rounded-md px-5 py-3 mt-3">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-4">
          <img
            src={props.image}
            className="w-8 h-8 flex-shrink-0 rounded-lg"
            alt={props.name}
          />
          <div className="p-0">
            <h5 className="text-base font-redhat-bold text-black">
              {props.name}
            </h5>
            <p className="text-sm font-redhat-semibold text-greyText">
              <span className="!capitalize">{props.action}</span>{" "}
              {props?.position === "Filled" &&
                `${parseFloat(props.units).toFixed(2)} at ${
                  props?.type
                } price ${SET_FORMAT(props.price)}`}
              {props?.position === "Open" &&
                `${props?.type} ${parseFloat(props.units).toFixed(2)} ${
                  props?.type
                } Order `}
            </p>
            <p className="text-sm font-redhat-semibold text-greyText">
              {props.broker}{" "}
              <span className="text-base font-redhat-bold text-greyText capitalize">
                --- #{props?.accountId?.split("").slice(-4)}
              </span>
            </p>
          </div>
        </div>
        <div className=" float-right text-right">
          <h5 className="text-base font-redhat-bold text-black capitalize">
            {props?.status?.toLowerCase()}
          </h5>
          <p className="text-sm font-redhat-semibold text-greyText mt-1">
            {moment(props?.date).format("MM/DD/YYYY hh:mm A")}
          </p>
        </div>
      </div>
      {props?.showButtons && (
        <div className=" flex justify-end items-center  gap-3 mt-5">
          <Button
            onClick={() => {
              handleOrderCancel();
            }}
            disabled={loading}
            className="w-1/2 shadow-none hover:shadow-none bg-[#E2E2E2] hover:bg-opacity-80 rounded-md text-black font-redhat-semibold text-sm px-5 py-3 normal-case transition-all duration-300 ease-in-out"
          >
            {loading ? "Please wait..." : "Cancel Order"}
          </Button>
          <Link
            to={"/trade/change?orderId=" + props?.orderId}
            className="w-full hidden"
          >
            <Button className="w-full  shadow-none hover:shadow-none bg-[#E2E2E2] hover:bg-opacity-80 rounded-md text-black font-redhat-semibold text-sm px-5 py-3 normal-case transition-all duration-300 ease-in-out">
              Change Order
            </Button>
          </Link>
        </div>
      )}
    </div>
  );
};

export default OrderTile;
