import { Link } from "react-router-dom";

/* ICONS */
import { GoArrowDown, GoArrowUp } from "react-icons/go";
import { FaArrowUpLong, FaArrowDownLong } from "react-icons/fa6";

const DropdownStockTile = (props) => {
  return (
    <Link to={"/trade/create?symbol=" + props?.symbol}>
      <div className="flex items-center justify-between p-2 border-b border-inputBox last:border-b-0 hover:bg-black hover:bg-opacity-5 cursor-pointer">
        <div className="p-0 flex items-center gap-3">
          {parseFloat(props.percentage) >= 0 ? (
            <FaArrowUpLong
              className={props?.position ? "w-5 h-5 text-blueText" : "hidden"}
            />
          ) : (
            <FaArrowDownLong
              className={props?.position ? "w-5 h-5 text-green-500" : "hidden"}
            />
          )}
          <div className="px-2">
            <h5 className="text-sm font-redhat-bold text-black leading-relaxed">
              {props.name}
            </h5>
            <p className="text-xs font-redhat-semibold text-greyText">
              {props.symbol}
            </p>
          </div>
        </div>
        <div className={props?.position ? "p-0" : "hidden"}>
          <h5 className="text-sm font-redhat-bold text-black leading-relaxed">
            ${props.price}
          </h5>
          {parseFloat(props.percentage) >= 0 ? (
            <div className="flex items-center justify-end gap-[0.2vh]">
              <GoArrowUp className="w-3 h-3 text-green-500" />
              <p className="text-xs font-redhat-semibold text-green-500 text-end">
                {Math.abs(props.percentage)}%
              </p>
            </div>
          ) : (
            <div className="flex items-center justify-end gap-[0.2vh]">
              <GoArrowDown className="w-3 h-3 text-blueText" />
              <p className="text-xs font-redhat-semibold text-blueText text-end">
                {Math.abs(props.percentage)}%
              </p>
            </div>
          )}
        </div>
      </div>
    </Link>
  );
};

const StockTile = (props) => {
  return (
    <Link to={"/trade/create?symbol=" + props?.symbol}>
      <div className="bg-[#F6F6F6] flex items-center justify-between py-3 px-6 hover:bg-black hover:bg-opacity-5 cursor-pointer rounded-md">
        <div className="p-0 flex items-center gap-5">
          {parseFloat(props.percentage) < 0 ? (
            <div className="w-9 h-9 flex items-center justify-center bg-blueText bg-opacity-10 rounded-md">
              <FaArrowDownLong className="w-4 h-4 text-blueText" />
            </div>
          ) : (
            <div className="w-9 h-9 flex items-center justify-center bg-green-500 bg-opacity-10 rounded-md">
              <FaArrowUpLong className="w-4 h-4 text-green-500" />
            </div>
          )}
          <div>
            <h5 className="text-base font-redhat-bold text-black leading-relaxed">
              {props.name}
            </h5>
            <p className="text-xs font-redhat-semibold text-greyText">
              {props.symbol}
            </p>
          </div>
        </div>
        <div className="p-0">
          <h5 className="text-sm font-redhat-bold text-black leading-relaxed text-end">
            ${props.price}
          </h5>
          {parseFloat(props.percentage) >= 0 ? (
            <div className="flex items-center justify-end gap-[0.2vh]">
              <GoArrowUp className="w-3 h-3 text-green-500" />
              <p className="text-xs font-redhat-semibold text-green-500 text-end">
                {props.percentage}%
              </p>
            </div>
          ) : (
            <div className="flex items-center justify-end gap-[0.2vh]">
              <GoArrowDown className="w-3 h-3 text-blueText" />
              <p className="text-xs font-redhat-semibold text-blueText text-end">
                {props.percentage}%
              </p>
            </div>
          )}
        </div>
      </div>
    </Link>
  );
};

export { StockTile, DropdownStockTile };
