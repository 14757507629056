import { SET_FORMAT } from "config";
import moment from "moment";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

const AreaChart = (props) => {
  const [state, setState] = useState({
    series: [
      {
        name: "My Portfolios",
      },
      {
        name: "Compared to S&P 500",
      },
    ],
    options: {
      chart: {
        height: "100%",
        type: "area",
        fontFamily: "Roboto-Medium, sans-serif",
        toolbar: {
          show: false,
        },
      },
      colors: ["#008037", "#494949"],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
        width: 1,
      },
      legend: {
        show: false,
      },
      xaxis: {
        formatter: (value) => {
          return moment(value).format("DD/MM/YY");
        },
        labels: {
          rotate: -45,
        },
      },
      grid: {
        show: false,
      },
    },
  });

  useEffect(() => {
    if (props?.myPortfolio) {
      let series = [];
      if (
        props?.myPortfolio?.portFolioData?.length > 0 &&
        props?.myPortfolio?.spyData?.length > 0
      ) {
        series = [
          {
            name: "My Portfolios",
            data: props?.myPortfolio?.portFolioData,
          },
          {
            name: "Compared to S&P 500",
            data: props?.myPortfolio?.spyData,
          },
        ];
      } else {
        series = [
          {
            name: "My Portfolios",
            data: props?.myPortfolio?.portFolioData,
          },
        ];
      }
      setState({
        series: series,
        options: {
          chart: {
            height: "100%",
            type: "area",
            fontFamily: "Roboto-Medium, sans-serif",
            toolbar: {
              show: false,
            },
          },
          colors: ["#008037", "#494949"],
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: "straight",
            width: 1,
          },
          legend: {
            show: false,
          },
          yaxis: {
            labels: {
              show: false,
            },
          },

          xaxis: {
            categories: props?.myPortfolio?.labels,
            labels: {
              formatter: (value) => {
                return moment(value).format("DD MMM");
              },
              offsetX: 0,
              rotate: 0,
            },
            tickAmount: 10,
          },
          tooltip: {
            enabled: true,
            x: {
              show: true,
              format: "dd MMM",
              // formatter: (value) =>{
              //   return "$"+ value
              // }
            },
            y: {
              show: true,
              title: {
                formatter: (seriesName) => {
                  return seriesName + " : ";
                },
              },
              formatter: (value) => {
                return SET_FORMAT(value);
              },
            },
          },
          grid: {
            show: false,
          },
        },
      });
    }
  }, [props]);

  return (
    <div className="chart area">
      <ReactApexChart
        series={state.series}
        options={state.options}
        type="area"
        height="100%"
      />
    </div>
  );
};

export default AreaChart;
