/* REACT IMPORTS */
import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";

/* STYLES */
import "../styles/global.css";

/* LAYOUT */
import DashboardLayout from "layout/dashboard/index";
import WebsiteLayout from "layout/website";

/* WEBSITE PAGES */
import Homepage from "pages/home";
import AboutPage from "pages/about";
import Pricing from "pages/pricing";
import CookiesPolicy from "pages/cookies-policy";
import TermsofService from "pages/terms-of-service";

/* DASHBOARD PAGES */
import TradePage from "pages/dashboard/trade";
import CreateTradePage from "pages/dashboard/trade/create";
import ChangeTradePage from "pages/dashboard/trade/change";
import AccountPage from "pages/dashboard/account";
import Dashboard from "pages/dashboard/index";
import Automate from "pages/dashboard/automate";
import Selection from "pages/dashboard/selection";
import NewSelection from "pages/dashboard/calculator/new-page";

/* SCROLL TO TOP */
const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const AppRoutes = () => {
  return (
    <div className="app">
      <Router>
        <ScrollToTop />
        <Routes>
          <Route
            path="/"
            element={
              <WebsiteLayout>
                <Homepage />
              </WebsiteLayout>
            }
          />
          <Route
            path="/about"
            element={
              <WebsiteLayout>
                <AboutPage />
              </WebsiteLayout>
            }
          />
          <Route
            path="/pricing"
            element={
              <WebsiteLayout>
                <Pricing />
              </WebsiteLayout>
            }
          />
          <Route
            path="/cookies-policy"
            element={
              <WebsiteLayout>
                <CookiesPolicy />
              </WebsiteLayout>
            }
          />
          <Route
            path="/terms-of-service"
            element={
              <WebsiteLayout>
                <TermsofService />
              </WebsiteLayout>
            }
          />
          <Route
            path="/dashboard"
            element={
              <DashboardLayout>
                <Dashboard />
              </DashboardLayout>
            }
          />
          <Route
            path="/trade"
            element={
              <DashboardLayout>
                <TradePage />
              </DashboardLayout>
            }
          />
          <Route
            path="/trade/create"
            element={
              <DashboardLayout>
                <CreateTradePage />
              </DashboardLayout>
            }
          />
          <Route
            path="/trade/change"
            element={
              <DashboardLayout>
                <ChangeTradePage />
              </DashboardLayout>
            }
          />
          <Route
            path="/account"
            element={
              <DashboardLayout>
                <AccountPage />
              </DashboardLayout>
            }
          />
          <Route
            path="/automate"
            element={
              <DashboardLayout>
                <Automate />
              </DashboardLayout>
            }
          />
          <Route
            path="/selection"
            element={
              <DashboardLayout>
                <Selection />
              </DashboardLayout>
            }
          />
          <Route
            path="/new-selection"
            element={
              <DashboardLayout>
                <NewSelection />
              </DashboardLayout>
            }
          />
          
        </Routes>
      </Router>
    </div>
  );
};

export default AppRoutes;