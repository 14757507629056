const counters = [
  {
    id: 1,
    value: "4.6k",
    label: "Trades automated monthly",
  },
  {
    id: 2,
    value: "$7M",
    label: "Dollars connected",
  },
  {
    id: 3,
    value: "$3M",
    label: "Automated via ScaleTrade",
  },
  {
    id: 4,
    value: "231",
    label: "Connected accounts",
  },
];

export { counters };
