/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* REDUX IMPORTS */
import { useDispatch, useSelector } from "react-redux";
import userAction from "../../redux/actions/user.action";

import {
  getAccountListApi,
  getAccountDetailsApi,
  getAccountAllDetailsApi,
  profileGet,
} from "service/api";
import { useEffect } from "react";

const useHelperGetData = () => {

  /* GLOBAL USER STATE */
  const dispatch = useDispatch();

  const callApiStatus = useSelector((state) => state?.user?.callApi);

  const userId = useSelector((state) => state?.user?.userId);
  const accountId = useSelector((state) => state?.user?.accountId);

  const userDetails = useSelector((state) => state?.user?.user);
  const loggedInStatus = useSelector((state) => state?.user?.loggedIn);
  const accountsList = useSelector((state) => state?.user?.allAccounts);
  const selectedAccountDetails = useSelector(
    (state) => state?.user?.selectedAccount
  );
  const allConnectedAccountsList = useSelector(
    (state) => state?.user?.allConnectedAccounts
  );

  const handleAllAccountDetailsFetchFunction = () => {
    profileGet()
      .then((res) => {
        dispatch(userAction.setUser(res?.data));
      })
      .catch((err) => {
        console.log("Error: ", err);
      });

    getAccountListApi(userId)
      .then((res) => {
        dispatch(userAction.setAllAccounts(res?.data));
        dispatch(userAction.setAccountId(res?.data?.accounts?.[0]?.accountId));
      })
      .catch((err) => {
        dispatch(userAction.setAllAccounts([]));
        dispatch(userAction.setAccountId(null));
        console.log("Error: ", err);
      });

    if (accountId) {
      getAccountDetailsApi(userId, accountId)
        .then((res) => {
          dispatch(userAction.setSelectedAccount(res?.data));
        })
        .catch((err) => console.log("Error: ", err));
    }

    getAccountAllDetailsApi(userId)
      .then((res) => {
        dispatch(userAction.setAllConnectedAccounts(res?.data));
        if (res?.data?.accounts?.length > 0) {
          dispatch(
            userAction.setAccountId(res?.data?.accounts?.[0]?.accountId)
          );
          dispatch(userAction.setSelectedAccount(res?.data?.accounts?.[0]));
        }

        handlePreviousOrdersList(res?.data);
      })
      .catch((err) => console.log("Error: ", err));

    dispatch(userAction.setApiStatus(false));
  };

  const handlePreviousOrdersList = (propsData) => {
    var openTemp = [];
    var filledTemp = [];

    propsData?.accounts?.forEach((element) => {
      element?.historical.forEach((data) => {
        if (
          data?.order?.information?.status === "ACCEPTED" ||
          data?.order?.information?.status === "PENDING" ||
          data?.order?.information?.status === "PARTIAL" ||
          data?.order?.information?.status === "REPLACE_PENDING"
        ) {
          let orderType = null;
          if (data?.order?.information?.fill?.split("LIMIT")?.length === 2) {
            orderType = "Limit";
          } else if (
            data?.order?.information?.fill?.split("MARKET")?.length === 2
          ) {
            orderType = "Market";
          } else if (
            data?.order?.information?.fill?.split("STOPLOSS")?.length === 2
          ) {
            orderType = "Stop Loss";
          }
          openTemp.push({
            accountId: data?.accountId,
            name: data?.order?.underlying,
            action: data?.order?.action,
            units: data?.order?.units,
            price: data?.order?.information?.price,
            details: data,
            status: data?.order?.information?.status,
            orderType: orderType,
            date: data?.order?.information?.time_placed,
          });
        }
        if (
          data?.order?.information?.status === "EXECUTED" ||
          data?.order?.information?.status === "FILLED" ||
          data?.order?.information?.status === "EXPIRED" ||
          data?.order?.information?.status === "CANCELLED"
        ) {
          let orderType = null;
          if (data?.order?.information?.fill?.split("LIMIT")?.length === 2) {
            orderType = "Limit";
          } else if (
            data?.order?.information?.fill?.split("MARKET")?.length === 2
          ) {
            orderType = "Market";
          } else if (
            data?.order?.information?.fill?.split("STOPLOSS")?.length === 2
          ) {
            orderType = "Stop Loss";
          }
          filledTemp.push({
            accountId: data?.accountId,
            name: data?.order?.underlying,
            action: data?.order?.action,
            units: data?.order?.units,
            price: data?.order?.information?.execution_price,
            details: data,
            status: data?.order?.information?.status,
            orderType,
            date: data?.order?.information?.time_placed,
          });
        }
      });
    });

    const tempFilled = filledTemp.sort(
      (a, b) => new Date(b.date) - new Date(a.date)
    );
    const tempOpen = openTemp.sort(
      (a, b) => new Date(b.date) - new Date(a.date)
    );

    dispatch(userAction.setFilledOrdersList(tempFilled));
    dispatch(userAction.setOpenOrdersList(tempOpen));
  };
  
  useEffect(() => {
    if (callApiStatus) {
      handleAllAccountDetailsFetchFunction();
    }
  }, [callApiStatus]);

  return true;
};
export default useHelperGetData;
